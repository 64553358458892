var revealOfferBackManager = (function () {
    function init(doHeightBoxCard) {
        if(doHeightBoxCard != undefined && doHeightBoxCard == true){
            heightBoxCard();
            return;
        } 
        revealService();
    }

    function getHashTags(inputText) {
        var regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
        var matches = [];
        var match;

        while ((match = regex.exec(inputText))) {
            matches.push(match[1]);
        }

        return matches;
    }
    function revealService() {

        $(document).on("click", ".sticker a, .ztl-reveal", function (e) {
            var href = getHashTags($(this).attr('href'))
            if (href.length) {
                $(this).closest(".offer-box").find('.offer-box-front .d-lg-block .rotate').trigger("click");
                e.preventDefault();
                return false;
            }
        });

        $(document).on("click",".offer-box .rotate", function (e) {
            var heightToApply = $(this).closest('.flip-card').find('.offer-box-front');
            var backFlip = $(this).closest('.flip-card').find('.offer-box-back');
            var offerBoxServices = $(".offer-box-services ul", backFlip);
            var services = $(".offer-box-services",backFlip);
            // backFlip.css({
            //     'height': heightToApply.height()
            // });


            if (!$(this).closest('.flip-card').hasClass('reveal')) {
                $(this).closest('.flip-card').addClass('reveal');
            } else {
                $(this).closest('.flip-card').removeClass('reveal');

            }
           e.preventDefault();
        });
    }

    function heightBoxCard() { 
        var flipCard = $('.flip-card');
        flipCardResize();
          $(window).resize(flipCardResize);
          function flipCardResize() {
            flipCard.each(function() {
                var flipCardInner = $(this).find('.offer-box-wrapper').innerHeight();
                var flipCard = $(this);
                flipCard.css('height', flipCardInner);
            });
          }
    }

    return {
        init: init
    };
})(jQuery);
