function isValidEmail(sEmail) {
  var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
  if(!sEmail.match(reEmail)) {
    return false;
  }
  return true;
}

function isValidZip(zip) {
  var re = /^\d{5}$/;
  return re.test(zip);
}

function isValidPhoneNr(phoneNr) {
  var re = /^[\d\+]{5,16}$/;
  return re.test(phoneNr);
}

function isValidCodFis(codFis) {
  var re = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
  return re.test(codFis);
}

function isValidPartitaIva(partitaIva) {
  var re = /^[0-9]{11}$/;
  return re.test(partitaIva);
}
function isMobile() {
  return detectOS() === 'android' || detectOS() === 'ios';
}
function detectOS(){
  if( navigator.userAgent.match(/Android/i)){
    return  'android'
  }
  else if(navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)){
    return 'ios'
  }
  else{
    return 'web'
  }

}

function isIE() {
  return detectIE();
}

function detectIE() {

  /* Sample function that returns boolean in case the browser is Internet Explorer*/
  function isIE() {
    ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }
  /* Create an alert to show if the browser is IE or not */
  if (isIE()){
    return true;
  }else{
    return false;
  }

}

function grayscale(src, callbackSuccess, callbackError){
  grayscaleAlpha(src, 1, callbackSuccess, callbackError);
}

function grayscaleAlpha(src, alpha, success, failure) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  var imgObj = new Image();
  imgObj.onload = function() {
    canvas.width = imgObj.width;
    canvas.height = imgObj.height;
    ctx.drawImage(imgObj, 0, 0);
    var imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for(var y = 0; y < imgPixels.height; y++){
      for(var x = 0; x < imgPixels.width; x++){
        var i = (y * 4) * imgPixels.width + x * 4;
        var avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
        imgPixels.data[i] = avg;
        imgPixels.data[i + 1] = avg;
        imgPixels.data[i + 2] = avg;
        imgPixels.data[i + 3] = Math.floor(alpha * imgPixels.data[i + 3]);
      }
    }
    ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
    success(canvas.toDataURL());
  };
  imgObj.onerror = failure;
  imgObj.src = src; // start loading image and return (void)
}

$(function () {
  $('[data-toggle="tooltip"]').tooltip({
    placement: 'top',
    template: '<div class="tooltip tooltip-custom" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
    trigger: 'hover focus'
  })
});

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}