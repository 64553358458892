var priceManager = (function () {
  var originalPrice = parseFloat($("[data-original-price]").attr('data-original-price'));
  var originalAdvance = parseFloat($("[data-original-advance]").attr('data-original-advance'));
  var additionalPackagePrice = 0;
  var additionalPackageAdvance = 0;
  var customizationPrice = 0;
  var finalPrice = originalPrice;

  function setAdditionalPackagePrice(price){
    additionalPackagePrice = parseFloat(price);
  }

  function setAdditionalPackageAdvance(price){
    additionalPackageAdvance = parseFloat(price);
  }

  function setCustomizationPrice(price) {
    //customizationPrice = parseFloat(price);
    customizationPrice = Number.parseFloat(price.replace(',','.'));
  }

  function setPrice() {
    var originalPriceElem = $("[data-original-price]");
    var originalPriceCard = $(".offer-box-price-main span.h1, .offer-card-compact .offer-price span");
    var finalPriceConverted;
    if (originalPrice === customizationPrice) {
      finalPrice = originalPrice+additionalPackagePrice;
      finalPriceConverted = window.ENV.market === 'uk' ? finalPrice : finalPrice.toString(10).replace('.', ',');
      originalPriceElem.text(finalPriceConverted);
      originalPriceCard.text(finalPriceConverted);
    } else {
      if ($("#financialCalculatorCollapse").length && customizationPrice !== 0){
        finalPrice = additionalPackagePrice+customizationPrice;
      } else {
        finalPrice = originalPrice+additionalPackagePrice+customizationPrice;
      }

      finalPriceConverted = window.ENV.market === 'uk' ? finalPrice : finalPrice.toString(10).replace('.', ',');
      originalPriceElem.text(finalPriceConverted);
      originalPriceCard.text(finalPriceConverted);

      //UPDATE ADVANCE PRICE
      newAdvance = originalAdvance + additionalPackageAdvance
      //$("span.offer-card-advance").text(getConvertedPrice(newAdvance, true));
      $("p.offer-advance").text(getConvertedPrice(newAdvance, true));


      //SET PRICE IN NOTES
      //setPriceInNotes(finalPrice);

    }
    if ($('.startingFrom').length){
      if (parseFloat($("[data-original-price]").text()) > originalPrice) {
        $.each($('.startingFrom'), function (){
          $(this).addClass('d-none');
        })
      } else {
        $.each($('.startingFrom'), function (){
          $(this).removeClass('d-none');
        })

      }
    }
  }

  function setPriceInNotes(finalPrice){
    var notesPrice = $('.custom-dynamic-notes span.notes-price');
    notesPrice.text(finalPrice);
  }

  function getPrice() {
    return finalPrice;
  }

  function getConvertedPrice(price, hasCurrency){
    let currency = window.ENV.currency;
    let currencyPosition = window.ENV.currencyPosition;
    thousandsSep = ["£"].includes(currency) ? "," : '.';
    decimalSep = ["£"].includes(currency) ? "." : ',';
    if(currency == '£') price = price.toLocaleString("en-UK")
    else price = price.toLocaleString("it-IT");
    if(hasCurrency){
      if(hasCurrency == "after"){
        return price + " " + currency;
      }else{
        return currency + "" + price
      }
    }
    return price;
    
  }

  return {
    setPrice: setPrice,
    getPrice: getPrice,
    setAdditionalPackagePrice: setAdditionalPackagePrice,
    setAdditionalPackageAdvance: setAdditionalPackageAdvance,
    setCustomizationPrice: setCustomizationPrice,
    getConvertedPrice: getConvertedPrice
  };
})(jQuery);
