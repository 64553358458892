var relatedBlogsManager = (function () {
    var relatedBlogsCarousel;
    var indexOffer = 0;
    var relatedBlogsObj = $('.related-blogs .owl-carousel');

    function init() {
        $(document).ready(function () {
            if (relatedBlogsObj.length) {
                initCarousel();
                setCarouselArrowsHeight();
            }
        });

        $( window ).resize(function () {
            if (relatedBlogsObj.length) {
                setCarouselArrowsHeight();
            }
            
        });
    }

    function setCarouselArrowsHeight(){
        var relatedBlogImg = $(".item", relatedBlogsObj).find(".single-related-blog-img img").eq(0);
        $(".related-blogs-place .owl-nav button").css({
            "top" : relatedBlogImg.height()/2
        })

    }

    function initCarousel() {
        $(".item", relatedBlogsObj).each(function (k, v) {
            if ($(this).hasClass('active')){
                indexOffer = k;
            }
        });
        var relatedBlogsNumber = $('.single-related-blog').length;
        relatedBlogsCarousel = relatedBlogsObj.owlCarousel({
            mouseDrag: relatedBlogsNumber > 3,
            nav: relatedBlogsNumber > 3,
            margin: 25,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            dots: false,
            loop: relatedBlogsNumber > 3,
            responsive: {
                0: {
                    items: 1,
                    loop: relatedBlogsNumber > 1,
                    nav: relatedBlogsNumber > 1,
                    center: true,
                },
                992:{
                    items: 2,
                    loop: relatedBlogsNumber > 2,
                    nav: relatedBlogsNumber > 2,
                    center: false,
                },
                1200: {
                    items: relatedBlogsNumber > 3 ? 3 : relatedBlogsNumber
                }
            }
        })
    }

    return {
        init: init
    }
})(jQuery);