var serviceIncludeManager = (function () {
    var serviceIncludeObj = $(".services");
    var isContractPage = $("body.contract-page").length;
    function init() {
        $(document).ready(function () {
            eventHandler();
        });
    }

    function eventHandler() {
        $("li .hover-blocks", serviceIncludeObj).each(function () {
            if ($(this).hasClass('active')){
                changeContent($(this));
            }
        });
        if (isMobile()){
            $("li .hover-blocks.active", serviceIncludeObj).removeClass('active');
        }
        if(!isMobile()) {
            $("li .hover-blocks", serviceIncludeObj).on("click", function (e) {
                $("li .hover-blocks", serviceIncludeObj).removeClass('active');
                $(this).addClass("active");
                changeContent($(this));
                e.preventDefault();
                return false;
            });
        }
    }

    function changeContent($this) {
        var serviceContentObj = $($this.closest('.section').find(".service-include-content"));
        if(!isMobile()){
            serviceContentObj.removeClass('d-none');
        } else{
            serviceContentObj.addClass('d-none');
        }
        var title = $this.attr("title");
        var image = $this.find(".inactive-image");
        var description = $this.attr("data-description");
        var dataLink = $this.attr("data-link");
        var dataLinkLabel = $this.attr("data-link-label");
        var dataColor = $this.attr("data-color");
        $("h4,h3", serviceContentObj).text(title);
        $("p", serviceContentObj).html(description);
        if (isContractPage) {
            $("a", serviceContentObj).addClass('d-none')
                .attr('rel','nofollow');
        }
        if(typeof dataLink !== typeof undefined && dataLink !== '' && dataLink !== null) {
            $("a", serviceContentObj).not('.to-show').removeClass('d-none')
                .attr('href',dataLink)
                .text(dataLinkLabel);
        } else {
            $("a", serviceContentObj).not('.to-show').addClass('d-none')
        }

        if(typeof dataColor !== typeof undefined) {
            $("h4,h3", serviceContentObj).removeClass().addClass(dataColor);
        }
        if(typeof image !== typeof undefined) {
            $("img", serviceContentObj).attr("src", image.attr("src"))
        }
    }

    return {
        init: init
    };
})(jQuery);
