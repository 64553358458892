var boxPillsManager = (function () {
    var tabToBeActiveTogether = $(".two-tab-together").length;
    function init() {
        $(document).ready(function () {
            if (tabToBeActiveTogether){
                handleNavItemActiveTogether();
            }
        });
    }

    function handleNavItemActiveTogether(){
        var nav = $('.nav-pills.box-pills#pills-tab a');
        var navTogether = $('.nav-pills.box-pills#pills-tab a.nav-together');
        if (navTogether.length >= 2) {
            $(nav).on("click", function (e){
                e.preventDefault();
                var currentNav = $(this);
                currentNav.tab('show');
                navTogether.each(function (){
                    var navToEnable = $(this);
                    $(navToEnable).removeClass('active')
                    $(navToEnable).tab('dispose');
                })
            })
            $(navTogether).on("click", function (e){
                e.preventDefault();
                var currentNav = $(this);
                currentNav.tab('show');
                navTogether.each(function (){
                    var navToEnable = $(this);
                    $(navToEnable).addClass('active');
                })
            })
        }
    }

    return {
        init: init
    };
})(jQuery);
