var imagePinsManager = (function () {

    function init(){
        if($(".image-pins-component").length <= 0) return;
        dataAttributeCheck = $(".image-pins-component").data("tocheckvalue");
        if(!dataAttributeCheck) return;

        $("g.country-pin").on("click", function(e){
            $("g.country-pin").removeClass("active");
            $(this).addClass("active");
            pinContents = $(".pin-contents-place .pin-contents-container");
            pinContents.removeClass("d-flex");
            pinContents.addClass("d-none");

            toShowContent = $(".pin-contents-place .pin-contents-container#popup_" + $(this).first().data(dataAttributeCheck));
            toShowContent.removeClass("d-none");
            toShowContent.addClass("d-flex");

            $(".image-pins-component .image-background-container").addClass("active")

            $('html, body').animate({
                scrollTop: $(".pin-contents-place").offset().top+(-150)
            }, 1500);

        })
    }

    return {
        init:init
    }

})(jQuery)