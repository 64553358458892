var searchModalManager = (function () {
    function init() {
        $("#searchModal form").submit(function(event) {
            // var input = $(this).find("input[name='search']");
            // if($(input).text() !== '') {
            //     event.preventDefault();
            //     $(input).addClass("is-invalid")
            // }
            // $(input).focus(function() {
            //     $(this).removeClass("is-invalid")
            // })
        });

    }

    return {
        init: init
    };
})(jQuery);
