var newSliderSmallManager = (function () {

    var carousel = $('.new-slider-small-component .owl-carousel');
    //var cardItems = carousel.find('.card-container').length;
    var animation_duration = $(".new-main-slider-small").data("customanimationsd");
    var animation_interval = $(".new-main-slider-small").data("customanimationsi");
    console.log('testtt'+animation_duration + '----' + animation_interval);

    function init(){
        $(document).ready( function(){
            if($ ('.new-slider-small-component').length <= 0){
                return;
            }else{
                startGamificationCarousel();
                startLeftTextAndImageCarousel();
                startLeftTextCarousel();
                startRightTextCarousel();
            }
        })
    }





    //right text carousel
    function startRightTextCarousel(){
        if($(".new-slider-small-component .owl-carousel .new-slider-small-right-text").length){
            $(".new-slider-small-component .owl-carousel .new-slider-small-right-text").closest('.new-slider-small-component .owl-carousel').owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: true,
                center: true,
                loop: false,
                responsive:{
                    0:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: true,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: true,
                        center: false,
                        loop: animation_interval > 1 ? true : false,
                        nav: false,
                        margin: 60
                    },
                    768:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: true,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: true,
                        center: false,
                        loop: animation_interval > 1 ? true : false,
                        nav: false,
                        margin: 60
                    },
                    992:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: false,
                        items: 1,
                        stagePadding: 0,
                        singleItem: false,
                        dots: true,
                        center: false,
                        loop: animation_interval > 1 ? true : false,
                        nav: false,
                        margin: 60
                    }
                }
            });
        }
    }






    //left text carousel
    function startLeftTextCarousel(){
        if($(".new-slider-small-component .owl-carousel .new-slider-small-left-text").length){
            $(".new-slider-small-component .owl-carousel .new-slider-small-left-text").closest('.new-slider-small-component .owl-carousel').owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: true,
                center: true,
                loop: false,
                responsive:{
                    0:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: true,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: true,
                        center: false,
                        loop: animation_interval > 1 ? true : false,
                        nav: false,
                        margin: 60
                    },
                    768:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: true,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: true,
                        center: false,
                        loop: animation_interval > 1 ? true : false,
                        nav: false,
                        margin: 60
                    },
                    992:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: false,
                        items: 1,
                        stagePadding: 0,
                        singleItem: false,
                        dots: true,
                        center: false,
                        loop: animation_interval > 1 ? true : false,
                        nav: false,
                        margin: 60
                    }
                }
            });
        }
    }

    //left text and image carousel
    function startLeftTextAndImageCarousel(){
        if($(".new-slider-small-component .owl-carousel .new-slider-small-left-text-and-image").length){
            $(".new-slider-small-component .owl-carousel .new-slider-small-left-text-and-image").closest('.new-slider-small-component .owl-carousel').owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: false,
                center: true,
                loop: true,
                responsive:{
                    0:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: true,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: true,
                        center: false,
                        loop: true,
                        nav: false,
                        margin: 60
                    },
                    768:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: true,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: true,
                        center: false,
                        loop: true,
                        nav: false,
                        margin: 60
                    },
                    992:{
                        autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                        autoplayHoverPause: animation_interval > 1 ? true : false,
                        autoplay: animation_interval > 1 ? true : false,
                        smartSpeed: animation_duration > 1 ? animation_duration: 450,
                        mouseDrag: false,
                        items: 1,
                        stagePadding: 0,
                        singleItem: false,
                        dots: false,
                        center: false,
                        loop: true,
                        nav: true,
                        margin: 60
                    }
                }
            });
        }
    }

    //gamification carousel
    function startGamificationCarousel(){
        if($(".new-slider-small-component .owl-carousel .new-slider-small-gamification").length){
            $(".new-slider-small-component .owl-carousel .new-slider-small-gamification").closest('.new-slider-small-component .owl-carousel').owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: false,
                center: true,
                loop: false,
                responsive:{
                    0:{
                        mouseDrag: false,
                        touchDrag: false,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: false,
                        center: false,
                        nav: false,
                        margin: 60
                    },
                    768:{
                        mouseDrag: false,
                        touchDrag: false,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: false,
                        center: false,
                        nav: false,
                        margin: 60
                    },
                    992:{
                        mouseDrag: false,
                        touchDrag: false,
                        items: 1,
                        stagePadding: 0,
                        singleItem: false,
                        dots: false,
                        center: false,
                        nav: false,
                        margin: 60
                    }
                }
            });
        }
    }

    return {init: init}

})(jQuery);