var careersDetailsManager = (function(){

    function init(){
        $(document).ready( function(){

            if($(".careers-details-component").length){

                //show form
                $("#careers_details_button").on("click", function(){
                    $(".careers_details_form_box").removeClass("d-none");
                });
                
                //open form if contain errors
                if($(".careers-form-component .err").length){
                    $(".careers_details_form_box").removeClass("d-none");
                    $("html, body").animate({
                        scrollTop: $(".careers_details_form_box").offset().top
                    }, 1000);
                }

                console.log($(".careers-form-component .err").length);

            }else{
                return;
            }
        });
    }

    return {init: init};

})(jQuery);