var troubleTicketRequest = (function () {

    var troubleCause;
    var argument;
    var troubleSubArgument;
    var requestType;
    var url;
    var requestJson;
    var troubleDescriptionHelper;


    function init() {
        troubleCause = $("#troubleCause");
        argument = $('#troubleArgument');
        troubleSubArgument = $('#troubleSubArgument');
        troubleDescriptionHelper = $('.troubleDescriptionHelper');
        requestType = null;
        url = ENV.troubleTicketUrl;
        disableArgument();
        disableTroubleArgument();
        eventHandler();
    }

    function htmldecode (str){

        var txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    function eventHandler()
    {
        $(document).ready(function () {
            troubleDescriptionHelper.hide()
            if (troubleCause.length) {
                var getValue = troubleCause.attr("data-selected");
                getRequestJson(function (){
                     setRequest(getValue);
                });

            }
        });

        troubleCause.change(function () {
            var getValue = argument.attr("data-selected");
            setArgument($(this),getValue);
        });

        argument.change(function () {
            var getValue = troubleSubArgument.attr("data-selected");
            setSubArgument($(this), getValue);
        });

        troubleSubArgument.change(function () {
            var getValue = troubleSubArgument.val();
            if (requestJson && requestType && getValue && argument.val()) {
                if (requestJson[requestType][argument.val()][getValue]) {
                    troubleDescriptionHelper.show()
                        .html(htmldecode(requestJson[requestType][argument.val()][getValue]))
                } else {
                    troubleDescriptionHelper.hide()
                }
            }

        });

    }



    function getRequestJson(callback)
    {
        $.ajax({
            method: "GET",
            url: url
        }).done(function( ret ) {
            requestJson = ret;
            callback();
        });
    }

    function setRequest(value)
    {
        troubleCause.select2({
            data: formatSelect2Results(requestJson)
        });
        if ( typeof value !== 'undefined' && value != "") {
            troubleCause.val(value).trigger("change");
        }
    }

    function setArgument($this, value)
    {
        argument.removeAttr('disabled');
        $("option",argument).not(":first").remove();
        argument.val(null).trigger('change');

        enableSelect(argument);

        requestType = $this.val();

        if (requestType != null) {
            argument.select2({
                data: formatSelect2Results(requestJson[requestType])
            });
            if ( typeof value !== 'undefined' && value != "") {
                argument.val(value).trigger("change");
            }
        } else {
            disableArgument();
        }
    }

    function setSubArgument($this, value)
    {
        enableSelect(troubleSubArgument);

        var argument = $this.val();

        if (argument != null && requestType != null) {
            troubleSubArgument.select2({
                data: formatSelect2Results(requestJson[requestType][argument])
            });
            if ( typeof value !== 'undefined' && value != "") {
                troubleSubArgument.val(value).trigger("change");
            }
        } else {
            disableTroubleArgument();
        }
    }

    function enableSelect(select)
    {
        select.removeAttr('disabled');
        $("option",select).not(":first").remove();
        select.val(null).trigger('change');
    }

    function disableTroubleArgument()
    {
        troubleSubArgument.attr("disabled",true);
    }

    function disableArgument()
    {
        argument.attr("disabled",true);
    }

    function formatSelect2Results(item) {
        var array = [];
        $.each(item,function (k,v) {
            array.push(k);
        });
        return array;
    }

    return {
        init: init
    };
})(jQuery);