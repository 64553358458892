var halfBoxTabsR23 = (function () {
    var halfBoxTabs = $('.half-box-tabs-r23');

    function init() {
        showHidePanel();
        showMoreText();
    }

    function showHidePanel() {
        halfBoxTabs.each(function () {
            var This = $(this);
            $(this).find('.half-box-tabs-r23__inner__tab:not(.half-box-tabs-r23__inner__tab--active)').hide();
            $(this).find('.half-box-tabs-r23__head__content__button__link[href^="#"]').on('click', function (event) {
                event.preventDefault();
                This.find('.half-box-tabs-r23__inner__tab').hide();
                var target = $(this).attr('href');
                This.find('.half-box-tabs-r23__inner__tab').removeClass('half-box-tabs-r23__inner__tab--active');
                This.find('.half-box-tabs-r23__head__content__button__link').removeClass('half-box-tabs-r23__head__content__button__link--active');
                $(this).addClass('half-box-tabs-r23__head__content__button__link--active');
                This.find('.half-box-tabs-r23__inner__tab' + target).toggle();
                This.find('.half-box-tabs-r23__inner__tab' + target).addClass('half-box-tabs-r23__inner__tab--active');
            });
        });
    }

    function showMoreText() {
        var contentText = $('.half-box-list-wrapper__text-more');
        var ellipsestext = "...";

        contentText.each(function () {
            var content = $(this).html().trim();
            var showChar = $(this).data('show-char-preview');
            var moretext = $(this).data('name-more');
            var lesstext = $(this).data('name-less');
            var cta = $(this).closest("div").find(".text-with-images-and-boxes-hidden-cta");



            if (content.length > showChar) {

                var ctaHref = cta.attr('href');
                var ctaLabel = cta.html();
                var ctaToPrint = ctaHref ? '<a href="'+ ctaHref +'" class="btn btn-leasys-red text-with-images-and-boxes-hidden-cta d-none">' + ctaLabel + '</a>' : '';

                var c = content.substr(0, showChar);
                var h = content.substr(showChar, content.length - showChar);
                var html = c + '<span class="half-box-list-wrapper__text-more__ellipses">' + ellipsestext + '&nbsp;</span><span class="half-box-list-wrapper__text-more__content"><span class="half-box-list-wrapper__text-more__content__text">' + h + '</span>' + ctaToPrint + '<button type="button" class="half-box-list-wrapper__text-more__content__link">' + moretext + '</button></span>';
                $(this).html(html);
                cta.remove();
            }
            else{
                cta.removeClass('d-none');
            }

            $(this).find(".half-box-list-wrapper__text-more__content__link").click(function () {
                if ($(this).hasClass("less")) {
                    $(this).removeClass("less");
                    $(this).html("&nbsp;" + moretext + "&nbsp;");
                    $(this).closest("div").find(".half-box-list-wrapper__text-more__content__text").removeClass('half-box-list-wrapper__text-more__content__txt--less');
                    $(this).closest('div').find(".text-with-images-and-boxes-hidden-cta").addClass('d-none');
                } else {
                    $(this).addClass("less");
                    $(this).html("&nbsp;" + lesstext + "&nbsp;");
                    $(this).closest("div").find(".half-box-list-wrapper__text-more__content__text").addClass('half-box-list-wrapper__text-more__content__txt--less');
                    if(ctaToPrint !== ''){
                        $(this).closest("div").find(".half-box-list-wrapper__text-more__content__text").append('<br/>');
                    }
                    $(this).closest('div').find(".text-with-images-and-boxes-hidden-cta").removeClass('d-none').addClass("mr-4");
                }
                $(this).parent().prev().toggle();
                return false;
            });
        });
    }


    return {
        init: init
    };
})(jQuery);
