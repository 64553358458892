var stockSearchManager = (function() {
    var stockSearchConfig = document.getElementById('stockSearchConfig') !== null ? JSON.parse(document.getElementById('stockSearchConfig').textContent) : {};
    var stockCards = null;
    var Loader = $("<div class='stock-loading d-none'></div>");
    var currentpage = 1;
    var totalNumberOfPages = null;
    var total = null;
    var $pagination = $('#pagination');
    var isOptionsChanged = false;
    var owlCarouselItems = 0;
    var owlCarouselCurrentItem = 0;
    var nextClicked = 0;
    var cardContainer = $('.stock-offer-carousel');
    var isDesktop = true;

    var parameter = {
        lcdv_brand: stockSearchConfig.lcdv_brand,
        lcdv_model: stockSearchConfig.lcdv_model,
        lcdvBodyStyles: stockSearchConfig.lcdvBodyStyles,
        fuel: stockSearchConfig.fuel,
        geoLocalization: '',
        distanceRadius: '',
        sort: '',
        resultsPerPage: 4,
        page: 1,
    };

    var stockSectionScrollTop = $('.stockSearchForm').length? $('.stockSearchForm').offset().top + $('.nav-main').height() : null;

    var $paginationOption = {
        headerHeight: $('nav'),
        firstClass: 'd-none',
        lastClass: 'd-none',
        prev: '<i class="prev-icon"></i>',
        next: '<i class="next-icon"></i>',
        totalPages: 20,
        onPageClick: function (event, page) {
            closePopover();
            //console.log(currentpage, page)
            if (currentpage !== page) {
                parameter.page = page;

                renderStockCard();

                $('html, body').animate({
                    scrollTop: $(".stock-offers-section").offset().top
                }, 1000);
                /*
                if ($(window).width() > 992 && stockSectionScrollTop !== null) {
                    $("html, body").animate({ scrollTop:  stockSectionScrollTop}, "slow");
                }
                 */
            }

        }
    }


    function resetSettings () {
        currentpage = 1;
        totalNumberOfPages = null;
        total = null;
        owlCarouselItems = 0;
        owlCarouselCurrentItem = 0;
        nextClicked = 0;
        parameter = {
            lcdv_brand: stockSearchConfig.lcdv_brand,
            lcdv_model: stockSearchConfig.lcdv_model,
            fuel: stockSearchConfig.fuel,
            geoLocalization: '',
            distanceRadius: '',
            sort: '',
            resultsPerPage: 4,
            page: 1,
        };
    }

    function init() {
        if ($('#car-detail-quote-request [name="stockUuid"]').length){
            renderStockOptionsPopup();
        }
        if (Object.keys(stockSearchConfig).length) {
            isDesktop = $(window).width() >= 992;
            if (!isDesktop){
                stockCards = $(".stock-offer-carousel").owlCarousel({
                    loop: false,
                    rewind: false,
                    //margin: 70,
                    dots: false,
                    items: 1,
                    stagePadding: 45,
                    margin: 15,
                    touchDrag: true,
                    onChanged: onChanged,
                    onDrag: function () {
                        closePopover();
                    }
                });
            }
            $(window).on('resize', function (){
                isDesktop = $(this).width() >= 992;
                if($(this).width()>= 992 && stockCards !== null && stockCards.data('owl.carousel') !== null){
                    if ( typeof stockCards.data('owl.carousel') !== typeof undefined){
                        stockCards.data('owl.carousel').destroy();
                        renderStockCard();
                    }


                } else {
                    stockCards = $(".stock-offer-carousel").owlCarousel({
                        loop: false,
                        rewind: false,
                        //margin: 70,
                        dots: false,
                        items: 1,
                        stagePadding: 45,
                        margin: 15,
                        touchDrag: true,
                        onChanged: onChanged,
                        onDrag: function () {
                            closePopover();
                        }
                    });
                }
            });
            handleStockSearch();
            renderStockCard();
            $('.stockLoader').append(Loader);
            $pagination.twbsPagination($paginationOption);

            $('.clear-location-field').on('click', function (){
                if ($('#searchTextField').val() !== ''){
                    $('#searchTextField').val('');
                    document.getElementById('city2').value = '';
                    document.getElementById('cityLat').value = '';
                    document.getElementById('cityLng').value = '';
                    resetSettings();
                    renderStockCard();
                }
            })
            $('.pagination-mobile .prev').on('click', function (e){
                nextClicked = nextClicked-1
                e.preventDefault();
                if (!isDesktop){
                    stockCards.trigger('prev.owl.carousel');
                }
                if (parameter.page > 1) {
                    parameter.page = parameter.page-1;
                    renderStockCard();
                    nextClicked = 0;
                }
            })

            $('.pagination-mobile .next').on('click', function (e){
                nextClicked++;
                e.preventDefault();
                if(!isDesktop){
                    stockCards.trigger('next.owl.carousel');
                }
                if (nextClicked >= owlCarouselItems) {
                    nextClicked = 0;
                    parameter.page = parameter.page+1;
                    renderStockCard();
                }
            })
        }
    }

    function onChanged(event) {
        var items     = event.item.count;     // Number of items
        var item      = event.item.index;
        owlCarouselItems = items;
        owlCarouselCurrentItem = item;
        if (currentpage === 1 && owlCarouselCurrentItem === 0) {
            disableMobilePrevBtn();
        } else {
            enableMobilePrevBtn();
        }
    }

    function renderStockOptionsPopup(){
        $(document).on('click','.stock-options', function(event) {
            $(this).popover({
                placement:'top',
                trigger:'manual',
                sanitize: false,
                html:true,
                content: function() {
                    var contents = $(this).attr('data-popover-content').split('|');
                    var list = '<ul>';
                    $.each(contents, function (){
                        if (this.length) {
                            list += `<li>${this}</li>`
                        }
                    })
                    list += '</ul>';
                    return list;
                },
                title: function() {
                    var title = $(this).attr('data-popover-title');
                    return `<div>${title}</div><div class="close-icon-blue closePopover"></div>`;
                },
                template: '<div class="popover stock-options-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body stocks-overflow"></div></div>'
            });
            $(this).popover('show');
        });
        $(document).on('click','.closePopover', function (e) {
            closePopover();
        });
        $('body').on('click', function (e) {
            $('[data-toggle="popover"]').each(function () {
                //the 'is' for buttons that trigger popups
                //the 'has' for icons within a button that triggers a popup
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
    }

    function closePopover (){
        $('[data-toggle="popover"]').each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            $(this).popover('hide');
        });
    }

    function showLoading() {
        Loader.removeClass("d-none");
    }
    function hideLoading() {
        Loader.addClass('d-none');
    }

    function renderPagination() {
        //var currentPage = $pagination.twbsPagination('getCurrentPage');
        //console.log(totalNumberOfPages)
        $pagination.twbsPagination('destroy');
        $pagination.twbsPagination($.extend({}, $paginationOption, {
            startPage: currentpage,
            totalPages: totalNumberOfPages
        }));
    }

    function removeStockCard() {

        if (isDesktop){
            document.querySelectorAll('.stock-offer-card').forEach( function (c){
                c.remove();
            })
        } else {
            var cards = $(document).find('.stock-offer-card').length;
            for (var i = 0; i < cards; i++) {
                stockCards.trigger('remove.owl.carousel',[i]);
                stockCards.trigger('refresh.owl.carousel');
            }
        }
    }

    function disableMobilePrevBtn() {
        $('.pagination-mobile .prev').attr('disabled', true);
        $('.pagination-mobile .prev').addClass('disabled');
    }

    function disableMobileNextBtn() {
        $('.pagination-mobile .next').attr('disabled', true);
        $('.pagination-mobile .next').addClass('disabled');
    }

    function enableMobileNextBtn() {
        $('.pagination-mobile .next').attr('disabled', false);
        $('.pagination-mobile .next').removeClass('disabled');
    }

    function enableMobilePrevBtn() {
        $('.pagination-mobile .prev').attr('disabled', false);
        $('.pagination-mobile .prev').removeClass('disabled');
    }

    function getCardClass(perPage){
        if(isDesktop){
            switch (perPage){
                case 4:
                    return 'col-12 col-lg-3';
                case 6:
                    return 'col-12 col-lg-4';
                case 8:
                    return 'col-12 col-lg-3'
            }
        } else {
            return '';
        }
    }

    function renderStockCard() {
        removeStockCard();
        var template = TPL.stockCard;
        var country = stockSearchConfig.culture;
        callStockSearch().then(function (stocks){
            hideLoading();
            if (stocks.length === 0) {
                $pagination.twbsPagination('destroy');
                if (!isDesktop){
                    stockCards.data('owl.carousel').destroy();
                }
                $pagination.addClass('d-none')
                return;
            }

            $pagination.removeClass('d-none')
            currentpage = stocks.page;
            totalNumberOfPages = stocks.totalNumberOfPages;
            total = stocks.total;

            if (currentpage === totalNumberOfPages){
                disableMobileNextBtn();
            } else {
                enableMobileNextBtn()
            }
            if (total > 0) {
                //console.log(page, parameter)
                let html = '';
                //console.log(stocks.results)
               /* var isOdd = false;
                if (Object.keys(stocks.results).length <= 3) {
                    isOdd = true;
                }*/
                $.each(stocks.results, function (i, data){
                    var tplData = {
                        class: total<=3? 'col-12 col-lg-4' : getCardClass(parameter.resultsPerPage),
                        item: {
                            title: `${stockSearchConfig.lcdv_brand_label} ${stockSearchConfig.lcdv_model_label}`,
                            image: `${stocks.results[i].defaultMedias.V3DDefaultViews[0].url}&format=jpg&quality=85&height=218`,
                            optionsTitle: `${Object.keys(stocks.results[i].equipments).length} ${stockSearchConfig.label_options_available}`,
                            optionsSortTitle: `${Object.keys(stocks.results[i].equipments).length} ${stockSearchConfig.label_options}`,
                            options: getOptions(stocks.results[i].equipments),
                            co2class: stocks.results[i].consumption.wltp.emissions.cycle.CO2 === 0 ? 'A' : stocks.results[i].consumption.CO2class,
                            co2: stocks.results[i].consumption.wltp.emissions.cycle.CO2,
                            versionLabel: stocks.results[i].versionLabel,
                            locatorName: Object.keys(stocks.results[i].pointsOfSale).length ? stocks.results[i].pointsOfSale[0].tradeName : '',
                            city: Object.keys(stocks.results[i].pointsOfSale).length ? stocks.results[i].pointsOfSale[0].address[country].city : '',
                            zipcode: Object.keys(stocks.results[i].pointsOfSale).length ? stocks.results[i].pointsOfSale[0].address[country].zipCode : '',
                            address2: Object.keys(stocks.results[i].pointsOfSale).length ? stocks.results[i].pointsOfSale[0].address[country].line1 : '',
                            address: Object.keys(stocks.results[i].pointsOfSale).length ? stocks.results[i].pointsOfSale[0].address[country].line2 : '',
                            cta: Object.keys(stocks.results[i].pointsOfSale).length ? `${stockSearchConfig.quoteRequestURL}?stockUuid=`+stocks.results[i].uuid+`&productKey=${stockSearchConfig.productKey}` : null,
                            quoteRequestLabel: stockSearchConfig.quoteRequestLabel,
                        },
                        ENV: window.ENV
                    };
                    html = Mustache.render(template, tplData);
                    if (isDesktop){
                        cardContainer.append(html);
                    } else {
                        stockCards.trigger('add.owl.carousel',html);
                        stockCards.trigger('refresh.owl.carousel');
                    }

                    setParametersOffers();

                    /*if (parameter.resultsPerPage === 6 || parameter.resultsPerPage === 8) {
                        if (!isOdd) {
                            html += Mustache.render(template, tplData);
                            if (i % 2 === 1) {
                                stockCards.trigger('add.owl.carousel',html);
                                stockCards.trigger('refresh.owl.carousel');
                                html  = '';
                            }
                        } else {
                            html = Mustache.render(template, tplData);
                            stockCards.trigger('add.owl.carousel',html);
                            stockCards.trigger('refresh.owl.carousel');
                        }
                    } else {
                        html = Mustache.render(template, tplData);
                        stockCards.trigger('add.owl.carousel',html);
                        stockCards.trigger('refresh.owl.carousel');
                    }*/

                });
                renderStockOptionsPopup();
                $(".total-stock-around").text(stocks.total);
                renderPagination();
            } else {
                $pagination.twbsPagination('destroy');
                $(".total-stock-around").text(stocks.total);
            }
        });
    }

    function getOptions(equipments) {
        var labels = [];
        $.each(equipments, function (){
            var values = this.values;
            $.each(values, function (){
                labels.push(this.label);
            });
        });
        return labels;
    }

    function handleStockSearch() {
        $(".stockSearchForm .search-stock").on("click", function (e){
            if ($('#searchTextField').val() !== '') {
                parameter.geoLocalization = $("#cityLat").val()+';'+$("#cityLng").val();
            } else {
                parameter.geoLocalization = '';
            }
            parameter.page = 1;
            parameter.resultsPerPage = parseInt($(".stockSearchPerPage").val());
            parameter.distanceRadius = parseInt($(".stockSearchRadius").val());
            renderStockCard();
            isOptionsChanged = true;
           e.preventDefault();
        });

        $(".stockSearchPerPage").on("change", function (){
            parameter.page = 1;
            parameter.resultsPerPage = parseInt($(this).val());
            isOptionsChanged = true;
            renderStockCard()
        })
    }

    function setParametersOffers() {
        $('.stock-quote-btn .btn').on("click", function (e) {
            e.preventDefault();
            var hrefUrl = $(this).attr('href');
            if (new URL(document.baseURI).origin === new URL(hrefUrl, document.baseURI).origin) {
              var defaultUrl = new URL(hrefUrl, document.baseURI);
              var searchParams = defaultUrl.searchParams;
              var serviceInputValue = $('#additional_services_input').val();
              var leasingKmInput = $('#financial_calculator_light_leasingKm').data("name-parameter");
              var leasingKmInputValue = $('#financial_calculator_light_leasingKm').val();
              var leasingLenghtInput = $('#financial_calculator_light_leasingLength').data("name-parameter");
              var leasingLenghtInputValue = $('#financial_calculator_light_leasingLength').val();
              var leasingStandardRateInput = $('#financial_calculator_light_leasingStandardRate').data("name-parameter");
              var leasingStandardRateInputValue = $('#financial_calculator_light_leasingStandardRate').val();
              if(serviceInputValue) {
                searchParams.append('services', serviceInputValue);
              }
              if(leasingKmInputValue) {
                searchParams.append(leasingKmInput, leasingKmInputValue);
              }
              if(leasingLenghtInputValue) {
                searchParams.append(leasingLenghtInput, leasingLenghtInputValue);
              }
              if(leasingStandardRateInputValue) {
                searchParams.append(leasingStandardRateInput, leasingStandardRateInputValue);
              }
              defaultUrl.searchParams = searchParams;
              window.location  = defaultUrl.toString();
            } else {}
          })
    }

    async function callStockSearch() {
        try {
            showLoading();
            var response = await fetch(stockSearchConfig.url, {
                method: 'POST',
                body: JSON.stringify(parameter)
            });
            if(!response.ok) {
                hideLoading();

                return [];
                /**
                 * SHOW ERROR
                 */
            } else {
                var stocks = await response.json();
                if (stocks.success) {
                    return stocks.stocks;
                } else {
                    $(".total-stock-around").text(0);
                    resetSettings();
                    hideLoading();
                    return [];
                    /**
                     * SHOW ERROR
                     */
                }
            }
        } catch (err){
            console.log(err);
            return [];
        }
    }


    return {
        init: init
    };
})(jQuery);