var offerAnnotationsPopoverManager = (function(){

    function init(){
        $(function () {
            $('[data-toggle="popover_annotations"]').popover({
                sanitize: false,
                html:true,
                content: function() {
                    var contents = $(this).attr('data-annotations-popover-content').split('|');
                    var list = '<div>';
                    $.each(contents, function (index){
                        if (this.length) {
                            list += `<p class="pb-0 mb-0">${this}</p>`
                            if(index + 1 != contents.length ) list += `<hr class="my-1">`
                        }
                    })
                    list += '</div>';
                    return list;
                },
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
            });
          })
    }

    return {
        init: init
    };
})(jQuery);