var formManager = (function () {
    var $formPreventivo;
    function init() {
        $formPreventivo = $('.form-preventivo');

        initBrandFieldSearch();
        initializeProvinceSearch();
        collapseBottomTextFormPreventivo();
        fillQuoteRequestWithCarIdAndDem();
        offerBoxScrollToContentEffect();
        initializeSelect2SearchInput();

    }

    function initBrandFieldSearch() {
        $("#brand-field", $formPreventivo).select2({
            matcher: oldMatcher(matchStart)
        });
    }

    function initializeSelect2SearchInput() {
        $(document).on("click", ".form-preventivo .select2-selection", function () {
            var select = $(this).closest('div').find('select');
            if ($(select).attr('data-search') === 'true') {
                $("html").find(".select2-container").addClass("select2SearchView");
                $("html").find(".select2-container .select2-search__field").focus();
            }
        })
    }

    function collapseBottomTextFormPreventivo() {
        var selector = $(".collapseBottomText-coll");
        var target = null;
        var tabElement = null;
        if (selector.length) {
            selector.on("click",function () {
                target = $(this).attr("data-target");
                tabElement = $(target);

                if (!tabElement.is(':visible')) {
                    tabElement.show();
                } else {
                    tabElement.hide();
                }
            });
        }
    }

    function initializeProvinceSearch() {
        if ($formPreventivo.length) {
            $("#provincia", $formPreventivo).select2({
                matcher: oldMatcher(matchStart)
            });

            if ($("select#cap-field", $formPreventivo).length) {
                $("#cap-field", $formPreventivo).select2({
                    matcher: oldMatcher(matchStart),
                    minimumInputLength: ENV.zipCodes.autocompleteMinimumLength,
                    language: {
                        inputTooShort: function() {
                            return LABELS.zipCode.inputTooShort;
                        },
                        noResults: function() {
                            return LABELS.zipCode.noResult;
                        }
                    },
                    ajax: {
                        url: ENV.zipCodes.index,
                        data: function(params) {
                            return {
                                cap: params.term
                            };
                        },
                        dataType: 'json',
                        delay: 250,
                        processResults: function(data) {
                            var results = [];
                            $.each(data, function(index, cap) {
                                results.push({
                                    id: cap,
                                    text: cap,
                                });
                            });
                            return {results: results};
                        }
                    }
                });
            }


            $(document).on("click", "[aria-labelledby='select2-provincia-container']", function () {
                $("html").find(".select2-container").addClass("viewProvinciaDropdown");
                $("html").find(".select2-container .select2-search__field").focus();
            });
        }
    }

    function matchStart (term, text) {
        if (text.toUpperCase().indexOf(term.toUpperCase()) == 0) {
            return true;
        }
        return false;
    }

    function oldMatcher (matcher) {
        function wrappedMatcher (params, data) {
            var match = $.extend(true, {}, data);

            if (params.term == null || $.trim(params.term) === '') {
                return match;
            }

            if (data.children) {
                for (var c = data.children.length - 1; c >= 0; c--) {
                    var child = data.children[c];

                    // Check if the child object matches
                    // The old matcher returned a boolean true or false
                    var doesMatch = matcher(params.term, child.text, child);

                    // If the child didn't match, pop it off
                    if (!doesMatch) {
                        match.children.splice(c, 1);
                    }
                }

                if (match.children.length > 0) {
                    return match;
                }
            }

            if (matcher(params.term, $.trim(data.text), data)) {
                return match;
            }

            return null;
        }

        return wrappedMatcher;
    }

    function fillQuoteRequestWithCarIdAndDem() {
        var attrCarId = null;
        var attrDem = null;
        var attrProductKey = null;
        var anchor = null;
        var target = null;
        $('.newOfferBox a.request,.newOfferBoxSectionRequest a.request').on('click',function (e) {
            attrCarId = $(this).attr("carID");
            attrDem = $(this).attr("dem");
            attrProductKey = $(this).attr("productKey");
            fillQuoteRequestFormForLandingPageComeNuovo(attrDem,attrCarId,attrProductKey);
            anchor = $(this);
            target = $(anchor.attr('href'));
            $("#form-preventivo-target").collapse("show");
            if (typeof target.offset() == typeof undefined)
                return false;
            $('html, body').stop().animate({
                scrollTop: target.offset().top
            }, 1500);

            e.preventDefault();
        })
    }

    function fillQuoteRequestFormForLandingPageComeNuovo(attrDem,attrCarId,attrProductKey) {
        var dem = $("#dem");
        var offer_id = $("#offer_id");
        var productKey = $("#productKey");
        dem.val(attrDem);
        offer_id.val(attrCarId);
        productKey.val(attrProductKey);
    }

    function offerBoxScrollToContentEffect() {
        var attrCarId = null;
        var attrDem = null;
        var attrProductKey = null;
        var anchor = null;
        var target = null;
        $('.newOfferBox a.scopri[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            attrCarId = $(this).attr("carID");
            attrDem = $(this).attr("dem");
            attrProductKey = $(this).attr("productKey");
            fillQuoteRequestFormForLandingPageComeNuovo(attrDem,attrCarId,attrProductKey);
            anchor = $(this);
            target = $(anchor.attr('href'));
            var strToReplace = anchor.attr('href').replace("#","");
            $(".tab-content .tab-pane").each(function () {
                var myTab = $(this);
                if (strToReplace != myTab.attr("id") ){
                    myTab.removeClass("active in");
                }
            });
            $('.newOfferBox a.scopri[data-toggle="tab"]').each(function () {
                $(this).not(anchor).closest(".newOfferBoxList").removeClass("active");
            });
            if (typeof target.offset() == typeof undefined)
                return false;
            $('html, body').stop().animate({
                scrollTop: target.offset().top
            }, 1500);
            e.preventDefault();
        });
    }





    return {
        init: init
    };
})(jQuery);
