var leasysWorldCarouselManager = (function () {
    var mainLeasysWorldCarousel;
    var leasysWorldCarousel = $('.leasys-world-carousel .owl-carousel');
    var items = $(".item", leasysWorldCarousel)
    var nextBtn = $(".leasys-world .next-btn");
    var prevBtn = $(".leasys-world .prev-btn");
    function init() {
        $(document).ready(function () {
            if(leasysWorldCarousel.length){
                initCarousel();
                nextBtn.on("click", function (e) {
                    mainLeasysWorldCarousel.trigger('next.owl.carousel');
                    e.preventDefault();
                });
                prevBtn.on("click", function (e) {
                    mainLeasysWorldCarousel.trigger('prev.owl.carousel');
                    e.preventDefault();
                });
            }
        });
    }

    function initCarousel() {
        mainLeasysWorldCarousel = leasysWorldCarousel.owlCarousel({
            dots: false,
            nav: false,
            onInitialized: callback,
            onChanged: callback,
            loop: items.length <= 2 ? false : true,
            mouseDrag: false,
            center: items.length === 1 ? true : false,
            responsive:{
                0:{
                    items: 1,
                    mouseDrag: true,
                    dots: true
                },
                992: {
                    items: 2,
                    slideBy: 2
                },
                1200:{
                    items: 2,
                    slideBy: 2
                }
            }
        });
        function callback(event) {
            var item      = event.item.index;     // Position of the current item
            var items      = event.item.count;     // Position of the current item

            if (items > 2) {
                showNavBtn();
            }
            if(items <= 2) {
                hideNavBtn();
            }
            // if (item === 3) {
            //     disableNextBtn();
            //     enablePrevBtn();
            // } else if (item === 0) {
            //     enableNextBtn();
            //     disablePrevBtn();
            // }
        }
    }

    function hideNavBtn() {
        nextBtn.css({
            'visibility': 'hidden'
        });
        prevBtn.css({
            'visibility': 'hidden'
        })
    }
    function showNavBtn() {
        nextBtn.css({
            'visibility': ''
        });
        prevBtn.css({
            'visibility': ''
        });
    }

    function disableNextBtn() {
        nextBtn.addClass('disabled')
            .prop('disabled', true);
    }

    function enableNextBtn() {
        nextBtn.removeClass('disabled')
            .prop('disabled', false);
    }

    function disablePrevBtn() {
        prevBtn.addClass('disabled')
            .prop('disabled', true);
    }

    function enablePrevBtn() {
        prevBtn.removeClass('disabled')
            .prop('disabled', false);
    }

    return {
        init: init
    };
})(jQuery);
