var roundedBoxesSliderManager = (function () {
    var roundedBoxesCarousel;
    var indexBox = 0;
    var roundedBoxesSliderObj = $(".section.rounded-boxes-slider .owl-carousel");

    function init(){
        $(document).ready(function () {
            if(roundedBoxesSliderObj.length){
                initCarousel();
                alignHeight();
            }
        })
    }

    function alignHeight(){
        if(ENV.isEditmode) return;
        let roundedBoxes = $(".section.rounded-boxes-slider .owl-item .single-rounded-box-container");
        let maxHeight = 0;
        roundedBoxes.each(function(){
            currentHeight = $(this).innerHeight();
            if(currentHeight > maxHeight) maxHeight = currentHeight;
        })

        roundedBoxes.each(function(){
            $(this).css('height', maxHeight);
        })
    }

    function initCarousel(){
        $(".item", roundedBoxesSliderObj).each( function (k,v){
            if ($(this).hasClass('active')){
                indexBox = k;
            }
        });

        var boxesCount = $(".single-rounded-box-container").length;
        roundedBoxesCarousel = roundedBoxesSliderObj.owlCarousel({
            mouseDrag: true && !ENV.isEditmode,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            dots: false,
            center: boxesCount > 3,
            loop: boxesCount > 3 && !ENV.isEditmode,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 0,
                    singleItem: true,
                    dots: false,
                    center: true,
                    loop: true && !ENV.isEditmode,
                    nav:boxesCount > 1
                },
                992: {
                    items: 2,
                    loop: true && !ENV.isEditmode,
                    nav:boxesCount > 2
                },
                1200: {
                    items: boxesCount > 3 ? 3 : boxesCount,
                    stagePadding: 0,
                    margin: 0,
                    nav:boxesCount > 3
                }
            }
        });

        // if(boxesCount > 3 || true){
        //     $(".owl-nav").attr('style', 'display: inherit !important');
        // } else {
        //     $(".owl-nav").attr('style', 'display: none !important');
        // }
    }

    return {
        init: init
    }
})(jQuery);