var icarePackagesTabs = (function () {

    function init() {
        hideElements();
    }

    var hideElements = function(){

        var setOfTabs = $('.icare-pacchetti-tab');

        setOfTabs.on('click', function(){

            if(window.innerWidth <= 767){


                var smartTab = $('#icare-tab-smart').attr('id');
                var trackingTab = $('#icare-tab-tracking').attr('id');
                var advancedTab = $('#icare-tab-advanced').attr('id');

                var selectedTab = $(this);

                var selectedId = selectedTab.attr('id');

                var hideableSmart = $('.show-smart');
                var hideableTracking = $('.show-tracking');
                var hideableAdvanced = $('.show-advanced');

                if(selectedTab.hasClass('active-tab')){
                    setOfTabs.removeClass('active-tab');
                    selectedTab.removeClass('active-tab');
                    switch(selectedId){
                        case smartTab: {
                            hideableSmart.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            break;
                        }
                        case trackingTab: {
                            hideableTracking.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            break;
                        }
                        case advancedTab: {
                            hideableAdvanced.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            break;
                        }
                    }
                }else{
                    setOfTabs.removeClass('active-tab');
                    selectedTab.addClass('active-tab');
                    switch(selectedId){
                        case smartTab: {
                            hideableTracking.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            hideableAdvanced.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            hideableSmart.each(function(i, el){
                                var element = $(el);
                                element.removeClass('d-none d-sm-flex');
                                element.slideDown(300);
                            });
                            break;
                        }
                        case trackingTab: {
                            hideableSmart.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            hideableAdvanced.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            hideableTracking.each(function(i, el){
                                var element = $(el);
                                element.removeClass('d-none d-sm-flex');
                                element.slideDown(300);
                            });
                            break;
                        }
                        case advancedTab: {
                            hideableSmart.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            hideableTracking.each(function(i, el){
                                var element = $(el);
                                element.addClass('d-none d-sm-flex');
                                element.slideUp(300);
                            });
                            hideableAdvanced.each(function(i, el){
                                var element = $(el);
                                element.removeClass('d-none d-sm-flex');
                                element.slideDown(300);
                            });
                            break;
                        }
                    }
                }

            }
        })
    };

    return {
        init: init
    };

})(jQuery);