var orgChartManager = (function () {
    function init() {
        loadOrgChart()
    }

    function loadOrgChart() {
        return;
        var url = ENV.organigramma;
        $.ajax({
            method: "GET",
            url: url,
            dataType: "json"
        })
            .done(function( ret ) {
                renderItems(ret.data.items);
            });
    }


    function renderItems(items) {

        var template;

        template = TPL.organigramma;


        if(items.length>0) {
            $.each(items, function(i,item){
                var tplData = {
                    item: item,
                    ENV: window.ENV
                };

                var html = Mustache.render(template, tplData);
                $(".governance-chart.here").append(html);

            });
        } else {
            //console.log(items);
        }

    }

    return {
        init: init
    };
})(jQuery);
