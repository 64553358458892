var generateCsrfToken = (function () {
    var endpoint = '/api/generateCsrfToken'

    function init() {
        method = typeof method === 'undefined' ? '' : method.toUpperCase();
        if($("form") && $("form").length && method == "POST" ){
            $.ajax({
                url: endpoint,
                type: 'get',
                dataType: 'json',
                success : function (res) {
                    $('form').append("<input type='hidden' name='csrfToken' id='csrf_token_input' value='csrf_value' />")
                    $("#csrf_token_input").val(res.data.token)
                },
                error: function (err) {
                    console.log(err);
                }
            })
        }
    }
    return {
        init: init
    }
})(jQuery);