var businessLinesManager = (function () {
    var businessLines = $('.business-lines');
    var container = $(".business-lines-container");
    function init() {
        $(document).ready(function () {
            if (businessLines.length) {
                managerBusinessLines();
            }
        });
    }

    function managerBusinessLines() {
        var video = $("video.html5", container);
        video.css({
            'height': container.outerWidth()
        });

    }

    return {
        init: init
    };
})(jQuery);
