var leasysWizardManager = (function () {

    var wizardScreen = $(".wizard-screen");
    var selectedElemToPlace = $(".wizard-data-container", wizardScreen);
    var selectecElemToClone = $(".wizard-data-container li.to-clone", wizardScreen);
    var counter = 0;
    var numStep = 0;
    var wizardDataObj = {};

    var stepDefinerArray = [];

    function init() {
        if (wizardScreen.length) {
            wizardEventListener();
            initCarousel();
            initializeProgressDataAttribute();
            sendWizardData();
            //gamificationManager();
        }
    }

    function gamificationManager() {
        var bottom_of_object = null;
        var bottom_of_window = null;
        // if (true){
        //     $(window).on('scroll', function() {
        //         bottom_of_object = $(".wizard .btn:visible").offset().top + $(".wizard .btn:visible").outerHeight();
        //         bottom_of_window = $(window).scrollTop() + $(window).height();
        //         console.log(bottom_of_object, bottom_of_window);
        //         if( bottom_of_window > bottom_of_object) {
        //             $(".side-menu .launch-wizard").closest("li").animate({
        //                 opacity: '0'
        //             }, {
        //                 duration: 1000,
        //                 complete: function(){
        //                     console.log('down');
        //                 }
        //             });
        //         }
        //     });
        // }
    }

    function wizardEventListener() {
        var wizardBtn = $(".launch-wizard");
        var closeBtn = $(".close", wizardScreen);
        var body = $("body");

        $(document).on("click",".wizard-data", function (e) {
            delete wizardDataObj[$(this).attr('data-key-selected')];
            $(this).closest("li").remove();
            e.preventDefault();
        });


        closeBtn.on("click",function () {
            body.removeClass('no-scroll');
            wizardScreen.removeClass('show');
            $(".step.end",wizardScreen).removeClass('show');
            wizardDataObj = {};
            $(".wizard-screen-progress").addClass('d-none');
            $(".wizard-data-container .to-remove").remove();
            $(".wizard-screen-progress.wizard-screen-progress--start .wizard-screen-progress-bar").css({
                'width':'0%'
            }).removeClass('in-progress');
            $(".wizard-screen-progress.wizard-screen-progress--start .car").css({
                'left': '-10px',
            }).removeClass('in-progress');
        });

        wizardBtn.on("click", function (e) {
            wizardScreen.addClass('show');
            body.addClass('no-scroll');
            e.preventDefault();
            $(".step.start",wizardScreen).addClass('show');
        });

        $(document).on("click",".wizard-screen .step [data-current-step]", function (e) {
            goToStep($(this));
            e.preventDefault();
        });
    }

    function animateLastStep() {
        if ($(window).width()<992) {
            $('.wizard-screen-progress--end .car').animate({
                left: '94%'
            }, {
                duration: 3500,
                complete: function(){
                    $(".step.end .btn").removeClass('invisible');
                }
            });
        } else if ($(window).width()<767) {
            $('.wizard-screen-progress--end .car').animate({
                left: '89%'
            }, {
                duration: 3500,
                complete: function(){
                    $(".step.end .btn").removeClass('invisible');
                }
            });
        } else {
            $('.wizard-screen-progress--end .car').animate({
                left: '95.5%'
            }, {
                complete: function(){
                    $(".step.end .btn").removeClass('invisible');
                }
            });
        }
    }

    function hideWizardProgressBar() {
        $(".wizard-screen-progress--start").addClass('d-none');
    }

    function showWizardProgressBar() {
        $(".wizard-screen-progress--start").removeClass('d-none');
    }

    function initializeProgressDataAttribute() {
        hideWizardProgressBar();
        stepDefinerArray.push('.start');
        $(".step", wizardScreen).not('.start, .end').each(function () {
            counter++;
            numStep++;
            stepDefinerArray[counter] = '.step-'+counter;
        });
        stepDefinerArray.push('.end');

        counter = (100/counter);

    }

    function managerProgressBar(step) {
        var currentStep = step.attr('data-current-step');
        var progressContainer = $(".wizard-screen-progress.wizard-screen-progress--start");
        var progressBar = $(".wizard-screen-progress-bar", progressContainer);
        var progressCar = $(".car", progressContainer);
        if (currentStep > 0) {
            progressBar.addClass('in-progress');
            progressCar.addClass('in-progress');
            progressCar.css({
                'left': counter*(currentStep)+'%'
            });
            progressBar.css({
                'width': counter*(currentStep)+'%'
            })
        }
    }

    function collectWizardData(step) {
        var currentStep = parseInt(step.attr('data-current-step'));
        var valueForObj = step.attr("data-value");
        var key = step.attr("data-key");
        var valueToShow = step.find(".figure-caption").text();
        if (currentStep>0){
            if (valueToShow!=='') {
                var elem = selectecElemToClone.clone().addClass('to-remove').removeClass('d-none');
                $("a", elem).attr('data-value-selected',currentStep);
                $("a", elem).attr('data-key-selected',key);
                elem.appendTo(selectedElemToPlace);
                $("[data-value-selected='"+currentStep+"']", wizardScreen).removeClass('d-none').text(valueToShow);
            }
            if (typeof key !== typeof undefined) {
                wizardDataObj[key] = valueForObj
            }
        }
    }

    function initCarousel() {
        $('.owl-carousel', wizardScreen).each(function () {
            $(this).owlCarousel({
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                items: 3,
                stagePadding: 25,
                nav: true,
                dots: false,
                center: true,
                margin: -55,
                loop: true,
                responsive:{
                    0:{
                        items: 1,
                        stagePadding: 20,
                        singleItem: true,
                        dots: false,
                        center: true,
                        margin: -135,
                        loop: true,
                    },
                    767: {
                        items: 2,
                        stagePadding: 20,
                        singleItem: true,
                        dots: false,
                        center: true,
                        margin: -100,
                        loop: true,
                    }
                }
            })
        })
    }

    function goToStep($this) {
        var step = parseInt($this.attr('data-current-step'));
        var currentStep = $(stepDefinerArray[step]);
        var nextStep = $(stepDefinerArray[step+1]);
        currentStep.removeClass('show');
        nextStep.addClass('show');
        showWizardProgressBar();
        managerProgressBar($this);
        collectWizardData($this);
        if (step===0){
            animateProgressBarOnFirstStep(currentStep)
        }
        if (step >= numStep) {
            hideWizardProgressBar();
            animateLastStep();
        }
    }

    function animateProgressBarOnFirstStep() {
        $(".wizard-screen-progress--start", wizardScreen).addClass('fadeIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            $(".wizard-screen-progress--start", wizardScreen).removeClass('fadeIn animated');
        });

    }

    function sendWizardData() {
        $(".wizard-data-send-btn").not('invisible').on("click", function (e) {
            if (ENV.offerPage !== '' || typeof ENV.offerPage !== typeof undefined) {
                window.location.replace(ENV.offerPage + '?'+$.param(wizardDataObj));
            }
        });
    }


    return {
        init: init,
        animateLastStep: animateLastStep,
        showWizardProgressBar: showWizardProgressBar,
        hideWizardProgressBar: hideWizardProgressBar
    };
})(jQuery);
