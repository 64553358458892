var boxCarouselManager = (function () {
    var mainBoxCarousel;
    var boxCarouselObj = $('.box-carousel .owl-carousel');
    var navPillsObj = $('.nav-pills.owl-carousel');
    var navPillsCounter = $(".nav-item", navPillsObj).length;
    function init() {
        $(document).ready(function () {
            if(navPillsObj.length) {
                initNavPillsCarousel();
            }
            if (boxCarouselObj.length) {
                initBoxCarousel();
            }
        });
    }

    function initNavPillsCarousel() {
        navPillsObj.owlCarousel({
            dots: false,
            loop: true,
            center: true,
            drag: false,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            navClass: ['owl-prev owl-prev--small', 'owl-next owl-next--small'],
            responsive:{
                0:{
                    items: 3,
                    margin: 10,
                    stagePadding: 45,
                    nav: false,
                    dots: false,
                    loop: true,
                    center: true,
                },
                992:{
                    items: 5,
                    stagePadding: 80,
                    margin: 10,
                    nav: false,
                    dots: false,
                    loop: true,
                    center: true,
                    touchDrag: true
                },
                1200: {
                    items: navPillsCounter < 6 ? navPillsCounter : 8,
                    loop: false,
                    center: false,
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,
                    freeDrag: false,
                    margin: -25,
                    nav: !(navPillsCounter < 6)
                },
                1420: {
                    items: navPillsCounter,
                    loop: false,
                    center: false,
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,
                    freeDrag: false,
                    nav: true
                }
            }
        });

    }

    function initBoxCarousel() {

        mainBoxCarousel = boxCarouselObj.owlCarousel({
            nav: false,
            dots: false,
            margin: 34,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            autoWidth:false,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 70,
                    margin: 15,
                    loop: false,
                    center: true
                },
                992:{
                    items: 3,
                    stagePadding: 30,
                    margin: 15,
                    loop: false,
                    center: false
                },
                1200:{
                    items: 4
                }
            }
        });
    }

    return {
        init: init
    };
})(jQuery);
