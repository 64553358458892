var contactPageTrackManager = (function () {

    function init(){
        $(document).ready(function(){
            //track only in contact pages
            if(window.location.pathname == ENV.contactPage){
                trackTelephoneClick();
                trackEmailClick();
            }
        });
    }

    //track telephone click
    function trackTelephoneClick(){
        $("a").on("click",function(){
            var href = $(this).attr("href");
            if(href.includes("tel:")){
                var telephone = href.replace("tel:", '').trim();

                if($(this).closest(".owl-carousel.owl-theme.international-contacts-box").length){
                    var telephoneName = $(this).closest(".item").find("h5").text().trim();
                }

                if($(this).closest(".icons-text-list-cards-component").length){
                    var telephoneName = $(this).closest(".single-card").find(".card-title").text().trim();
                }

                if($(this).closest(".vertical-image-text").length){
                    var telephoneName = $(this).closest(".item").find("h4").first().text().trim();
                }

                if($(this).closest(".image-text-list-cards-component").length){
                    var telephoneName = $(this).closest(".card-text-container").find(".card-title").text().trim();
                }

                manageGTM.trackContactPageClick("Telephone - Click", telephoneName ? telephoneName : telephone);
            }
        });
    }

    //track email click
    function trackEmailClick(){
        $("a").on("click",function(){
            var href = $(this).attr("href");
            if(href.includes("mailto:")){
                var email = href.replace("mailto:", '').trim();
                manageGTM.trackContactPageClick("Email - Click", email);
            }
        });
    }

    return {init: init};

})(jQuery);