var objectListingManager = (function () {

   
    var carousel = $('.object-listing-component .owl-carousel') 
    var cardItems = carousel.find('.card-container').length;

    function init(){
        $(document).ready( function(){
            if($ ('.object-listing-component').length <= 0){
                return;
            }
            else{
                $('.object-listing-component').each(function(){
                    var occurences = 0;
                    var item = ''
                    occurences = $(this).find('.item').length;
                    console.log(occurences);
                    item = $(this).find('.item')[0];
                    var arr = $(item).attr('class').split(" ");
                    startCarousel(occurences,arr[1]);
                })
            }
            
        })
    }

    function startCarousel(occurences,item){
        $('.object-listing-component .owl-carousel'+' .'+item).closest('.object-listing-component .owl-carousel').owlCarousel({
            navText: [
                "<i class='fas fa-arrow-left'></i>",
                "<i class='fas fa-arrow-right'></i>"
            ],
            dots: false,
            center: true,
            loop: false,
            onInitialized: callback,
            onResized: callback,
            onRefreshed: callback,
            responsive:{
                0:{
                    mouseDrag: true,
                    items: 1,
                    stagePadding: 25,
                    singleItem: true,
                    dots: false,
                    center: false,
                    loop: false,
                    nav: false,
                },
                768:{
                    mouseDrag: true,
                    items: 2,
                    stagePadding: 17,
                    singleItem: true,
                    dots: false,
                    center: false,
                    loop: false,
                    nav: false,

                },
                992:{
                    mouseDrag: false,
                    items: 3,
                    stagePadding: 0,
                    singleItem: false,
                    dots: false,
                    center: false,
                    loop: false,
                    nav: occurences > 3,
                }
            }
        });
        console.log(item+'refresh');
    }

    function callback(events) {
        console.log(events);
        var offerCards = $('.object-listing-component .owl-carousel .offer-card');
        if($(document).ready()){
            
            offersCarouselManager.fullCardResizeGeneric(offerCards);

            if($(window).width() < 767) {
                console.log('test-callback-mobile');
                var classToCheck = ['.offer-price-details', '.car-properties', '.offer-title', '.offer-subtitle', '.products-banner, .offer-card-cta-container','.products-banner, .offer-card-cta-container-custom', '.first-bottom-text', '.second-bottom-text', '.offer-box'];

                classToCheck.forEach((className) => {
                    offerCards.find(className).css('height', 'auto');
                    var maxElementHeight = Math.max.apply(null, offerCards.map(function ()
                    {
                        var elements = $(this).find(className);
                        if (elements.length>1){
                            var maxHeightLocal = 0;
                            elements.each(function () {
                                maxHeightLocal = ($(this).innerHeight()>maxHeightLocal)? $(this).innerHeight() : maxHeightLocal;
                            });
                            return maxHeightLocal;
                        }else {
                            return elements.innerHeight()
                        }
                    }).get());

                    if(className == '.offer-box'){
                        offerCards.find(className).css('min-height', maxElementHeight);
                    }else{
                        offerCards.find(className).css('height', maxElementHeight);
                    }
                })
            }
        
        }
    }

  return {init: init}

})(jQuery);