var blockBasicProR23 = (function () {
    var componentBlock = $('.block-basic-pro-r23[data-rows="true"]');
    function init() {
        $(document).ready(function () {
            componentBlock.each(function() {
                if (componentBlock) {
                    showHidePanel();
                    initCarouselCard();
                }
              });
        });
    }

    function showHidePanel() {
        componentBlock.each(function() {
            var This = $(this);
            $(this).find('.tab-content>.block-basic-pro-r23__inner__row__column:not(.block-basic-pro-r23__inner__row__colum__active)').hide();
            $(this).find('.block-basic-pro-r23__inner__row__img__link[href^="#"]').on('click', function (event) {
                event.preventDefault();
                This.find('.tab-content>.block-basic-pro-r23__inner__row__column').hide();
                var target = $(this).attr('href');
                This.find('.block-basic-pro-r23__inner__row__img__link').removeClass('block-basic-pro-r23__inner__row__img__link__active');
                $(this).addClass('block-basic-pro-r23__inner__row__img__link__active');
                This.find('.block-basic-pro-r23__inner__row__column' + target).toggle();
            });
        });
    }

    function initCarouselCard () {
        var cardCarousel = componentBlock.find('.block-basic-pro-r23__inner__row__img-inner');
        var cardCarouselItem = componentBlock.find('.block-basic-pro-r23__inner__row__img');
        if (cardCarousel.length && cardCarouselItem.length > 2){
            cardCarousel.each(function (){
                $(this).owlCarousel({
                    navText: [
                        "<i class='prev-icon'></i>",
                        "<i class='next-icon'></i>"
                    ],
                    loop: false,
                    nav: $(this).find('.owl-item').lenght > 3 ? $(this).find('.owl-item').lenght : true,
                    dots: false,
                    items: 3,
                    margin: 34,
                    responsive:{
                        0:{
                            items:1.5,
                            nav: false
                        },
                        992:{
                            items:2.5,
                            nav: false

                        },
                        1200:{
                            items:3
                        }
                    }
                })
            })
        }
    }

    return {
        init: init
    };
})(jQuery);
