var blogManager = (function ($) {
  var $DOM = {};
  var isLastPage = true;
  var currentPage = 0;
  var totalPages = 1;
  var blogFilters = {};
  var availableFilters = ["page", "category"];
  var noResultsPlace = $(".blog-listing-container .blog-no-results");
  var loadMoreContainer = $(".blog-listing-loadmore-container");
  var navigationPlace = $(".blog-listing-pagination");

  $(".blog-detail-single-tag").on("click", function (tag) {
    return;
    if(!($(".blog-filters").length)) return;
    isLastPage = false;
    currentPage = 0;
    filter = $(this).parent().attr("data-filter");
    filterOptionValue = $(this).attr("data-filter-option-value");

    if (
      blogFilters[filter] &&
      blogFilters[filter].includes(filterOptionValue)
    ) {
      let index = blogFilters[filter].indexOf(filterOptionValue);
      blogFilters[filter].splice(index, 1);
      $(this).removeClass("selected");
      if (!blogFilters[filter].length) delete blogFilters[filter];
    } else {
      if (!blogFilters[filter]) {
        blogFilters[filter] = [];
      }

      blogFilters[filter].push(filterOptionValue);
      $(this).addClass("selected");
    }
    
    loadMoreContainer.removeClass("d-none");
    loadMoreContainer.addClass("d-flex");

    //loadBlogs(true);
  });

  $("input.blog-search-input").keypress(function (e){
    if(e.which == 13){
        blogFilters['keywords'] = [$(this).val().replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"").trim()];
        updateUrlWithFilter(blogFilters);
        loadBlogs(true);
    }
  })

  //PAGE NAVIGATION
  navigationPlace.find(".navigation-botton").on("click", function (e) {
    if ($(this).hasClass("prev") && !$(this).hasClass("deactivated")) {
      currentPage--;
      loadBlogs();
    } else if ($(this).hasClass("next") && !$(this).hasClass("deactivated")) {
      currentPage++;
      loadBlogs();
    }
  });

  function updateUrlWithFilter(filters) {
    return;

    if(!Object.keys(filters).length){
      history.pushState(
          null,
          document.title,
          window.location.pathname
        );
        return;
  } 
  urlFilters = {};
    filtersKeys = Object.keys(filters);
    filtersKeys.forEach((key) => {
      urlFilters[key] = filters[key].join("|");
    });
    history.pushState(
      null,
      document.title,
      window.location.pathname + "?" + $.param(urlFilters)
    );
  }

  function init() {
    $(document).ready(function(){

      $DOM.wrapper = $(".blog-listing-container");
      $DOM.filters = $(".blog-filters");

      $DOM.filtersForm = $(".blog-filters").find("form");
      if (!$DOM.wrapper.length) {
        return;
      }

      $DOM.container = $DOM.wrapper.find("main");
      $DOM.blogPlace = $DOM.wrapper.find(".blogs-listing-container-place");
      $DOM.blogPlaceSmall = $DOM.wrapper.find(".blogs-listing-container-place--small");
      $DOM.loading = $("<div class='loading d-none'></div>");

      updateFiltersWithUrl();
      updateSelectedFilterUrl();
      //loadBlogs();
      //setPagination();

      /*
      window.onscroll = function () {
        
        if(loadMoreContainer.hasClass("d-none")){
            return;
        }
        var hT = loadMoreContainer.not(".d-none").offset().top;
        var hH = loadMoreContainer.outerHeight();
        var wH = $(window).height();
        var wS = $(this).scrollTop();
  
        if (wS > (hT+hH-wH)){
          
            if(currentPage + 1 < totalPages){
                currentPage++;
                loadBlogs();
            } 
        }
      };
      */
    });
  }

  function updateFiltersWithUrl() {
    urlParams = new URLSearchParams(window.location.search);
    availableFilters.forEach((filter) => {
      if (filter != "page") {
        if (urlParams.get(filter)) {
          filterContainer = $(".blog-filters[data-filter*=" + filter + "]");

          urlParams
            .get(filter)
            .split("|")
            .forEach((filterOption) => {
              filterContainer
                .find(
                  ".blog-detail-single-tag[data-filter-option-value*=" +
                    filterOption +
                    "]"
                )
                .addClass("selected");
            });

          blogFilters[filter] = urlParams.get(filter).split("|");
        }
      } else {
        return;
        if (urlParams.get(filter)) {
          currentPage = urlParams.get(filter) - 1;
        } else {
          currentPage = 0;
        }
      }
    });
  }

  function updateSelectedFilterUrl(){
    var filterUrl = window.location.href.split('?')[0];
    $(".blog-detail-single-tag.selected").parent().attr("href", filterUrl);
  }

  function loadBlogs(emptyList = false) {
    return;
    updateUrlWithFilter(blogFilters);
    var url = $DOM.container.data("load-more-url");

    noResultsPlace.addClass("d-none");

    var mainFilters = {
      filters: JSON.stringify(blogFilters),
      return: "json",
      page: currentPage + 1,
    };

    //GET BLOGS
    var data = {
      filters: mainFilters,
    };
    $.ajax({
      method: "GET",
      url: url,
      data: data.filters,
      dataType: "json",
    }).done(function (ret) {
      if (ret.success) {
        totalPages = ret.data.totalPages;
        if(totalPages == (currentPage + 1)){
          loadMoreContainer.removeClass("d-flex");
          loadMoreContainer.addClass("d-none");
      }
        renderBlogs(ret.data.items, emptyList);
        setTotalItems(ret.data.totalItems, ret.data.items);
        
        
      }
    });
  }

  function setTotalItems(total, items) {
    return;
    if (!items.length) {
      total = 0;
      loadMoreContainer.removeClass("d-flex");
            loadMoreContainer.addClass("d-none");
    }
    totalResultContainer = $(
      ".blog-listing-container span.filtered-results-number"
    );
    totalResultContainer.text(total);
    $(".blog-listing-container .results-counter").removeClass("d-none");
  }

  function renderBlogs(blogs, emptyList = false) {
    return;
    if(emptyList){
      $DOM.blogPlace.empty();
      $DOM.blogPlaceSmall.empty();
    }
    
    var blogTemplate;

    blogTemplate = TPL.blogs_listing;

    if (blogs.length > 0) {
      noResultsPlace.removeClass("d-block");
      noResultsPlace.addClass("d-none");

      $.each(blogs, function (i, blog) {
        var tplData = {
          item: blog,
          isSecond: i == 1,
          highlightItem: [0, 1].includes(i) && currentPage == 0,
          ENV: window.ENV,
        };
        var html = Mustache.render(blogTemplate, tplData);

        if(i > 1  || !emptyList){
          $DOM.blogPlaceSmall.append(html);
        }else {
          $DOM.blogPlace.append(html);
        }
        
      });
      setPagination();
    } else {
      noResultsPlace.removeClass("d-none");
      noResultsPlace.addClass("d-block");
      navigationPlace.removeClass("d-flex");
      navigationPlace.addClass("d-none");
    }
  }

  function setPagination() {
    return;
    currentPageContainer = navigationPlace.find("span.current-page");
    totalPagesContainer = navigationPlace.find("span.total-pages");
    currentPageContainer.text(currentPage + 1);
    totalPagesContainer.text(totalPages);

    if (currentPage == 0) {
      currentPageContainer
        .parent()
        .siblings(".navigation-botton.prev")
        .addClass("deactivated");
    } else {
      currentPageContainer
        .parent()
        .siblings(".navigation-botton.prev")
        .removeClass("deactivated");
    }
    if (currentPage == totalPages - 1) {
      totalPagesContainer
        .parent()
        .siblings(".navigation-botton.next")
        .addClass("deactivated");
    } else {
      totalPagesContainer
        .parent()
        .siblings(".navigation-botton.next")
        .removeClass("deactivated");
    }
  }

  return {
    init: init,
    //loadBlogs: loadBlogs,
  };
})(jQuery);
