var newsImageCarouselManager = (function () {
    var mainCarousel;
    var newsImagesObj = $(".news-images");
    var newsCarousel = $('.owl-carousel', newsImagesObj);
    var previewSize = newsCarousel.data("preview-size");
    var itemCount = $(".item", newsCarousel).length;

    function init() {
        $(document).ready(function () {
            if(newsImagesObj.length) {
                initnewsCarousel();
                eventHandler();
            }
        });
    }

    function eventHandler() {
        $('.item', newsImagesObj).click(function (e) {
            if (itemCount === 2) {
                $(this).closest('.owl-item').removeClass('active');
            }
            mainCarousel.trigger('to.owl.carousel', $(this).attr('data-index'));
            changeContent($('a', this));
            var attr = $('a', this).attr("data-lity");
            if(!(typeof attr !== typeof undefined && attr !== false)) {
                e.preventDefault();
                return false;
            }
        });
    }

    function changeContent($this) {
        var title = $this.attr("title");
        var subtitle = $this.attr("data-subtitle");
        var description = $this.attr("data-description");
        var target1 = $this.attr("data-target-1");
        var target2 = $this.attr("data-target-2");
        var label1 = $this.attr("data-label-1");
        var label2 = $this.attr("data-label-2");
        $("h3", newsImagesObj).text(title);
        $("h5", newsImagesObj).text(subtitle);
        $("p", newsImagesObj).text(description);
        if (target1 !== '') {
            $(".link1", newsImagesObj)
                .removeClass('d-none')
                .text(label1)
                .attr('href',target1);
        } else {
            $(".link1", newsImagesObj).addClass('d-none');
        }
        if(target2 !== '') {
            $(".link2", newsImagesObj)
                .removeClass('d-none')
                .text(label2)
                .attr('href',target2);
        } else {
            $(".link2", newsImagesObj).addClass('d-none');
        }
    }

    function initnewsCarousel() {
        var defaultDesktopConfig = {
            items: (typeof previewSize !== "undefined" && previewSize == "big") ? 2 : 3,
            nav: true,
            loop: true,
            center: (typeof previewSize !== "undefined" && previewSize == "big") ? false : true
        };

        var desktopConfig = {
            1: {
                items: 1,
                loop: false,
                nav: false,
                center: false,
                mouseDrag: false
            },
            2: {
                items: 2,
                loop: false,
                nav: false,
                center: false,
                mouseDrag: false
            }
        };

        var defaultMobileConfig = {
            items: 1,
            nav: true,
            loop: true,
            center: true
        };
        var mobileConfig = {
            1: {
                items: 1,
                loop: false,
                nav: false,
                center: false
            },
        };

        mainCarousel = newsCarousel.owlCarousel({
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            onInitialized: onInitializedCallback,
            onChanged: callback,
            dots: false,
            responsive: {
                0: itemCount > 1 ? defaultMobileConfig : mobileConfig[itemCount],
                1200: itemCount > 3 ? defaultDesktopConfig : desktopConfig[itemCount]
            }
        });

        var activeItem = $(".item.active", newsImagesObj);
        if (activeItem.length) {
            mainCarousel.trigger('to.owl.carousel', activeItem.attr('data-index'));
            changeContent($('a', activeItem));
        }
    }

    function onInitializedCallback() {
        detectCenterElement();
    }


    function callback(event) {
        var current = $(event.target).find(".owl-item").eq(event.item.index).find("a");
        changeContent($(current));
    }

    function detectCenterElement() {
        var centerElem = $(".owl-item.active.center", newsImagesObj);
        $(".owl-item", newsImagesObj).each(function(index, elem){
            if (!!!centerElem.length) {
                $(elem).addClass("force-display");
            } else {
                $(elem).removeClass("force-display");
            }
        });

    }

    return {
        init: init
    };
})(jQuery);
