var newOffersManager = (function($){
    function init() {
        loadDoubleRange();
        autoSearchOffers(false);
        autoSearchOffersTop();
        hideTargetClientOffers();
        resetSliderRange();
        filterCollapseMobileOffers();
        filterLoadTargetTopOnPageLoad();
    }

    function loadDoubleRange() {
        $( ".slider-range" ).each(function (){
            var slider_range_contentbox = $(this);
            var min = $(this).find( ".slider-min-sl" );
            var min_start = parseInt($(this).find( ".slider-min" ).text());
            var max = $(this).find( ".slider-max-sl" );
            var max_start = parseInt($(this).find( ".slider-max" ).text());
            var checkbox = "";
            var checked = "";
            var slider_label = slider_range_contentbox.find(".slider-label").text();
            $(this).find(".slide").slider({
                range: true,
                min: min_start,
                max: max_start,
                step: 10,
                values: [ min_start, max_start ],
                slide: function( event, ui ) {
                    min.text( ui.values[ 0 ] );
                    max.text( ui.values[ 1 ] );
                },
                stop: function(event, ui) {
                    let filter = event.target.id.split("-")[event.target.id.split("-").length - 1];
                    let localeSeparator = ENV.market == 'uk' ? 'en-UK' : 'it-IT';
                    checkbox = slider_range_contentbox.find(".form-check-input");
                    var startRange = ui.values[0].toLocaleString(localeSeparator);
                    var endRange = ui.values[1].toLocaleString(localeSeparator)
                    checkbox.val( startRange + '-' + endRange + ' ' + slider_label );
                    //checkbox.prop( "checked", false ).trigger('click');
                    //checkbox.prop( "checked", true ).trigger('click');
                    checked = checkbox.is(":checked");
                    //console.log(checked);
                    if(checked){
                        checkbox.prop( "checked", false );
                        checkbox.trigger('click');
                    }else{
                        checkbox.trigger('click');
                    }
                    //autoSearchOffers(true);
                    $('#' + filter + '_filter .slider-min-sl-custom').text(startRange);
                    $('#' + filter + '_filter .slider-max-sl-custom').text(endRange);
                }
            });
            jQuery(document).on('touchstart', '.slider-range .ui-slider-handle', function (e) {
                let t = e.touches[0] || e;
                jQuery(this).addClass('ui-state-hover').addClass('ui-state-active').addClass('ui-state-focus')
                var newEvent = new MouseEvent('mousedown', {
                    screenX: t.screenX, screenY: t.screenY,
                    clientX: t.clientX, clientY: t.clientY,
                    relatedTarget: t.target,
                })
                Object.defineProperty(newEvent, 'target', {value: t.target, enumerable: true});
                Object.defineProperty(newEvent, 'currentTarget', {value: t.target, enumerable: true});
                jQuery(this).parent().slider("instance")._mouseDown(newEvent)
            });
            jQuery(document).on('touchend', '.slider-range .ui-slider-handle', function (e) {
                let t = e.touches[0] || e;
                jQuery(this).removeClass('ui-state-hover').removeClass('ui-state-active').removeClass('ui-state-focus')
                var newEvent = new MouseEvent('mouseup', {
                    screenX: t.screenX, screenY: t.screenY,
                    clientX: t.clientX, clientY: t.clientY,
                    relatedTarget: t.target,
                })
                Object.defineProperty(newEvent, 'target', {value: t.target, enumerable: true});
                Object.defineProperty(newEvent, 'currentTarget', {value: t.target, enumerable: true});
                jQuery(this).parent().slider("instance")._mouseUp(newEvent)
            });
            jQuery(document).on('touchmove', '.slider-range .ui-slider-handle', function (e) {
                let t = e.touches[0] || e;
                var newEvent = new MouseEvent('mousemove', {
                    screenX: t.screenX, screenY: t.screenY,
                    clientX: t.clientX, clientY: t.clientY,
                    relatedTarget: t.target,
                    'bubbles': true,
                    'cancelable': true,
                });
                Object.defineProperty(newEvent, 'target', {value: t.target, enumerable: true});
                Object.defineProperty(newEvent, 'currentTarget', {value: t.target, enumerable: true});
                jQuery(this).parent().slider("instance")._mouseMove(newEvent);
            });
            min.text( $(this).find(".slide").slider( "values", 0 ) );
            max.text( $(this).find(".slide").slider( "values", 1 ) );
        });
    }

    function resetSliderRange(){
        $(".deleteAllFilters").on("click", function () {
            // Reset Slider Range on click of delete all filters
            $( ".slider-range" ).each(function (){
                resetSingleSliderRange($(this));
            });
            // Remove models
            $("[name='model[]']").prop('checked', false);
            $("[name='model[]']").parents(".panel-default").find(".panel-title a").addClass("disabled");
            $(".to-remove").remove();
        });
    }

    function autoSearchOffers(trigger) {
        $(document).on('change', '#filterOffersAcc .form-check-input',function(){
            //var getBrand = $(this).attr("name");
            //console.log("TRACK CHANGE");
            $('#filtersBox').submit();
        });
        if(trigger){
            //console.log("TRACK TRIGGER");
            $('#filtersBox').submit();
        }
    }

    function autoSearchOffersTop(){
        $(document).on('change', '#allFiltersTop input[type="checkbox"]',function(e){
            e.preventDefault();
            //console.log("TRACK CHANGE TOP");

            var getTarget = $(this).val();
            var checked = $(this).is(":checked");
            var checkbox = this;
            //console.log(getTarget + ' -> ' + checked);

            $('#allFiltersTop input[type="checkbox"]').each(function(){
                if (this !== checkbox) {
                    this.checked = checkbox.checked ? false : true;
                }
                if (!checkbox.checked) {
                    $('#allFiltersTop input[type="checkbox"]').prop('checked', false);
                }
            });

            if(checked){
                if(getTarget==="pmi"){
                    //console.log("pmi");
                    $("#plp").prop('checked', false);
                }
                if(getTarget==="plp"){
                    //console.log("plp");
                    $("#pmi").prop('checked', false);
                }
                $("input[name='target_client[]'][value='" + getTarget + "']").prop('checked', false).trigger('click');
            }else{
                $("input[name='target_client[]'][value='" + getTarget + "']").prop('checked', true).trigger('click');
            }
        });
    }

    function hideTargetClientOffers(){
        $(".target_client_filter").each(function (){
            $(this).parent().css("display","none");
            //$(this).parent().remove();
        });
    }

    function filterCollapseMobileOffers() {
        $(document).on("click", ".btn-filter-collapse", function (){
            //$(".filter-collapse").collapse("toggle");
            $(".filters-top-filters").appendTo(".popFilters-top-filters");
            $(".filters-top-result").appendTo(".popFilters-top-result");
            $(".filters-offers-mobile .offers-filters").appendTo(".filter-collapse-dest");
        });
        $(document).on("click", "#popFiltersOffers .close", function (){
            //$(".filter-collapse").collapse("toggle");
            $(".filter-collapse-dest").appendTo(".filters-offers-mobile .offers-filters");
        });
        $(document).on("click", "#popFiltersOffers .btn-filter-collapse-apply", function (){
            //$(".filter-collapse").collapse("toggle");
            $(".filter-collapse-dest").appendTo(".filters-offers-mobile .offers-filters");
        });
    }

    function filterLoadTargetTopOnPageLoad() {
        let queryParams = new URLSearchParams(window.location.search);
        let target_top = "";
        if(queryParams.has('target_client')) {
            target_top = "#"+queryParams.get('target_client')+"-top";
            $(target_top).prop("checked", true);
        }
    }

    return {
        init: init,
    };

})(jQuery);
