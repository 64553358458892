var shakeit = (function () {
    function init() {
        revealShakeITService();
    }

    function revealShakeITService() {
        var target = null;
        $(".shakeit-pack a").on("click",function () {
            target = $(this).attr("href");
            revealShakeITServiceShow(target)
        });
    }

    function revealShakeITServiceShow(target) {
        $(target).tab("show");
    }

    return {
        init: init
    };
})(jQuery);
