var topHeaderManager = (function () {

    var topHeaderSmall = $(".top-header-small");

    function init()
    {
        if(topHeaderSmall.length)
        {
            topHeaderSmallInit();
        }
    }

    function topHeaderSmallInit()
    {
        ctas = topHeaderSmall.find(".ctas a");
        if(ctas.length){
            maxWidth = 0;
            ctas.each(function (e){
                if($(this).width() > maxWidth) maxWidth = $(this).width();
            })
            ctas.width(maxWidth);
        }
        
    }

    return {
        init: init
    };
})(jQuery);