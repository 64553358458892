var careersFormManager = (function(){

    function init(){
        $(document).ready( function(){

            if($(".careers-form-component").length){

                //trigger click
                $("#upload_button").on("click", function(){
                    $("#cv_file").trigger("click");
                });

                //show uploaded file name
                $('input[type="file"]').on("change", function(){
                    $("#cv").attr("placeholder", $(this).val().split('\\').pop());
                    $("#cross_icon").removeClass("invisible");
                });

                //delete uploade file
                $("#cross_icon").on("click", function(){
                    $('input[type="file"]').val('');
                    $("#cv").attr("placeholder", "CV");
                    $(this).addClass("invisible");
                });

            }else{
                return;
            }

            /*
            //trigger click
            $("#upload_button").on("click", function(){
                $("#cv_file").trigger("click");
            });

            //show uploaded file name
            $('input[type="file"]').on("change", function(){
                $("#cv").attr("placeholder", $(this).val().split('\\').pop());
                $("#cross_icon").removeClass("invisible");
            });

            //delete uploade file
            $("#cross_icon").on("click", function(){
                $('input[type="file"]').val('');
                $("#cv").attr("placeholder", "CV");
                $(this).addClass("invisible");
            });
            */
        });
    }

    return {init: init}

})(jQuery);