var regionManager = (function () {

    var container = $(".section-region");
    var contactJson = typeof regionData !== typeof undefined ? regionData : {};
    var dataContainer = $(".section-region--data", container);
    var email = $("[data-attr=email]", container);
    var phone = $("[data-attr=phone]", container);
    var title = $("[data-attr=title] span", container);

    function init() {
        $(document).ready(function () {
            $('.regionContactForm select', container).select2();

            $('.regionContactForm select', container).on("change", function () {
                var regionSelected = $(this).val();
                if (regionSelected !== '' && typeof regionSelected !== typeof undefined){
                    fillRegionData($(this).val())
                } else {
                    dataContainer.addClass('d-none');
                }
            })
        });
    }

    function fillRegionData(regionSelected) {

        var contacts = contactJson[regionSelected];
        if (typeof contacts !== typeof undefined) {
            dataContainer.removeClass('d-none');
            email.attr("href", 'mailto:'+contacts.email);
            email.html(contacts.email);
            phone.attr("href", 'tel:'+contacts.phone);
            phone.html(contacts.phone);
            //title.text(contacts.title);
        } else {
            dataContainer.addClass('d-none');
        }
    }

    return {
        init: init
    };
})(jQuery);
