var footerManager = (function () {

    function init(){
        $(document).ready(function(){
            trackFooterMenuClick();
        });
    }

    //track the footer links click
    function trackFooterMenuClick(){
        $("footer.footer a.footer-link, footer.footer-copyright a.share, footer.footer-copyright a.footer-copyright-link, footer.footer-copyright a.dropdown-item").on("click",function(event){
            var regionClicked = "footer-menu";
            var textClicked = event.currentTarget.textContent.trim() ? event.currentTarget.textContent.trim() : event.currentTarget.title.trim();
            var pathClicked = event.currentTarget.href.trim();
            if(pathClicked.includes("javascript")){
                pathClicked = " ";
            }
            manageGTM.trackMenuClick(regionClicked, textClicked, pathClicked);
        });
    }

    return {init: init};

})(jQuery);