var leasysInNumber = (function () {
    var leasysInNumberAnimated = false;
    var leasysInNumberObj = $(".leasys-in-number");
    function init() {
        if (leasysInNumberObj.length) {
            initLeasysInNumberAnimation()
        }
    }

    function initLeasysInNumberAnimation() {
        $('.count-number').data('countToOptions', {
            formatter: function (value, options) {
                return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.');
            }
        });

        var bottom_of_object = null;
        var bottom_of_window = null;
        $(window).on('scroll', function() {
            bottom_of_object = leasysInNumberObj.offset().top + leasysInNumberObj.outerHeight();
            bottom_of_window = $(window).scrollTop() + $(window).height();
            if( bottom_of_window > bottom_of_object/1.2 && !leasysInNumberAnimated){
                $('.timer', leasysInNumberObj).each(count);
                leasysInNumberAnimated = true;
            }
        });
    }
    function count(options) {
        var $this = $(this);
        options = $.extend({}, options || {}, $this.data('countToOptions') || {});
        $this.countTo(options);
    }


    return {
        init: init
    };
})(jQuery);
