var scrollToElementManage = (function () {
    function init() {

        $(document).ready(function () {
            if(getUrlParameter('scrollto') !== '') {

                var navMain = $(".nav-main").height();
                var navTop = $(".nav-top").outerHeight();
                // Store hash
                var hash = getUrlParameter('scrollto');
                setTimeout(function(){
                    var heightToRemove = isMobile() ? navMain+30 : (navMain+navTop+30);
                    $('html, body').stop().animate({
                        scrollTop: $("#"+hash).offset().top - heightToRemove
                    }, function (){
                        if ($("#"+hash).hasClass('collapse')){
                            $("#"+hash).collapse('show')
                        }
                    });
                }, 1000);
            }
            bindHomeScrollToBtn();

        });
    }

    function calcHeightToRemove() {
        var navTop = $(".nav-top");
        var navMain = $(".nav-main");
        var heightToRemove = 25;
        if (navTop.length) {
            heightToRemove += navTop.height()
        }
        if (navMain.length) {
            heightToRemove += heightToRemove+navMain.height()
        }
        return heightToRemove;
    }

    function bindHomeScrollToBtn() {
        var anchor = null;
        var target = null;
        $('[data-scroll-to]').on('click', function(e){
            anchor = $(this);
            target = $(anchor.attr('href'));
            if (typeof target.offset() === typeof undefined)
                return false;
            scrollTo(target);
            e.preventDefault();
        });
        $('[data-scroll-to-origin]').on('click', function(e){
            anchor = $(this);
            target = $(anchor.attr('href'));
            if (typeof target.offset() === typeof undefined)
                return false;
            scrollToOrigin(target);
            e.preventDefault();
        });
    }

    function scrollTo(target, delay) {
        if(typeof delay === typeof undefined) delay = 1500;
        $('html, body').stop().animate({
            scrollTop: target.offset().top - calcHeightToRemove()
        }, delay);
    }

    function scrollToOrigin(target, delay) {
        var heightToRemove = isMobile() ? 0 : $(".nav-main").height();
        if(typeof delay === typeof undefined) delay = 1500;
        $('html, body').stop().animate({
            scrollTop: target.offset().top - heightToRemove
        }, delay);
    }

    return {
        init: init,
        scrollTo: scrollTo
    };
})(jQuery);
