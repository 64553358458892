var iconsTabAreasManager = (function(){

    iconsTabAreasComponentsPlace = $("section.icons-tab-areas-component");

    function init(){
        
        $('.icons-tab-areas-component button.nav-link').on('click', function (event) {
            console.log("switched");
            if (ENV.isEditmode) {
                return;
            }

            if($(this).hasClass("active")) return; // CLICK SE STESSO

            switchIcons($(this).parent().parent().find("button.nav-link.active").first());

            var tmp = $(this).find("img").first().attr("src");

            $(this).find("img").first().attr("src",$(this).find("img").first().data("alternative-icon"));
            $(this).find("img").first().data("alternative-icon", tmp);

            $('html, body').animate({
                scrollTop: $(this).closest("section.icons-tab-areas-component").offset().top
            }, 1000);
        })

        $(document).ready(function(){
            //alignButtonWidth();
            if($(".icons-tab-areas-component").length){
                /*
                var bgColor = $(".icons-tab-areas-component").css("background-color");
                $(".icons-tab-areas-component .custom-sticky").css("background-color", bgColor);
                */

                $(".icons-tab-areas-component .custom-sticky").each(function(){
                    var bgColor = $(this).closest(".icons-tab-areas-component").css("background-color");
                    $(this).css("background-color", bgColor);
                });

                var stickyNavbarHeight = $(".sticky-top.navbar.nav-main").innerHeight();
                $(".icons-tab-areas-component .custom-sticky").css("top", stickyNavbarHeight-1);
            }
        });

        $(window).on("resize", function(){
            //alignButtonWidth();
        })

        




    }

    function alignButtonWidth(){
        iconsTabAreasComponentsPlace.each(function(){
            maxWidth = 0;
            $(this).find("li.nav-item").each(function(){
                if(maxWidth < $(this).width() ) maxWidth = $(this).width();
            })

            $(this).find("li.nav-item").each(function(){
                $(this).width(maxWidth)
                $(this).find("button.nav-link").css("width", "100%")
            })
        })
    }

    function switchIcons(element)
    {
        var tmp = element.find("img").first().attr("src");
        element.find("img").first().attr("src", element.find("img").first().data("alternative-icon"));
        element.find("img").first().data("alternative-icon", tmp);
    }

    return {
        init:init
    }
})(jQuery);