var favouriteCarManager = (function () {
    var key = `${ENV.market}.favorites`
    function init() {
        $(document).ready(function () {
            handleFavouriteClick();
            updateFavouriteViewer();
        });
    }

    function updateFavouriteViewer() {
        var navElem = $(".nav-favourite");
        var favourites = JSON.parse(localStorage.getItem(key)) || [];
        favourites.forEach(function(favourite) {
            $("[data-id='"+favourite+"']").addClass('fav');
        });
        if (favourites.length) {
            $.each(navElem, function (i, ne){
                $(ne).css("display","")
                $(ne).attr('data-favourite-badge', favourites.length);
            })

        } else {
            $.each(navElem, function (i, ne){
                $(ne).css("display","none")
            })

        }
    }

    function handleFavouriteClick() {
        $(document).on("click",".favourite", function (e) {
            var favourites = JSON.parse(localStorage.getItem(key)) || [];
            var item = $(this);
            var carID = item.attr('data-id');
            var index = favourites.indexOf(carID);
            if (!carID) return;

            if (index == -1) {
                favourites.push(carID);
                item.addClass('fav');
                $("[data-id='"+carID+"']").addClass('fav');
            } else {
                favourites.splice(index, 1);
                item.removeClass('fav');
                $("[data-id='"+carID+"']").removeClass('fav');
            }
            // store array in local storage
            localStorage.setItem(key, JSON.stringify(favourites));
            updateFavouriteViewer();
            if (ENV.isFavouritePage !== "" && ENV.isFavouritePage === "1") {
                $(".form-filter").submit();
            }
            e.preventDefault();
            window.stop()
            return false;
        });
    }

    return {
        init: init,
        updateFavouriteViewer: updateFavouriteViewer
    };
})(jQuery);
