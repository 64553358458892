var utmParametersManager = (function () {
  var utmParams = {};
  var storageUtmParametersName = 'L_UTM_PARAMETERS';
  function init() {
    initUtmParams();
  }

  function initUtmParams() {
    var sessionUtmParameters = getUtmParametersFromStorage();
    var currentUtmParameters = getUtmParametersFromUrl() !== null ? JSON.parse(getUtmParametersFromUrl()) : null;
    if (currentUtmParameters === null ) {
        return;
    }
    if (JSON.stringify(currentUtmParameters) === JSON.stringify(sessionUtmParameters)){
      return;
    }
    if(JSON.stringify(currentUtmParameters) !== JSON.stringify(sessionUtmParameters)) {
      sessionStorage.setItem(storageUtmParametersName, getUtmParametersFromUrl());
    }
  }

  function getUtmParametersFromStorage(){
    return sessionStorage.getItem(storageUtmParametersName) !== null ? JSON.parse(sessionStorage.getItem(storageUtmParametersName)) : null
  }

  function getUtmParametersFromUrl(){
    utmParams.utm_content = escapeHtml(getUrlParameter("utm_content"));
    utmParams.utm_medium = escapeHtml(getUrlParameter("utm_medium"));
    utmParams.utm_campaign = escapeHtml(getUrlParameter("utm_campaign"));
    utmParams.utm_term = escapeHtml(getUrlParameter("utm_term"));
    utmParams.utm_source = escapeHtml(getUrlParameter("utm_source"));
    if (getUrlParameter("utm_content") === '' && getUrlParameter("utm_medium") === '' && getUrlParameter("utm_campaign") === '' && getUrlParameter("utm_term") === '' && getUrlParameter("utm_source") === '') {
      return null;
    } else {
      return JSON.stringify(utmParams);
    }
  }

  function escapeHtml(text) {
    return text.replace(/[&"'<>]/g, function(c) {
      return (
          c == '&' ? '&amp;' :
              c == '"' ? '&quot;' :
                  c == "'" ? '&#039;' :
                      c == '<' ? '&lt;' : '&gt;'
      );
    });
  }

  return {
    init: init,
    getUtmParametersFromStorage: getUtmParametersFromStorage
  };
})(jQuery);
