var campaignOffersCarouselManager = (function () {
    var campaignOffersCarousel;
    var indexOffer = 0;
    var campaignOffersCarouselObj = $('.campaign-section .campaign-offers-container-place.owl-carousel');

    function init() {
        $(document).ready(function () {
            if (campaignOffersCarouselObj.length) {
                initCarousel();
            }
        });

        $( window ).resize(function () {
            if (campaignOffersCarouselObj.length) {
            }
            
        });
    }

    function setCarouselArrowsHeight(){
        var relatedBlogImg = $(".item", relatedBlogsObj).find(".single-related-blog-img img").eq(0);
        $(".related-blogs-place .owl-nav button").css({
            "top" : relatedBlogImg.height()/2
        })

    }

    function initCarousel() {
        $(".item", campaignOffersCarouselObj).each(function (k, v) {
            if ($(this).hasClass('active')){
                indexOffer = k;
            }
        });
        var campaignOffersNumber = $('.campaign-single-offer-container').length;
        campaignOffersCarousel = campaignOffersCarouselObj.owlCarousel({
            margin: 20,
            stagePadding: 15,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            loop:false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag:false,
            center: false,
            responsive: {
                0: {
                    items: 1,
                    loop: false,
                    rewind: true,
                    nav: campaignOffersNumber > 1,
                    center: false,
                    stagePadding: 15,
                    touchDrag: true
                },
                992:{
                    items: 4,
                    loop: false,
                    rewind: true,
                    nav: false,
                    center: false,
                    stagePadding: 0,
                }
            },
            onChanged: callBackChange
        })

        campaignLandingManager.alignBoxHeight();
    }

    function callBackChange(event){
        let selectedTargetValue = $('#select2-campaign_target-container');
        if(event.page.index == -1) return;
        let campaignOffers = $(".owl-item:not(.cloned, .d-none) .campaign-single-offer");
        let campaignTargetSelect = $('.main-quote-request-form .userInfo-container #campaign_target');
        let campaignOtherTargetIndex = 0;
        let isOfferB2B = $('.campaign-section .campaign-offers-container-place').data('initial-target');
        var owl = $('.owl-carousel');
        owl.owlCarousel();

        if((selectedTargetValue !== undefined && selectedTargetValue !== null && !selectedTargetValue.closest('.row').attr('hidden'))){
            if(isOfferB2B === false && campaignTargetSelect.val() !== "private"){
                campaignOtherTargetIndex = 4;
            } else if(isOfferB2B === true && campaignTargetSelect.val() !== "business"){
                campaignOtherTargetIndex = 4;
            } else {
                campaignOtherTargetIndex = 0;
            }
            if(event.item.index > 3){
                owl.trigger('to.owl.carousel', [0, 400]);
                campaignOtherTargetIndex = campaignOtherTargetIndex - 4;
            }
        }

        campaignLandingManager.setActiveContainer(campaignOffers, campaignOffers.eq(event.item.index)[0]);
        campaignLandingManager.renderActiveOffer(event.item.index + campaignOtherTargetIndex);
        campaignLandingManager.updateForm(event.item.index + campaignOtherTargetIndex);
        campaignLandingManager.changeBackground(event.item.index + campaignOtherTargetIndex);
    }

    return {
        init: init
    }
})(jQuery);