var newTabAreasManager = (function () {
    function init(){
        if (!ENV.isEditmode){
            $(document).ready(initTabs);

            function initTabs(){
                $('.tab-areas').each(function () {
                    var currentTab = $(this);
                    var navTabs = currentTab.find('.nav-buttons .nav-tabs');
                    var navItems = navTabs.find('.nav-item');
                    if (navItems.length>1 && $(window).width()>575){
                        var maxWidth = 0;
                        navItems.each(function () {
                            maxWidth = Math.max($(this).outerWidth(),maxWidth);
                        });
                        navItems.outerWidth(maxWidth);

                        if ( (maxWidth*navItems.length) > navTabs.width() ){
                            navItems.outerWidth(navTabs.width()/navItems.length);
                        }
                    }
                })
            }
        }else{
            var navTabs = $('.tab-areas .nav-buttons .nav-tabs');
            navTabs.each(function () {
                var currentNavTabLinks = $(this).find('.nav-link');
                currentNavTabLinks.on('click', function () {
                    var selectedLink = $(this);
                    currentNavTabLinks.each(function () {
                        if ($(this).attr('id')!==selectedLink.attr('id')){
                            $(this).removeClass('active');
                        }
                    })
                })
            })
        }
    }

    return {
        init: init
    };
})(jQuery);