var customizeOfferManager = (function() {
    var financialCalculatorConfig = document.getElementById('financialCalculator') !== null ? JSON.parse(document.getElementById('financialCalculator').textContent) : {};
    var defaultLeasingValue = document.getElementById('defaultLeasingValue') !== null ? JSON.parse(document.getElementById('defaultLeasingValue').textContent) : {};
    var rentArray = [];
    var milesArray = [];

    function init() {
        if (document.getElementById('financialCalculator') !== null) {
            if (Object.keys(financialCalculatorConfig.leasingLength).length && Object.keys( financialCalculatorConfig.leasingKm).length) {
                $('.customize-offer-section').removeClass('d-none');
                generateMonthSlider(financialCalculatorConfig.leasingLength);
                generateKmSlider();
                sliderChangeHandler();
            } else {
                $('.customize-offer-section').addClass('d-none');
            }

            $('#financialCalculatorCollapse').on('shown.bs.collapse', function () {
                $('html, body').stop().animate({
                    scrollTop: $('#financialCalculatorCollapse').offset().top - ($('.offer-sticky').height()+($('.nav-top').length? $('.nav-top').height() : 0)+$('.nav-main').height())
                }, 1000);
                if(isMobile()){
                    $('.customizeOffer').html($('.customizeOffer').attr('data-label-close-mobile'));
                } else {
                    $('.customizeOffer').html($('.customizeOffer').attr('data-label-close'));
                }

                if($('.additionalPackage-box--compact').length > 3){
                    $('.additionalPackage .row:nth-child(2)').addClass('overflow-auto');
                    setHeightCompactPackage();
                    $(window).on('resize', function (){
                        setHeightCompactPackage();
                    });
                }
            })
            $('#financialCalculatorCollapse').on('hidden.bs.collapse', function () {
                $('.customizeOffer').html($('.customizeOffer').attr('data-label-default'))
            });
            if($('#financialCalculatorCollapse').hasClass("show")){
                $('.customizeOffer').html($('.customizeOffer').attr('data-label-close'));
            }
        }
    }

    function setHeightCompactPackage() {
        var heightToApply = $('.additionalPackage .row:nth-child(2) .col-12 .additionalPackage-box').outerHeight()*$('.additionalPackage .row:nth-child(2) .col-12').length;
        if ($(window).width() > 1200) {
            $('.additionalPackage .row:nth-child(2)').css('height',heightToApply/2.5 )
        } else {
            $('.additionalPackage .row:nth-child(2)').css('height',(heightToApply/2)-65 )
        }
    }

    function sliderChangeHandler(){
        $(document).on('change','.customize-offer-range-slider', function (e){
            removeActiveLi();
            var percent = e.target.value/e.target.getAttribute('max');
            e.target.parentElement.style.setProperty('--percentage', `${percent*100}%`);
            if(e.target.id === 'month'){
                generateKmSlider(rentArray[e.target.value])
            }
            updatePrice();
            var activeElem = $(this).closest('.customize-offer-range').find('li')[e.target.value];
            $(activeElem).addClass('active');
        })
    }

    function removeActiveLi(){
        $('.customize-offer-range ul li').each(function (){
            $(this).removeClass('active');
        })
    }

    function updatePrice(){
        var miles = milesArray[Number(document.querySelector('.customize-offer-range-miles input').value)];
        var month = rentArray[Number(document.querySelector('.customize-offer-range-rent input').value)];

        var cardKm = $('.offer-box-terms-icons .offer-box-km span');
        var cardMonths = $('.offer-box-terms-icons .offer-box-month span');

        var notesKm = $('.custom-dynamic-notes span.notes-km');
        var notesMonths = $('.custom-dynamic-notes span.notes-months');


        var cardMonthsLabel = cardMonths.first().text().slice(cardMonths.first().text().indexOf(" ") + 1);
            
        cardKm.text(formatData('leasingKm', miles) + " " + window.ENV.measureOfUnit);
        cardMonths.text(month + " " + cardMonthsLabel);

        notesKm.text(formatData('leasingKm', miles));
        notesMonths.text(month);

        financialCalculatorConfig.leasingLength[month].forEach(function (value){
            if (value.leasingKm === miles) {
                priceManager.setCustomizationPrice(value.leasingStandardRate);
                priceManager.setPrice();
                $('[name=financial_calculator_light_leasingKm]').val(value.leasingKm);
                $('[name=financial_calculator_light_leasingLength]').val(value.leasingLength);
                $('[name=financial_calculator_light_leasingStandardRate]').val(value.leasingStandardRate);
            }
        })
    }

    function formatData(variable, value)
  {
    switch(variable){
      case "leasingKm":
        if(window.ENV.measureOfUnit == 'km'){
            if(!isNaN(value)){
                return parseInt(value).toLocaleString('it-IT')
              }
        } else {
            if(!isNaN(value)){
                return parseInt(value).toLocaleString('en-US')
              }
        }
      default:
        return value;  
    }
  }

    function generateKmSlider(changedValue) {
        milesArray = [];
        var options;

        if (typeof changedValue !== typeof undefined) {
            options = financialCalculatorConfig.leasingLength[changedValue];
        } else {
            options = financialCalculatorConfig.leasingLength[defaultLeasingValue.leasingLength];
        }
        Object.keys(options).map(function (value, index){
            milesArray.push(options[value].leasingKm)
        })
        if (document.getElementById('miles')){
            document.getElementById('miles').remove();
            document.querySelectorAll('.customize-offer-range-miles ul li').forEach(function (li){
                li.remove();
            });
        }
        var miles = document.querySelector('.customize-offer-range-miles div');
        var milesList = document.querySelector('.customize-offer-range-miles ul');
        var inputRangeMiles = document.createElement('input');

        var formatMarket = 'it-IT';
        if(window.ENV.market == 'uk') formatMarket = 'en-EN';

        milesArray.forEach(function (value){
            var li = document.createElement('li');
            li.textContent = new Intl.NumberFormat(formatMarket).format(parseFloat(value));
            li.className = 'list-inline-item';
            milesList.append(li);
        })
        inputRangeMiles.type = "range";
        inputRangeMiles.name = "miles";
        inputRangeMiles.id = "miles";
        inputRangeMiles.min = 0;
        inputRangeMiles.max = milesArray.length-1;
        inputRangeMiles.step = "1";
        inputRangeMiles.className = "customize-offer-range-slider";
        inputRangeMiles.value = milesArray.indexOf(defaultLeasingValue.leasingKm);
        miles.append(inputRangeMiles)

        var percent = inputRangeMiles.value/inputRangeMiles.getAttribute('max');
        inputRangeMiles.parentElement.style.setProperty('--percentage', `${percent*100}%`);
        milesList.querySelectorAll('li')[inputRangeMiles.value].classList.add('active')

    }

    function generateMonthSlider(leasingLength){
        if (document.getElementById('month')){
            rentArray = [];
            document.getElementById('month').remove();
            document.querySelectorAll('.customize-offer-range-rent ul li').forEach(function (li){
                li.remove();
            });
        }
        var rent = document.querySelector('.customize-offer-range-rent div');
        var rentList = document.querySelector('.customize-offer-range-rent ul');
        var inputRangeRent = document.createElement('input');
        Object.keys(leasingLength).map(function (value,index){
            rentArray.push(value);
            var li = document.createElement('li');
            li.textContent = value;
            li.className = 'list-inline-item';
            rentList.append(li);
        })


        inputRangeRent.type = "range";
        inputRangeRent.name = "month";
        inputRangeRent.id = "month";
        inputRangeRent.min = 0;
        inputRangeRent.max = rentArray.length-1;
        inputRangeRent.step = "1";
        inputRangeRent.className = "customize-offer-range-slider";
        inputRangeRent.value = rentArray.indexOf(defaultLeasingValue.leasingLength);
        rent.append(inputRangeRent)

        var percent = inputRangeRent.value/inputRangeRent.getAttribute('max');
        inputRangeRent.parentElement.style.setProperty('--percentage', `${percent*100}%`);
        rentList.querySelectorAll('li')[inputRangeRent.value].classList.add('active')

    }


    return {
        init: init
    };
})(jQuery);