var popoverOfferPackage = (function () {
    var popoverPositionTop = 0;
    var popoverId;
    var popover;
    function init() {
        if( $("#package_container").length) {
            initializePopoverOfferPackage();
        }
    }

    function calculatePopoverPosition() {
        var height = $("#"+popoverId).outerHeight();
        var top = $(".offer_popover_link:visible").position().top;
        return (top-height);
    }


    function initializePopoverOfferPackage() {
        $(document).ready(function () {
            popoverOfferPackage();
        });

        $(document).on("click","#package_container .panel-title a",function (e) {
            var container = $("#"+popoverId);
            $('#package_container .panel-collapse',container).collapse('hide');
            $('#package_container .panel').removeClass('open');
            var href = $(this).attr("data-target");
            container.find(href).collapse('show');

            container.find(href).on("shown.bs.collapse",function () {
                $(this).parent().addClass('open')
                $("#"+popoverId).css({

                });
            });
            container.find(href).on("hidden.bs.collapse",function () {
                $(this).parent().removeClass('open')
                $("#"+popoverId).css({

                });
            });
        });
    }

    function cloneHtmlPopoverOfferPackageHtml(){
        var toClone = $("#package_container");
        var tempClone = null;
        if (toClone.length) {
            tempClone = toClone.clone(true).removeClass('hide');
            $('.collapse', tempClone).removeClass('show');
            toClone.remove();
            return tempClone;
        } else {
            return null;
        }
    }

    function popoverOfferPackage() {
        var htmlContent = cloneHtmlPopoverOfferPackageHtml();
        var target = $(".offer_popover_link:visible");
        if (target.length){
            popover  = target.popover({
                html: true,
                template: '<div class="popover popover_offer_package" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                content: function () {
                    return htmlContent
                },
            }).click(function(e) {
                popoverPositionTop = target.position().top;
                popoverId = $(this).attr("aria-describedby");
                e.preventDefault();
                closeAllPanel($("#"+popoverId))
            });
        }

        $(document).on("click",".close_popover_offer", function () {
            target.trigger("click");
        })
    }

    function closeAllPanel(target) {
        if ($(".collapse", target).length){
            $(".collapse", target).collapse('hide');
        }
    }

    return {
        init: init
    };
})(jQuery);
