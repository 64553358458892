var chartsManager = (function () {
  let animateNumbers = true;

  function init() {
    if(!$(".new-charts-component").length) return;
    $(document).ready(function () {
      $(".new-charts-component button.btn.btn-primary").on("click", testButton);
      changeBackgroundImages();

      $(window).on("scroll", function () {
        if (
          animateNumbers &&
          elementIsVisibleInViewport($("span.chart-value").get(0))
        ) {
          $("span.chart-value").each(function () {
            maxValue = $(this).data("chart-max-value");
            animateValue($(this), maxValue);
          });
          animateNumbers = false;
        }
      });
    });

    $(window).on("resize", function () {
      //changeBackgroundImages();
    });

    function animateValue(el, maxValue) {
      var $valueSpan = el;
      var value = parseInt($valueSpan.text());

      $({ value: value }).animate(
        { value: maxValue },
        {
          duration: 2000,
          easing: "swing",
          step: function () {
            if (maxValue % 1 != 0) {
              $valueSpan.text((this.value).toFixed(1));
          }
          else{
              $valueSpan.text(Math.ceil(this.value).toLocaleString());
          }
          },
        }
      );
    }

    function elementIsVisibleInViewport(el, partiallyVisible = false) {
      const { top, left, bottom, right } = el.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      return partiallyVisible
        ? ((top > 0 && top < innerHeight) ||
            (bottom > 0 && bottom < innerHeight)) &&
            ((left > 0 && left < innerWidth) ||
              (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
    }
  }

  function changeBackgroundImages() {
    if ($(window).width() < 992) {
      $(".single-chart-main-container").each(function () {
        let mobileImage = $(this)
          .find(".image-container")
          .first()
          .data("mobile-background-image");
        let desktopImage = $(this)
          .find(".image-container")
          .first()
          .data("mobile-background-image");
        $(this)
          .find(".image-container")
          .first()
          .css("background-image", "url(" + mobileImage + ")");
      });
    }
  }

  function testButton() {
    clickedIndex = $("button.btn.btn-primary").index(this);
    var theClass = $(this)
      .attr("class")
      .match(/acquisitions[\w_]*\b/);
    const xLabels = $("#graph-data-" + (clickedIndex + 1)).data("graphxlabels");
    const xValues = $("#graph-data-" + (clickedIndex + 1)).data("graphxvalues");
    const xAlternativeLabels = $("#graph-data-" + (clickedIndex + 1)).data(
      "graphxalternativelabels"
    );
    const xAlternativeValues = $("#graph-data-" + (clickedIndex + 1)).data(
      "graphxalternativevalues"
    );

    const xLabel = $("#graph-data-" + (clickedIndex + 1)).data("graphxlabel");
    const xAlternativeLabel = $("#graph-data-" + (clickedIndex + 1)).data(
      "graphxalternativelabel"
    );

    let customDatasets = [];

    //FIRST DATASET:
    chartData = xLabels.map((label, index) => ({
      year: label,
      count: xValues[index],
    }));

    firstDataset = {
      label: xLabel,
      data: chartData.map((row) => row.count),
      borderColor: "#36A2EB",
      backgroundColor: "#18305B",
    };

    customDatasets.push(firstDataset);

    //SECONDARY DATA
    if (xAlternativeLabels) {
      secondaryChartData = xAlternativeLabels.map((label, index) => ({
        year: label,
        count: xAlternativeValues[index],
      }));

      secondDataset = {
        label: xAlternativeLabel,
        data: secondaryChartData.map((row) => row.count),
        borderColor: "red",
        backgroundColor: "#C20E1A",
      };

      customDatasets.push(secondDataset);
    }
    setTimeout(() => {
      Chart.register(ChartDataLabels);
      customGraph = new Chart(document.getElementById(theClass[0]), {
        type: "bar",
        data: {
          labels: chartData.map((row) => row.year),
          datasets: customDatasets,
        },
        options: {
          animation: {
            onComplete: () => {
              delayed: true;
            },
            delay: (context) => {
              let delay = 0;
              if (context.type == "data" && context.mode == "default") {
                delay = context.dataIndex * 200;
              }
              return delay;
            },
          },
          scales: {
            x: {
              title: {
                display: false,
                text: xLabel,
              },
              grid: {
                display: false,
              },
            },
            y: {
              display: false,
              ticks:{
                display: false
            },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            datalabels: {
              color: 'white',
              font: {
                weight: 'bold'
              }
            },
            legend:{
                position: 'bottom'
            }
          }
        },
      });
    }, 200);

    $("button.btn.btn-secondary").on("click", function () {
      customGraph.destroy();
    });
  }

  return {
    init: init,
  };
})(jQuery);
