var leftRightImageTextManager = (function(){
    
    var leftRightImageTextComponent = $(".left-right-image-text-component");

    function init(){
        if(leftRightImageTextComponent.length){
            leftRightImageTextComponent.each(function(){
                leftRightImageTextComponent.find(".single-left-right-image-text").each(function(){
                    setViewportImages($(this));
                });
            })
        }
        $(window).resize(function(){
            if(leftRightImageTextComponent.length){
                leftRightImageTextComponent.each(function(){
                    leftRightImageTextComponent.find(".single-left-right-image-text").each(function(){
                        setViewportImages($(this));
                    });
                })
            }
        })
    }

    function setViewportImages(singleComponent)
    {
        mobileUrl = singleComponent.find(".image-background").data("mobile-image");
        tabletUrl = singleComponent.find(".image-background").data("tablet-image");
        desktopUrl = singleComponent.find(".image-background").data("desktop-image");

        if($(window).width() >= 992 ){
            singleComponent.find(".image-background").css("background-image", 'url(' + desktopUrl + ')');
        }
        if($(window).width() > 768 && $(window).width() < 992 ){
            singleComponent.find(".image-background").css("background-image", 'url(' + tabletUrl + ')');
        }
        if($(window).width() <= 768 ){
            singleComponent.find(".image-background").css("background-image", 'url(' + mobileUrl + ')');
        }
    }
   
    return {
        init: init
    }

})(jQuery);