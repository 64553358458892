var ugo = (function () {
    function init() {
        carOnOffOnScroll();
    }

    function carOnOffOnScroll() {
        var $step2 = $(".u-go_step2");
        var $step3 = $(".u-go_step3");
        var $step4 = $(".u-go_step4");
        if ($step2.length || $step3.length || $step4.length) {
            $(window).scroll(function() {
                if ($(window).scrollTop() >= $step2.offset().top-300) {
                    $step2.find('.auto_on').show();
                    $step2.find('.auto_off').hide();
                } else {
                    $step2.find('.auto_on').hide();
                    $step2.find('.auto_off').show();
                }
                if ($(window).scrollTop() >= $step3.offset().top-300) {
                    $step3.find('.auto_on').show();
                    $step3.find('.auto_off').hide();
                } else {
                    $step3.find('.auto_on').hide();
                    $step3.find('.auto_off').show();
                }
                if ($(window).scrollTop() >= $step4.offset().top-300) {
                    $step4.find('.auto_on').show();
                    $step4.find('.auto_off').hide();
                } else {
                    $step4.find('.auto_on').hide();
                    $step4.find('.auto_off').show();
                }
            });
        }
    }

    return {
        init: init
    };
})(jQuery);
