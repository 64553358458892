
var genericTextCarouselManager = function (){
    var genericTextCarousels = $('.generic-text-carousel');

    function init() {
        $(document).ready(function () {
            if (genericTextCarousels.length && !ENV.isEditmode){
                initCarousels();
                resizeItemsAndNavigationDots();
            }
        });
    }

    function resizeItemsAndNavigationDots() {
        var currentCarouselPlace = genericTextCarousels.find('.carousel-place');
        if ($(window.width<577)){
            ['.owl-carousel.main-carousel .blocks-container .left-block .slide-tag', '.owl-carousel.main-carousel .blocks-container .left-block .slide-description'].forEach(function (elementClass) {
                resizeVerticallyElements(currentCarouselPlace.find(elementClass));
            });
        }

        var dotContents = currentCarouselPlace.find('.dot-container .dot-row .single-dot .dot-content');
        if (dotContents.length>1){
            var localmaxWidth = 0;
            dotContents.each(function () {
                localmaxWidth = ($(this).width()>localmaxWidth)? $(this).width() : localmaxWidth;
            });
            dotContents.width(localmaxWidth);
        }
    }

    function resizeVerticallyElements(elementToResize) {
        if (elementToResize.length>1){
            elementToResize.height('auto');
            var localMaxHeight = 0;
            elementToResize.each(function () {
                localMaxHeight = ($(this).height()>localMaxHeight)? $(this).height() : localMaxHeight;
            });
            elementToResize.height(localMaxHeight);
        }
    }

    function initCarousels() {
        genericTextCarousels.find('.carousel-place').each(function () {
            var currentCarouselPlace = $(this);
            var currentCarousel = $('.owl-carousel.main-carousel',currentCarouselPlace);

            if (currentCarousel.find('.item').length>1){
                var customDotsRow = $('.dot-container .dot-row',currentCarouselPlace);
                currentCarousel.owlCarousel({
                    loop: false,
                    items: 1,
                    nav: true,
                    dotsData: true,
                    navText: [
                        "<i class='fa fa-arrow-left'></i>",
                        "<i class='fa fa-arrow-right'></i>"
                    ],
                    autoplayHoverPause: false,
                    smartSpeed: 1000,
                    mouseDrag: false,
                    pullDrag: false,
                    dotsContainer: '#'+customDotsRow.attr('id'),
                });

                var dotContainer = currentCarouselPlace.find('.dot-container')
                var dotsContainerWidth = dotContainer.width();

                customDotsRow.find('.single-dot').each(function () {
                    var currentSingleDot = $(this);
                    currentSingleDot.find('.dot-content').on('click', function() {
                        currentCarousel.trigger('to.owl.carousel', [currentSingleDot.index(), 1000]);
                        if (dotsContainerWidth<customDotsRow.width()){
                            scrollButtonBar(dotContainer, dotsContainerWidth);
                        }
                    });
                });

                if (dotsContainerWidth<customDotsRow.width()){

                    currentCarousel.find('.owl-nav .owl-next').on('click', function (){
                        scrollButtonBar(dotContainer, dotsContainerWidth);
                    });

                    currentCarousel.find('.owl-nav .owl-prev').on('click', function (){
                        scrollButtonBar(dotContainer, dotsContainerWidth);
                    });
                }
            }

        })
    }

    function scrollButtonBar(dotContainer, dotsContainerWidth) {
        var elementToScroll = dotContainer.find('.dot-row .single-dot.active');
        var dotContentLeftOffset = elementToScroll.position().left;
        var dotContentWidth = elementToScroll.outerWidth();
        if ( (dotContentLeftOffset+dotContentWidth) > dotsContainerWidth ){
            dotContainer.animate({scrollLeft: dotContainer.scrollLeft()+dotContentWidth}, 1000);
        }else if ((dotContentLeftOffset) < 0){
            dotContainer.animate({scrollLeft: dotContainer.scrollLeft()-dotContentWidth}, 1000);
        }
    }

    return {init: init}
}(jQuery);