var ieDetector = (function() {
    function init() {
        var userAgent = window.navigator.userAgent;
        if(
            userAgent.indexOf("MSIE ") > -1 || // IE <= 10
            userAgent.indexOf('Trident/') > -1  || // IE 11
            userAgent.indexOf('Edge/') > -1 // Edge
        ) {
            $(document.body).addClass("ie");
        }
    }

    return {
        init: init
    };
})(jQuery);