var offersFilterManager = (function ($) {

    var $DOM = {};
    var slideSpeed = 300;
    var isAjaxCalling = false;
    var isLastFilter = true;
    var placeholders = {};

    function isTargetSelected(forceShow){
        if($('.target-client-filter.hide-target-selector').length > 0) return;
        let queryParams = new URLSearchParams(window.location.search);
        if(queryParams.has('target_client') || $("div[aria-labelledby='target_client_filter']" ).length == 0){
            $(".target-client-filter").addClass("d-none");
        }

        if(forceShow && $("div[aria-labelledby='target_client_filter']" ).length > 0){
            $(".target-client-filter").removeClass("d-none");
            $(".target-client-filter input[type='checkbox']").each(function (){
                $(this).prop("checked", false);
            })
        }
    }

    function init() {
        isTargetSelected();
        $DOM.nb_nlt_form = $(".nb-nlt-form");

        $DOM.filters = $(".offerte-filters");
        if (!$DOM.filters.length) {
            return;
        }
        $DOM.filtersForm = $DOM.filters.find(".form-filter");
        $DOM.checkboxes = $DOM.filtersForm.find("input[type=checkbox]");
        $DOM.additionalFields = $(".offerte-listing-header").find("select");
        $DOM.currentFilters = $(".currentFilters");
        $DOM.newForm = $('<form id="hidden-filter-form"/>');

        handleFilterActiveInactive();
        renderHiddenFields();
        populateFakeForm();
        handleHiddenFields();
        handleCheckboxes();
        resetFiltersHandler();
        manageAllFilterShowHideButton();
        manageNbNltFormFilter();
        handleDisabledCheckboxesOnPageLoading();
    }

    function handleDisabledCheckboxesOnPageLoading(){
        $('.panel-body', '.filter-box').each(function(i, tab){
            $.each($("input", tab), function(n, elem){
                if($(elem).is(':checked')){
                    $(elem).trigger("click");
                    $(elem).trigger("click");
                }
            });
        });
    }

    function manageNbNltFormFilter() {
        if ($DOM.nb_nlt_form.length) {
            $(".nb-nlt-search-btn", $DOM.nb_nlt_form).on("click", function (e) {
                e.preventDefault();
                var currentTab = $(this).closest(".tab-pane");
                if (currentTab.hasClass("short-term")) {
                    var value = $("select", currentTab).val();
                    if (value !== "") {
                        window.open(value, "_blank");
                    }
                } else if (currentTab.hasClass("long-term")) {
                    if ($("#hidden-filter-form input").length) {
                        $("#hidden-filter-form").submit();
                    }
                }
                return false;
            });

            container = $.find("#quick-search-form-right");
            if (container.length === 0) return;
            $(container).find("button[id$='_filter']").each(
                function () {
                    $(container).find("input[type='checkbox']").each(
                        function () {
                            $(this).change(
                                function () {

                                }
                            )
                        }
                    )
                }
            )
        }
    }

    function manageAllFilterShowHideButton() {
        $("#allFilters, #moreFilters", $DOM.filters).on("show.bs.collapse", function () {
            $(".allFilters", $DOM.filters)
                .removeClass('closed')
                .addClass('opened');
            $("[aria-controls='moreFilters']", $DOM.filters)
                .removeClass('closed')
                .addClass('opened');
        });
        $("#allFilters ,#moreFilters", $DOM.filters).on("hide.bs.collapse", function () {
            $(".allFilters", $DOM.filters)
                .removeClass('opened')
                .addClass('closed');
            $("[aria-controls='moreFilters']", $DOM.filters)
                .removeClass('opened')
                .addClass('closed')
        });
    }

    function resetFiltersHandler() {
        $(".deleteAllFilters", $DOM.currentFilters).on("click", function () {
            $("[name='model[]']").prop('checked', false);
            $("[data-dependency]").each(function () {
                if (typeof $(this).data('dependence') !== typeof undefined) {
                    disableDependentField($("[name='" + $(this).data('dependence') + "[]']"));
                }
            });
            $("#allFiltersTop input").each(function () {
                $(this).prop("checked", false);
            });
            $(".offerte-filters").find(".form-filter").find("input[type=checkbox]").each(function () {
                $(this).prop("checked", false);
                populateFakeForm();
            });
            ////$(".offers-top-filters").css("display","none");
            isTargetSelected(true);
            if($("[id='filtersBox']").data("enable-dynamic-fields")) getUpdatedFilters();
            submitForm();
        });
        $(document).on("click", ".currentFilters .filterTag", function (e) {
            var dataFilter = $(this).attr("data-filter");
            var target = $("[value='" + dataFilter + "']");
            if (target.length && target.is(':checked')) {
                if(dataFilter == 'plp' || dataFilter == 'pmi') {
                    isTargetSelected(true);
                }
                var slider = target.closest('.slider-range');
                if(typeof slider != 'undefined'){
                    resetSingleSliderRange(slider);
                }
                target.prop("checked", false);
                $(this).remove();
                if($("[id='filtersBox']").data("enable-dynamic-fields")) getUpdatedFilters();
                populateFakeForm();
                submitForm();
            }
            e.preventDefault();
        });
    }


    function handleFilterActiveInactive() {
        disableFilterBtn();
        $(".dropdown", $DOM.filtersForm).removeClass('active');
        $("input[type=checkbox]", $DOM.filtersForm).each(function () {
            if ($(this).prop("checked")) {
                var $dropdown = $(this).parents(".dropdown");
                $dropdown.addClass('active');
                enableFilterBtn();
                isLastFilter = false;
            }
        })
    }


    function renderHiddenFields() {
        $DOM.filtersForm.find('[data-additional-field]').remove();
        $DOM.additionalFields.each(function () {
            var $field = $('<input data-additional-field type="hidden" name="' + $(this).attr("name") + '" value="' + $(this).val() + '">');
            $DOM.filtersForm.append($field);
        });
        populateFakeForm();
    }

    function handleHiddenFields() {
        $DOM.additionalFields.on("change", function () {
            renderHiddenFields();
            submitForm();
        });
    }

    function submitForm() {
        $DOM.filtersForm.submit();
    }

    function populateFakeForm() {
        var sep = '|';

        $DOM.newForm.remove();
        $("body").append($DOM.newForm);

        $DOM.newForm
            .attr("method", $DOM.filtersForm.attr("method"))
            .attr("action", $DOM.filtersForm.attr("action"))
            .attr("target", $DOM.filtersForm.attr("target"));

        var newFormFields = {};
        var newFormDataLabels = {};
        $DOM.filtersForm.find("input").each(function () {
            var $srcField = $(this);
            var fieldName = $srcField.attr("name").replace("[]", "");
            var fieldDataLabel = $srcField.next(".form-check-label").text().trim();
            var fieldValue = $srcField.val();

            if (($srcField.is("[type=checkbox]") || $srcField.is("[type=radio]")) && $srcField.prop("checked") === true && fieldValue && fieldValue != '') {
                if (newFormFields[fieldName] && newFormFields[fieldName] != '') {
                    newFormFields[fieldName] += sep + fieldValue;
                    newFormDataLabels[fieldName] += sep + fieldDataLabel;
                } else {
                    newFormFields[fieldName] = fieldValue;
                    newFormDataLabels[fieldName] = fieldDataLabel;
                }
            } else if (!$srcField.is("[type=checkbox]") && !$srcField.is("[type=radio]")) {
                newFormFields[fieldName] = fieldValue;
                newFormDataLabels[fieldName] = fieldDataLabel;
            }

        });

        $DOM.newForm.find("input").remove();
        $.each(newFormFields, function (name, value) {
            $DOM.newForm.append('<input type="hidden" name="' + name + '" value="' + value + '" data-label="' + newFormDataLabels[name] + '"/>');
        });

        managerFilters();
    }

    function managerFilters() {
        var toEsclude = [];
        $DOM.additionalFields.each(function () {
            toEsclude.push($(this).attr("name"));
        });

        var toAppend = $("ul", $DOM.currentFilters);
        var toClone = $(".to-clone", $DOM.currentFilters);
        var cloned = null;
        $(".to-remove", toAppend).remove();
        $("#hidden-filter-form input").each(function (k, item) {
            var $input = $(item);
            if (jQuery.inArray($input.attr('name'), toEsclude) === -1) {
                enableFilterBtn();
                var multiValue = $input.val().split('|');
                var multiDataLabel = $input.attr('data-label').split('|');
                if (multiValue.length == 1) {
                    ////$(".offers-top-filters").css("display","block");
                    cloned = toClone.clone().removeClass('d-none to-clone').addClass('to-remove');
                    $(".filterTag", cloned).attr('data-filter', $input.val());
                    if ( $input.attr('data-label').trim() ) {
                        $(".filterTag", cloned).text($input.attr('data-label'));
                    }else{
                        $(".filterTag", cloned).text($input.val());
                    }
                    toAppend.append(cloned);
                } else {
                    $.each(multiValue, function (k, item) {
                        cloned = toClone.clone().removeClass('d-none to-clone').addClass('to-remove');
                        $(".filterTag", cloned).attr('data-filter', item);
                        $(".filterTag", cloned).text(multiDataLabel[k]);
                        toAppend.append(cloned);
                    });
                }
            } else {
                //disableFilterBtn();
                cloned = toClone.clone().removeClass('d-none to-clone').addClass('to-remove');
                $(".filterTag", cloned).addClass('d-none');
                $(".filterTag", cloned).attr('data-filter', $input.val());
                $(".filterTag", cloned).text($input.attr('name'));
                toAppend.append(cloned);
                isLastFilter = true;
            }
        });
        handleFilterActiveInactive();
    }

    function enableFilterBtn() {
        $("button[type='submit']", $DOM.filtersForm)
            .removeClass('disabled')
            .prop('disabled', false);
        showDeleteAllFilterBtn();
    }

    function disableFilterBtn() {
        $("button[type='submit']", $DOM.filtersForm)
            .addClass('disabled')
            .prop('disabled', true);
        hideDeleteAllFilterBtn();
    }

    function showDeleteAllFilterBtn() {
        $(".deleteAllFilters", $DOM.currentFilters).removeClass('d-none')
    }

    function hideDeleteAllFilterBtn() {
        $(".deleteAllFilters", $DOM.currentFilters).addClass('d-none');
    }

    function handleCheckboxes() {
        $(document).on("click", ".form-filter .dropdown-item", function (e) {
            var checkbox = $("input[type=checkbox]", this);
            var checkboxs = $(this);
            var enableDynamicFields = $("[id='filtersBox']");
            if (checkboxs.is(':checked')) {
                checkboxs.prop("checked", false);
                //checkbox.click();
            } else {
                checkboxs.prop("checked", true);
                //checkbox.click();
            }
            if (checkbox.attr("data-dependence") !== '' && typeof checkbox.attr("data-dependence") !== typeof undefined) {
                getDependencies(checkbox.attr("data-dependence"));
                if(enableDynamicFields.data("enable-dynamic-fields") != null && enableDynamicFields.data("enable-dynamic-fields"))
                    getUpdatedFilters();
            } else{
                if(enableDynamicFields.data("enable-dynamic-fields") != null && enableDynamicFields.data("enable-dynamic-fields"))
                    getUpdatedFilters();
            }
            populateFakeForm();
            if (isLastFilter) {
                //submitForm();
            }
            if ($DOM.nb_nlt_form.length) {
                $(this).closest(".dropdown-menu").removeClass("show");
                if ($(this).closest("#quick-search-form-right").length > 0) {
                    var select = $(this).closest(".dropdown");
                    placeholders[$(select).attr("id")] = placeholders[$(select).attr("id")] || $(select).find("button").text();
                    $(this).siblings().each(
                        function () {
                            $(this).find("> input[type='checkbox']").prop("checked", false);
                        }
                    );
                    var selected = $(select).find("input[type='checkbox']:checked + label");
                    if (selected.length > 0) {
                        $(select).find("button").text($(selected).text().trim());
                    } else {
                        $(select).find("button").text(placeholders[$(select).attr("id")]);
                    }
                }
            }
            e.stopPropagation();
        });
    }

    function getUpdatedFilters() {
        var isEmpty = true;
        var query = {};
        var filter = [];
        var staticFilters = ["pmi", "plp", "gapa"];
        if ($('.convenzioni').length) {
            query["contract[]"] = $('.convenzioni').val();
        }
        $("input[value]").each(function () {
            var $this = $(this);
            if ($this.is(':checked') && (!staticFilters.includes($this.attr('value')))) {
                isEmpty = false;
                filter.push($this.val());
                query[$this.attr('name').replace("[]", "")] = filter;
            }
        });

        $.ajax({
            type: "GET",
            url: ENV.filterEndpoint + '?' + $.param(query),
            dataType: "json",
            cache: false,
            /**
             * Add processData and ContentType if you have form enctype="multipart/form-data"
             */

            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType,
            success: function (response) {
                $(".form-filter-loader").addClass("d-none");
                $(".panel-title a").removeClass("disabled");
                isAjaxCalling = false;
                renderFilteredObject(response.filters);
                maintainCheckedFields(query);
            },
            error: function (response) {
                $(".form-filter-loader").addClass("d-none");
                isAjaxCalling = false;
            }
        });

    }

    function maintainCheckedFields(query){
        $.each(query, function(i, filter){
            var filterSection = $("[id='" + i + "_filter']");
            $("[value='" + filter + "']", filterSection).prop("checked", true);
        });
    }

    function renderFilteredObject(data) {
        var staticFilters = ["target_client", "emissionsRange", "priceRange", "advance", "promptDelivery", "recommended", "model", "brand", "category"];
        if (!jQuery.isEmptyObject(data)) {
            $.each(data, function (k, checkbox){
                var selector = $("[id='" + k + "_filter']");
                var filterBody = $('.panel-body', selector);
                if(!staticFilters.includes(k)){
                    var toClone = selector.find('.form-check.dropdown-item:first').clone();
                    filterBody.empty();
                    $.each(checkbox.options, function (k, option) {
                        filterBody.append(toClone.clone());

                        $("input", filterBody).last()
                            .val(option.value)
                            .attr("id", option.value);
                        $(".form-check-label", filterBody).last()
                            .text(option.label)
                            .attr("for", option.value);
                    });
                } else if(k === "brand" || k === "category"){
                    $("input", filterBody).attr("disabled", true);
                    $.each(checkbox.options, function (j, option) {
                        $(":input[value='" + option.value + "']", filterBody).attr("disabled", false);
                    });
                }
            });
            //To fix - Model field becomes active when other filter selected
            //var selector = $("[id='model_filter']");
            //var filterBody = $('.panel-body', selector);
            //var modelFilterContainer = $("[data-parent='#model_filter']");
            /*if($('.dropdown-item', filterBody).length === 1){
                if(modelFilterContainer.hasClass("collapsed")) modelFilterContainer.trigger('click');
                if(!modelFilterContainer.hasClass("disabled")) modelFilterContainer.addClass('disabled');
            }*/
        }
    }

    function getDependencies(dependence) {
        var isEmpty = true;
        var query = {};
        var filter = [];
        if ($('.convenzioni').length) {
            query["contract[]"] = $('.convenzioni').val();
        }
        $("input[data-dependency]").each(function () {
            var $this = $(this);
            if ($this.is(':checked')) {
                isEmpty = false;
                filter.push($this.val());
            }
            query[$this.attr('name').replace("[]", "")] = filter;
        });

        if (isAjaxCalling || isEmpty) {
            var dependence = $("[name='" + dependence + "[]']");
            disableDependentField(dependence);
            $(".to-remove").remove();
            $(dependence).parents(".panel-default").find(".panel-title a").addClass("disabled");
            return;
        } else {
            isAjaxCalling = true;
            $(".form-filter-loader").removeClass("d-none")
            $.ajax({
                type: "GET",
                url: ENV.filterEndpoint + '?' + $.param(query),
                dataType: "json",
                cache: false,
                /**
                 * Add processData and ContentType if you have form enctype="multipart/form-data"
                 */
                processData: false,  // tell jQuery not to process the data
                contentType: false,  // tell jQuery not to set contentType,
                success: function (response) {
                    $(".form-filter-loader").addClass("d-none");
                    $(".panel-title a").removeClass("disabled");
                    isAjaxCalling = false;
                    renderDependencyObject(response.filters[dependence], dependence)

                },
                error: function (response) {
                    $(".form-filter-loader").addClass("d-none");
                    isAjaxCalling = false;
                }
            });
        }
    }

    function renderDependencyObject(data, dependence) {
        if (!jQuery.isEmptyObject(data)) {
            var selector = $("[name='" + data.name + "[]']");
            //var toAppend = selector.closest('.dropdown-menu');
            var toAppend = selector.closest('.panel-body');
            var toRemove = $(".to-remove", toAppend);

            if (!jQuery.isEmptyObject(data.options)) {
                toRemove.remove();
                $.each(data.options, function (k, checkbox) {
                    var toClone = selector.closest('.dropdown-item.to-clone').clone().removeClass('d-none to-clone').addClass('to-remove');
                    $("input", toClone)
                        .val(checkbox.value)
                        .attr("id", checkbox.value);
                    $(".form-check-label", toClone)
                        .text(checkbox.label)
                        .attr("for", checkbox.value);
                    toAppend.append(toClone);
                });
                enableDependentField(selector);
            } else {
                disableDependentField(selector);
            }
        }

        if ($("#hidden-filter-form [name='" + dependence + "']").length) {
            var getVal = $("#hidden-filter-form [name='" + dependence + "']").val().split('|');
            if (getVal.length == 1) {
                $("#" + getVal).prop("checked", true);
            } else {
                $.each(getVal, function (k, item) {
                    $("#" + getVal[k]).prop("checked", true);
                });
            }
        }
    }

    function disableDependentField(selector) {
        var toDisable = selector.closest('.dropdown').find('.dropdown-toggle');
        toDisable.addClass('disabled');
        var toAppend = selector.closest('.dropdown-menu');
        var toRemove = $(".to-remove", toAppend);
        toRemove.remove();
    }

    function enableDependentField(selector) {
        var toDisable = selector.closest('.dropdown').find('.dropdown-toggle');
        toDisable.removeClass('disabled');
    }

    return {
        init: init,
        populateFakeForm: populateFakeForm,
        submitForm: submitForm
    };

})(jQuery);
