var advantagesCarouselManager = (function () {
    var mainCarouselObj = null;
    var carouselObj = $('.advantages-carousel.owl-carousel');
    function init() {
        $(document).ready(function () {
            if (carouselObj.length) {
                initMainCarousel();
            }
        })
        $( window ).resize(function () {
            if (carouselObj.length) {
                initMainCarousel();
            }
        });
    }

    function initMainCarousel() {

        if ($(window).width() <= 992) {
            mainCarouselObj = carouselObj.owlCarousel({
                nav: false,
                dots: true,
                center: true,
                loop: false,
                items: 1
            });
        } else if (mainCarouselObj !== null){
            mainCarouselObj.trigger('destroy.owl.carousel');
        }
    }

    return {
        init: init
    };
})(jQuery);
