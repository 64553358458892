var additionalPackageManager = (function () {
  var additionalPackage = $(".additionalPackage");
  var originalPrice = parseFloat($("[data-original-price]").attr('data-original-price'));
  var packageInfoPopover;
  var additionalServicesInputForm = document.createElement("INPUT");
  function init() {
    $(document).ready(function () {
      initAdditionalPackagePopover();
      handleAdditionalPackageSelection();



    });
  }



  function initAdditionalPackagePopover() {
    $(document).on('click','.additionalPackage-popover', function(event) {
      packageInfoPopover = $(this).popover({
        placement:'top',
        trigger:'manual',
        html:true,
        title: function() {
          var title = $(this).attr('data-popover-title');
          return `<div>${title}</div><div class="close-icon-blue closePopover"></div>`;
        },
        template: '<div class="popover custom-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
      });
      $(this).popover('show');
    });

    $(document).on('click','.closePopover', function (e) {
      $('.info-icon-blue[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        $(this).popover('hide');
      });
    });
    $('body').on('click', function (e) {
      $('.info-icon-blue[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          $(this).popover('hide');
        }
      });
    });
  }

  function resetPopover(){
    $.each(packageInfoPopover, function (){
      $(this).popover('hide')
    });
  }

  function getDeferredPrice(price, defferedMonths){
    leasingMonths = parseFloat($("input#leasing_months-field").val());
    deferredPrice = ((price*leasingMonths) - (defferedMonths*price))/(leasingMonths-1);
    return Math.ceil(deferredPrice);
  }

  function getDeferredAdvance(amount, months){
    return amount * months;
  }

  function updatePrice(){

    var totalAdditionalPackagePrice = 0;
    var totalAdditionalPackageAdvance = 0;
    var ids = [];
    $.each($(".form-check input:checked", additionalPackage), function (){
      packageValue = parseFloat($(this).val())
      deferredMonths = $(this).data("months");
      if(deferredMonths > 0){
        deferredAdvance = getDeferredAdvance(packageValue,deferredMonths);
        totalAdditionalPackageAdvance += parseFloat(deferredAdvance);
        deferredPrice = getDeferredPrice(parseFloat($(this).val()), deferredMonths);
        totalAdditionalPackagePrice += deferredPrice;
      } else {
        totalAdditionalPackagePrice += parseFloat($(this).val());
      }
      //totalAdditionalPackagePrice += parseFloat($(this).val());
      ids.push($(this).attr('id'));
    });
    priceManager.setAdditionalPackageAdvance(totalAdditionalPackageAdvance);
    priceManager.setAdditionalPackagePrice(totalAdditionalPackagePrice);
    priceManager.setPrice();
    if (ids.length){
      document.querySelectorAll('.additional_services_input').forEach(function (asi){
        asi.remove();
      })
      additionalServicesInputForm.setAttribute("type", "hidden");
      additionalServicesInputForm.setAttribute("name", "additional_services");
      additionalServicesInputForm.setAttribute("id", "additional_services_input");
      additionalServicesInputForm.setAttribute("value", ids.join(','));
      $('.form-preventivo').append(additionalServicesInputForm);
    } else {
      document.querySelectorAll('.additional_services_input').forEach(function (asi){
        asi.remove();
      })
    }
  }

  function handleAdditionalPackageSelection() {
    $(".form-check", additionalPackage).on('click', function (e){
      var input = $(e.currentTarget);
      resetPopover();
      if ($(input).find('input').is(':checked')){
        $(input).closest('.additionalPackage-box').addClass('box-checked');
        $.each($(`[data-group="${$(input).find('input').attr('data-group')}"]`), function (){
          if($(this).parent('.form-check').not(input).length){
            $(this).closest('.additionalPackage-box').addClass('box-off')
          }
        });
      } else {
        $(input).closest('.additionalPackage-box').removeClass('box-checked');
        $.each($(`[data-group="${$(input).find('input').attr('data-group')}"]`), function (){
          if($(this).parent('.form-check').not(input).length){
            $(this).closest('.additionalPackage-box').removeClass('box-off')
          }
        })
      }
      updatePrice();
    });
  }


  return {
    init: init
  };
})(jQuery);
