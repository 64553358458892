var formContatti = (function () {

	function init() {
		var $formContatti = $('.form-contatti');
		
		if($formContatti.length){

			$('.form-contatti select').select2({
				allowClear: true,
			});

			$(".custom-file-upload-btn").on("click", function(e) {
				$(".original-file-input").trigger("click");
				e.preventDefault();
			});
			$(".original-file-input").on("change", function() {
				var fileName = $(this).val().split("\\").pop();
				fileName = fileName.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
				$(".custom-file-upload-label").html(fileName || "Nessun file selezionato");
			});

			$formContatti.on("submit", function(e) {
				if(validateForm($formContatti)) {
					$(this).submit();
				}
				e.preventDefault();
				return false;
			});

			//trigger click
			$("#upload_button").on("click", function(){
				$("#attachment_asset").trigger("click");
			});

			//show uploaded file name
			$('input[type="file"]').on("change", function(){
				$("#file_text").attr("placeholder", $(this).val().split('\\').pop());
				$("#cross_icon").removeClass("invisible");
			});

			//delete uploade file
			$("#cross_icon").on("click", function(){
				$('input[type="file"]').val('');
				$("#file_text").attr("placeholder", "");
				$(this).addClass("invisible");
			});
		}
	}

	function validateForm(form) {
		var isValid = true;
		$("input.form-control, select.form-control", form).each(function () {
			if($(this).attr("data-validate-required") === "false") return;
			var currentIsValid = (
				($(this).attr("type") === "email" && isValidEmail($(this).val())) ||
				($(this).attr("type") === "text" && $(this).val() !== '') ||
				($(this).attr("type") === "number" && $(this).val() !== '') ||
				($(this).is("select") && $(this).val() !== '')
			);
			isValid = isValid && currentIsValid;
			if(!currentIsValid) {
				$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		});

		return isValid;
	}

	return {
		init: init
	};
})(jQuery);
