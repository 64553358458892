var imageTextListCardsCarouselManager = (function () {
    var imageTextCardsCarousel;
    var indexCard = 0;
    var imageTextCardsCarouselObj = $(".image-text-list-cards-component .owl-carousel");
    var imageTextCards = $(".image-text-list-cards-component");

    function init(){
        $(document).ready(function (){
            if(imageTextCardsCarouselObj.length){
                initCarousel();
            }
            if(imageTextCards.length){
                resizeCardsHeight();
            }
        })
        $(window).resize(function(){
            if(imageTextCards.length){
                resizeCardsHeight();
            }
        })

        $(window).on('shown.bs.tab', function (e) {
            var prevComponent = imageTextCards;
            imageTextCards = $('.tab-pane.active.show .image-text-list-cards-component', e.target.closest('section'));
            if (imageTextCards.length>0){
                var prevCarousel = imageTextCardsCarouselObj;
                imageTextCardsCarouselObj = imageTextCards.find('.owl-carousel');
                if(imageTextCardsCarouselObj.length){
                    initCarousel();
                }
                if(imageTextCards.length){
                    resizeCardsHeight();
                }
                imageTextCardsCarouselObj = prevCarousel;
            }
            imageTextCards = prevComponent;
        })
    }

    function resizeCardsHeight(){
        imageTextCardsCarouselObj.each(function(){
            resizeSingleCards($(this).find('.single-card-container').toArray());
        })
        if ($(window).width()>768){
            imageTextCards.find('.cards-list').each(function () {
                var cardsList = $(this);
                var itemsPerRow = cardsList.data('items-per-row-desktop');
                if ($(window).width()<992)
                    itemsPerRow = cardsList.data('items-per-row-tablet');
                var singleCards = cardsList.find('.single-card-container');
                var elementsPushed = 0;
                var cardsToAlign = [];
                for (i=0; i<singleCards.length; i++){
                    cardsToAlign.push(singleCards[i]);
                    elementsPushed++;
                    if (elementsPushed===itemsPerRow || (i===singleCards.length-1 && elementsPushed>1)){
                        resizeSingleCards(cardsToAlign);
                        elementsPushed = 0;
                        cardsToAlign = [];
                    }
                }
            })
        }
    }

    function resizeSingleCards(singleCards) {
        var elementsToResize = ['.card-text-container', '.card-cta-container', '.card-bottom-part']
        if(singleCards.length){
            elementsToResize.forEach((className)=>{
                var singleElementMaxHeight = 0;
                $(singleCards).find(className).each(function () {
                    var currentElement = $(this);
                    currentElement.height('auto');
                    singleElementMaxHeight = Math.max(singleElementMaxHeight, currentElement.height())
                });

                $(singleCards).find(className).height(singleElementMaxHeight);
            })
        }
    }

    function initCarousel(){
        $(".item", imageTextCardsCarouselObj).each( function (k,v){
            if ($(this).hasClass('active')){
                indexCard = k;
            }
        });

        imageTextCardsCarouselObj.each(function () {
            var currentCarousel = $(this);
            var imageTextCardsCount = currentCarousel.find('.single-card').length;
            var ItemPerRowDesktop = currentCarousel.closest(".cards-place").data("items-per-row-desktop");
            var ItemPerRowTablet = currentCarousel.closest(".cards-place").data("items-per-row-tablet");
            imageTextCardsCarousel = currentCarousel.owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: false,
                center: imageTextCardsCount == 1,
                loop: imageTextCardsCount > 2 && !ENV.isEditmode,
                responsive:{
                    0:{
                        mouseDrag: imageTextCardsCount > 1 && !ENV.isEditmode,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: false,
                        center: false,
                        loop: imageTextCardsCount > 1 && !ENV.isEditmode,
                        nav: false,
                        stagePadding: 25,
                    },
                    768: {
                        items: imageTextCardsCount > 1 ? ItemPerRowTablet : 1,
                        loop: imageTextCardsCount > 1 && !ENV.isEditmode,
                        nav:imageTextCardsCount > 1,
                        center: false,
                    },
                    992: {
                        loop: imageTextCardsCount > 2 && !ENV.isEditmode,
                        mouseDrag: imageTextCardsCount > 2,
                        items: imageTextCardsCount > ItemPerRowDesktop ? ItemPerRowDesktop : (imageTextCardsCount),
                        nav:imageTextCardsCount > 1
                    }
                }
            });
        });
    }

    return {
        init: init
    }
})(jQuery);