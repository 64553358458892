var pressAreaManager = ( function (){

    var $DOM = {};
    var pressFilters = {
        "country" : "all",
        "date": "all",
        "keyword": ""
    };

    var currentPage = 0;
    var totalPages = 1;
    var isLastPage = true;
    var availableFilters = ["page", "country","date"];
    var noResultsPlace = $(".new-press-area .press-no-results");
    var loadMoreContainer = $(".press-listing-loadmore-container");
    var navigationPlace = $(".press-navigation-place");
    var totalResultsPlace = $("span.results-counter")
    var localReferencePlace = $(".local-ufficio-stampa-place");

    function init()
    {
        $DOM.wrapper = $(".new-press-area");

        $DOM.filtersForm = $(".blog-filters").find("form");
        if (!$DOM.wrapper.length) {
            return;
        }
        //IF NEW PRESS AREA PAGE:
        initSelects();

        $DOM.filters = $(".new-press-area .filters-place");
        $DOM.container = $DOM.wrapper.find("main");
        $DOM.pressPlace = $DOM.wrapper.find(".press-area-cards-place");

        //updateFiltersWithUrl();

        getPressReleases(true);

        $(document).on("click","span.navigation-botton:not('.deactivated')", function(e){
            if($(this).hasClass("next")) currentPage++;
            if($(this).hasClass("prev")) currentPage--;
            getPressReleases();
        })

        $(document).on("click",".press-actions img", function(e){
          if ($(this).siblings()[0].href) {
              navigator.clipboard.writeText($(this).siblings()[0].href);
              $('.toast').toast({"delay": 1500})
              $('.toast').toast('show')
          }
          
      })

        $(".new-press-area .press-area-search input").on("keypress", function(e){
            if(e.which === 13){
                if(pressFilters.keyword == $(".new-press-area .press-area-search input").val()) return;
                pressFilters.keyword = $(".new-press-area .press-area-search input").val();
                currentPage = 0;

                getPressReleases();
            }
            
        })
        


    }

    function updateFiltersWithUrl() {
        urlParams = new URLSearchParams(window.location.search);
        availableFilters.forEach((filter) => {
          if (filter != "page") {
            if (urlParams.get(filter)) {
              filterContainer = $(".blog-filters[data-filter*=" + filter + "]");
    
              urlParams
                .get(filter)
                .split("|")
                .forEach((filterOption) => {
                  filterContainer
                    .find(
                      ".blog-detail-single-tag[data-filter-option-value*=" +
                        filterOption +
                        "]"
                    )
                    .addClass("selected");
                });
    
              blogFilters[filter] = urlParams.get(filter).split("|");
            }
          } else {
            return;
            if (urlParams.get(filter)) {
              currentPage = urlParams.get(filter) - 1;
            } else {
              currentPage = 0;
            }
          }
        });
      }

    function initSelects()
    {
        $('.new-press-area select.select2').select2({
            templateResult: formatState,
            templateSelection: formatState
        });

        $('.new-press-area select.select2').on("change", function(e){
            updateSelectedFilters();
        })


    }

    function updateSelectedFilters()
    {
        pressFilters.country = $("select.markets-filter").val();
        pressFilters.date = $("select.dateRange-filter").val();
        currentPage = 0;

        getPressReleases();
    }


    function getPressReleases(disabledScroll = false)
    {
        //updateUrlWithFilter(pressFilters); 
        var url = $DOM.container.data("load-more-url");
        noResultsPlace.addClass("d-none");

        var mainFilters = {
            filters: JSON.stringify(pressFilters),
            return: "json",
            page: currentPage + 1,
        };

        //GET PRESS
        var data = {
            filters: mainFilters,
        };

        $.ajax({
            method: "GET",
            url: url,
            data: data.filters,
            dataType: "json",
          }).done(function (ret) {
            totalPages = ret.data.totalPages;
            totalResults = ret.data.totalItems;

            renderePresses(ret.data.items);
            renderLocalReference(ret.data.localReference);
            updateResults(totalPages,totalResults);
            if (!disabledScroll) {
              $('html, body').animate({
                  scrollTop: $("span.results-counter").offset().top+(-150)
              }, 1000);
          }
            //setTotalItems(ret.data.totalItems, ret.data.items);
          });

        
    }

    function renderLocalReference(localRef)
    {
        localReferencePlace.empty();

        if(pressFilters.country != 'all' && localRef.length > 0 )
        {
            var localReferenceTemplate;

            localReferenceTemplate = TPL.localRefPress;
            localRef.forEach((localPerson,index) => {
              var tplData = {
                item: localPerson,
                ENV: window.ENV,
                showCountryLabel: index == 0
              };
              var htmlLocalRef = Mustache.render(localReferenceTemplate, tplData);
              localReferencePlace.append(htmlLocalRef);
              localReferencePlace.removeClass("d-none")
            });
            
        }else{
            localReferencePlace.addClass("d-none");
            
        }
    }

    function renderePresses(presses)
    {
        $DOM.pressPlace.empty();

        var pressTemplate;

        pressTemplate = TPL.press_listing;

        if(presses.length > 0){
            navigationPlace.removeClass("d-none")
            $.each(presses, function (i, press) {
                var tplData = {
                  item: press,
                  ENV: window.ENV,
                };
                var html = Mustache.render(pressTemplate, tplData);
                $DOM.pressPlace.append(html);
              });
        }else{
            currentPage = 0;
        }
    }

    function handlePagination()
    {
        
    }

    function updateResults(pagesN, resultsN)
    {
        totalResultsPlace.text(resultsN);
        navigationPlace.find("span.current-page").text(currentPage+1);
        navigationPlace.find("span.total-pages").text(pagesN);

        if(currentPage+1 < pagesN){
            navigationPlace.find(".navigation-botton.next").removeClass("deactivated")
        }else{
            navigationPlace.find(".navigation-botton.next").addClass("deactivated")
        }
        if(currentPage+1 == 1){
            navigationPlace.find(".navigation-botton.prev").addClass("deactivated")
        }else{
            navigationPlace.find(".navigation-botton.prev").removeClass("deactivated")
        }

        if(pagesN == 0){
            navigationPlace.addClass("d-none");
            noResultsPlace.removeClass("d-none");
        } else{
            navigationPlace.removeClass("d-none");
        }

    }

    function updateUrlWithFilter(filters) {

        if(!Object.keys(filters).length){
          history.pushState(
              null,
              document.title,
              window.location.pathname
            );
            return;
      } 
      urlFilters = {};
        filtersKeys = Object.keys(filters);
        filtersKeys.forEach((key) => {
          urlFilters[key] = filters[key].join("|");
        });
        history.pushState(
          null,
          document.title,
          window.location.pathname + "?" + $.param(urlFilters)
        );
      }

    function formatState (opt) {

        var optimage = $(opt.element).attr('data-image'); 
        if(!optimage){
           return opt.text.toString();
        } else {                    
            var $opt = $(
               '<span><img style="vertical-align:sub; margin-right:8px;" src="' + optimage + '" height="17px" width="auto" /> ' + opt.text.toString() + '</span>'
            );
            return $opt;
        }
    };

    return {
        init: init
    }
})(jQuery)