var wallpaperCardsManager = (function (){

    function init(){
        $(document).ready(function (){
            if(!$("section.wallpaper-cards-component").length)
            {
                return
            }

            $("section.wallpaper-cards-component").each(function(){
                cardsTextContainers = $(this).find(".single-card-container .text-container");                
                cardsCtaContainers = $(this).find(".single-card-container .cta-container");                

                //OWL CAROUSEL
                carouselObject = $(this).find(".owl-carousel");
                cardCarousel = carouselObject.owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: false,
                center: false,
                loop: true,
                responsive:{
                    0:{
                        mouseDrag: true && !ENV.isEditmode,
                        items: 1,
                        singleItem: false,
                        dots: false,
                        center: false,
                        loop: false && !ENV.isEditmode,
                        nav: false,
                        stagePadding: 50,
                        margin: 15,
                    },
                    992: {
                        loop: false && !ENV.isEditmode,
                        mouseDrag: true,
                        items: 4,
                        margin: 30,
                        stagePadding: 0,
                        nav:false,
                    }
                }
            });

            if (cardsTextContainers.length > 1) {
                    maxTextContainerHeight = 0;
                    cardsTextContainers.each(function(){
                        if($(this).height() > maxTextContainerHeight){
                            maxTextContainerHeight = $(this).height()
                        }
                    });
                    cardsTextContainers.height(maxTextContainerHeight);
                    //cardsTextContainers.height()
                }
                if (cardsCtaContainers.length > 0) {
                    maxCtaContainerHeight = 0;
                    cardsCtaContainers.each(function(){
                        if($(this).height() > maxCtaContainerHeight){
                            maxCtaContainerHeight = $(this).height()
                        }
                    });
                    cardsCtaContainers.height(maxCtaContainerHeight);
                    //cardsTextContainers.height()
                }
            })


        
        })
    }


    return {
        init: init
    }
})(jQuery);