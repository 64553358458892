var mediaAreaManager = (function () {
  var $DOM = {};
  var mediaFilters = {
    country: "all",
    date: "all",
    keyword: "",
    type: "all",
    page: 1,
  };

  var currentPage = 0;
  var totalPages = 1;
  var isLastPage = true;
  var availableFilters = ["page", "country", "date"];
  var noResultsPlace = $(".new-media-area .press-no-results");
  var loadMoreContainer = $(".press-listing-loadmore-container");
  var navigationPlace = $(".press-navigation-place");
  var totalResultsPlace = $("span.results-counter");
  var localReferencePlace = $(".local-ufficio-stampa-place");

  function init() {
    $DOM.wrapper = $(".new-media-area");

    $DOM.filtersForm = $(".blog-filters").find("form");
    if (!$DOM.wrapper.length) {
      return;
    }
    //IF NEW PRESS AREA PAGE:
    initSelects();

    initCarousel();

    $DOM.filters = $(".new-media-area .filters-place");
    $DOM.container = $DOM.wrapper.find("main");
    $DOM.pressPlace = $DOM.wrapper.find(".media-area-cards-place");

    //updateFiltersWithUrl();

    //getMediaItems(true);

    $(document).on(
      "click",
      "span.navigation-botton:not('.deactivated')",
      function (e) {
        if ($(this).hasClass("next")) currentPage++;
        if ($(this).hasClass("prev")) currentPage--;
        //getMediaItems();
      }
    );

    $(document).on(
      "click",
      ".new-media-area .single-media-card .image-container",
      function (e) {
        $(this).parent().parent().find(".modal").modal("show");
      }
    );

    $(document).on("click", ".media-actions img", function (e) {
      if ($(this).siblings()[0].href) {
        navigator.clipboard.writeText($(this).siblings()[0].href);
        $(".toast").toast({ delay: 1500 });
        $(".toast").toast("show");
      }
    });

    $(".new-media-area .media-area-search input").on("keypress", function (e) {
      if (e.which === 13) {
        if (
          mediaFilters.keyword ==
          $(".new-media-area .media-area-search input").val()
        )
          return;
        mediaFilters.keyword = $(
          ".new-media-area .media-area-search input"
        ).val();
        currentPage = 0;
        updateUrlWithFilters();

        //getMediaItems();
      }
    });



    updateFiltersWithUrl();
  }

  function updateFiltersWithUrl() {
    urlParams = new URLSearchParams(window.location.search);
    Object.keys(mediaFilters).forEach((singleFilter) => {
      if (urlParams.get(singleFilter)) {
        mediaFilters[singleFilter] = urlParams.get(singleFilter);
      } else {
        if (singleFilter == "page") mediaFilters[singleFilter] = 1;
        if (singleFilter == "keyword") mediaFilters[singleFilter] = "";
      }
    });

    updateSelectedValues();
    return;
  }

  function updateSelectedValues()
  {
    mediaFilters.country = $("select.markets-filter").val();
    mediaFilters.date = $("select.dateRange-filter").val();
    mediaFilters.type = $("select.itemtype-filter").val();
  }

  function initSelects() {
    $(".new-media-area select.select2").select2({
      templateResult: formatState,
      templateSelection: formatState,
    });

    $(".new-media-area select.select2").on("change", function (e) {
      updateSelectedFilters();
    });
  }

  function initCarousel() {
    $(".new-media-area .carousel").carousel({
      interval: false,
    });

    $(".new-media-area .carousel").on("slid.bs.carousel", function (e) {
      $(e.currentTarget)
        .find("span.current-navigation-page")
        .text(e.to + 1);
      updateMediaActions(e);
    });
  }

  function updateMediaActions(e) {
    currentModal = $(e.currentTarget).closest(
      ".carousel.slide.car-gallery-carousel"
    );

    currentOpenImageSrc = currentModal
      .find(".carousel-item.active img")
      .attr("src");
    currentOpenVideoSrc = currentModal
      .find(".carousel-item.active video source")
      .attr("src");

    currentOpenImageAlt = currentModal
      .find(".carousel-item.active img")
      .attr("alt");
    currentOpenVideoAlt = currentModal
      .find(".carousel-item.active video source")
      .data("title");

    if (currentOpenImageSrc) {
      currentModal
        .find(".media-actions a")
        .first()
        .attr("href", currentOpenImageSrc);
      currentModal
        .find(".media-actions a")
        .first()
        .attr("download", currentOpenImageAlt);
    }
    if (currentOpenVideoSrc) {
      currentModal
        .find(".media-actions a")
        .first()
        .attr("href", currentOpenVideoSrc);
      currentModal
        .find(".media-actions a")
        .first()
        .attr("download", currentOpenVideoAlt);
    }
  }

  function updateSelectedFilters() {
    mediaFilters.country = $("select.markets-filter").val();
    mediaFilters.date = $("select.dateRange-filter").val();
    mediaFilters.type = $("select.itemtype-filter").val();
    mediaFilters.page = 1;

    updateUrlWithFilters();
  }

  function updateUrlWithFilters(toReload = true) {
    var url = new URL(window.location.href);
    Object.keys(mediaFilters).forEach((key) => {
      url.searchParams.set(key, mediaFilters[key]);
    });
    history.replaceState(null, null, url);
    if(!toReload) return;
    window.location.href = url;
  }

  function formatState(opt) {
    var optimage = $(opt.element).attr("data-image");
    if (!optimage) {
      return opt.text.toString();
    } else {
      var $opt = $(
        '<span><img style="vertical-align:sub; margin-right:8px;" src="' +
          optimage +
          '" height="17px" width="auto" /> ' +
          opt.text.toString() +
          "</span>"
      );
      return $opt;
    }
  }

  return {
    init: init,
  };
})(jQuery);
