var newSliderSmallGamification = (function(){

    function init(){
        $(document).ready( function(){

            if($(".new-slider-small-gamification").length){

                $(".gamification-answer, .next-slide").on("click", function(){
                    var dataBool = $(this).attr("data-bool");
                    var dataAnswer = $(this).attr("data-answer");
                    var dataCorrectLabel = $(this).attr("data-correct-label");
                    var dataWrongLabel = $(this).attr("data-wrong-label");

                    var owl = $(this).closest(".new-slider-small-component .owl-carousel");
                    owl.trigger('next.owl.carousel');

                    if(dataBool == dataAnswer){
                        $(".owl-item.active .answer-label #correct-img").removeClass("d-none");
                        $(".owl-item.active .answer-label h3").html(dataCorrectLabel);
                    }else{
                        $(".owl-item.active .answer-label #wrong-img").removeClass("d-none");
                        $(".owl-item.active .answer-label h3").html(dataWrongLabel);
                    }
                });

            }else{
                return;
            }
        });
    }

    return {init: init};

})(jQuery);