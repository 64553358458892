var leasysInEuropeManager = (function () {
    var container;

    function init() {
        container = $(".leasys-in-europe > .leasys-in-europe-box-container")
        if(container.length === 0) return;
        container.each(function() {
            translateBox(this);
        });

        window.addEventListener("resize", function() {
            container.each(function() {
                translateBox(this);
            });
        })
    }

    function translateBox(instance) {
        var singleContainer = $(instance).find(".container");
        if(singleContainer.length === 0) return;
        var maxHeight = 0;

        $(instance).find("picture > img").each(function () {
            maxHeight = Math.max($(this).height(), maxHeight);
        });

        var delta = Math.floor(maxHeight / 2);
        var containerHeight = $(singleContainer).height();
        delta = delta < containerHeight ? -delta : -Math.floor(containerHeight / 2);
        $(instance).find(".container").css("margin", delta + "px auto 0");
    }

    return {
        init: init
    };
})(jQuery);