var leasysMapCarousel = (function () {
    var leasysInEuropeObj = $(".leasys-in-europe");
    var leasyMapCarousel = $('.leasys-map-carousel .owl-carousel', leasysInEuropeObj);
    var mainLeasysMapCarousel;
    function init() {
        $(document).ready(function () {
            if (leasysInEuropeObj.length) {
                initLeasysMapCarousel();
                // updateContent();
            }
        });
    }

    function initLeasysMapCarousel() {
        mainLeasysMapCarousel = leasyMapCarousel.owlCarousel({
            dots: false,
            margin: 20,
            nav: true,
            loop: true,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            responsive:{
                0: {
                    singleItem: true,
                    center: true,
                    items: 1,
                },
                767: {
                  items: 3
                },
                1200:{
                    items: 7
                }
            }
        });
    }

    function updateContent() {

        var contentContainer = $(".leasys-in-europe-box-container", leasysInEuropeObj);
        $(".item a",leasyMapCarousel).on("click", function (e) {
            var link = $(this).attr("href");
            var title = $(this).attr("title");
            var description = $(this).attr("data-description");
            $("figcaption h3", contentContainer).text(title);
            $("figcaption p", contentContainer).text(description);
            $("a", contentContainer).attr('href',link);
            e.stopPropagation();
            e.preventDefault();
           return false;
        });
    }

    return {
        init: init
    };
})(jQuery);
