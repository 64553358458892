var formPreventivo = (function () {
	var isOverlay = false;
	var body = $("body");
	var cittaList = [];
	var provinciaList = [];
	var capList = [];
	function init() {
		$(document).ready(function () {
            scrollIfHasError();
        });
		var $formPreventivo = $('.form-preventivo');
		
		if($formPreventivo.length){
			var $btnSubmit = $('.form-preventivo button.main-submit-btn');
			var $formOverlay = $(".form-overlay");
			var $closeForm = $('.form-overlay .close');
			var $launchQuoteRequestOverlay = $('.launch-quote-request');

			$launchQuoteRequestOverlay.click(function (ev) {
				if(!$formOverlay.hasClass('show')) {
					$formOverlay.addClass('show');
					body.addClass('no-scroll');
					isOverlay = true;
				}
				ev.preventDefault();
			});

			$closeForm.click(function (ev) {
				if($formOverlay.hasClass('show')) {
					$formOverlay.removeClass('show');
					body.removeClass('no-scroll');
					isOverlay = false;
				}
				ev.preventDefault();
			});

			$('.form-preventivo select').select2({
				allowClear: true,
				dropdownParent: isOverlay || $formOverlay.hasClass('show') ? $formOverlay : $("body")
			});

			$('.form-preventivo select.zip_code').on('select2:select', function (e) {
				var data = e.params.data;
				$.ajax({
					url: ENV.zipCodes.show,
					type: 'get',
					dataType: 'json',
					data: {'cap':data.text},
					success: function (jsonObject) {
						var citySelect = $('.form-preventivo select#citta-field');
						var city = $('.form-preventivo input[name="city"]').not(citySelect);
						var district =  $('.form-preventivo select#provincia');
						if (city.length){
							$(city).val(jsonObject.citta);
						} else {
							citySelect.val(null).trigger('change')
							//console.log('select2',citySelect,jsonObject)
							if (jsonObject.allcities !== "") {
								citySelect.empty().trigger("change");
								$.each(jsonObject.allcities, function (k,v){
									var option = new Option(v, v, false, jsonObject.allcities.length == 1);
									citySelect.append(option).trigger('change');
								})
							}
						}
						$(district).val(jsonObject.provincia);
						if (city.length){
							$(city).trigger('change');
						}
						$(district).trigger('change');
					}
				});
			});

			// //SUBMIT BIND
			// $($btnSubmit).click(function(ev){
			// 	if(!validateForm()){
			// 		ev.preventDefault();
			// 	}
			// })
		}
	
	}

	function scrollIfHasError() {
		var hasError = $("#hasErrorForm");
		var header = $("#header");
		var nav = $("#nav");
        var extraHeight = 0;
        if (header.length && nav.length) {
            extraHeight = header.outerHeight()+nav.outerHeight();
		}
		if (hasError.length) {
            $('html, body').animate({
                scrollTop: hasError.offset().top-extraHeight
            }, 1500);
		}
    }
	
	function validateForm() {		
			
			var check = true;
			var name, radioChecked = [];
			var container = $('.form-preventivo form');
			var LABELS = window.LABELS;
			container.find("p.err").remove();	
		
			container.find("select,input,textarea").each(function () {
				var fieldValue = $.trim($(this).val());
				
				if ($(this).data("validate-required") && (($(this).attr("type") !== "checkbox" && $(this).attr("type") !== "radio" && fieldValue === "") || ($(this).attr("type") === "checkbox" && !$(this).prop("checked")))) {
					check = false;
					showError($(this).attr("id"), LABELS.formErrors.MandatoryField);
				}
				else if ($(this).data("validate-required") && $(this).attr("type") === "radio") {
					name = $(this).attr("name");
					if (container.find("input[name=" + name + "]:checked").length === 0) {
						check = false;
						//if(!radioChecked[name]) {
						radioChecked[name] = true;
						showError($(this).attr("id"), LABELS.formErrors.MandatoryField);
						//}
					}
				}
				
				if ($(this).data("validate-value") && $(this).attr("type") === "radio") {
					name = $(this).attr("name");
					
					if (container.find("input[name=" + name + "]:checked").val() != $(this).data("validate-value")) {						
						check = false;
						showError($(this).attr("id"), LABELS.formErrors.MandatoryField);						
					}
				}
				
				if ($(this).data("validate-email") && fieldValue !== "" && !isValidEmail(fieldValue)) {
					check = false;
					showError($(this).attr("id"), LABELS.formErrors.InvalidEmail);
				}
				if ($(this).data("validate-telefono") && fieldValue !== "" && !isValidPhoneNr(fieldValue)) {
					check = false;
					showError($(this).attr("id"), LABELS.formErrors.InvalidPhoneNr);
				}
				if ($(this).data("validate-cap") && fieldValue !== "" && !isValidZip(fieldValue)) {
					check = false;
					showError($(this).attr("id"), LABELS.formErrors.InvalidZip);
				}
				if ($(this).data("validate-codfis") && fieldValue !== "" && !isValidCodFis(fieldValue)) {
					check = false;
					showError($(this).attr("id"), LABELS.formErrors.InvalidCodFis);
				}
				if ($(this).data("validate-piva") && fieldValue !== "" && !isValidPartitaIva(fieldValue)) {
					check = false;
					showError($(this).attr("id"), LABELS.formErrors.InvalidPartitaIva);
				}
			});
			return check;
		}
	
		function showError(fieldId, msg) {
			var container = $('.form-preventivo form');
			//= this;
			
			var $field = container.find("#"+fieldId);
			if($field.attr("type") === "checkbox") {
			  container.find("#"+fieldId).before('<p class="err">'+msg+'</p>');
			}
			else if($field.attr("type") === "radio") {
			  container.find("#"+fieldId).parent().find("p.err").remove();
			  container.find("#"+fieldId).parent().append('<p class="err">'+msg+'</p>');
			}
			else {
				container.find("#"+fieldId).parent().find("p.err").remove();	
				container.find("#"+fieldId).parent().append('<p class="err">'+msg+'</p>');
			}
		}
		
		function initMultipleOffers()
		{
			var $formPreventivo = $('.form-preventivo');
			selectMultiple = $formPreventivo.find("select#multiple_offers");
            if(!selectMultiple.length) return;

            offerSelects = {
                selectBrand : $formPreventivo.find("select#brand-field"),
                selectModel : $formPreventivo.find("select#modello-field"),
                selectLeasingkm : $formPreventivo.find("select#leasing_km-field"),
                selectLeasingmonths : $formPreventivo.find("select#leasing_months-field"),
                selectFuel : $formPreventivo.find("select#fuel-field"),
                selectId: $formPreventivo.find("select#offer_id"),
                selectKey: $formPreventivo.find("select#productKey"),
            }

            
            selectMultiple.on('change', function(){
                selectedIndex = selectMultiple.prop("selectedIndex");
                Object.values(offerSelects).forEach( singleSelect => {
                    singleSelect.prop("selectedIndex", selectedIndex);
                    singleSelect.trigger("change");
                })

				multipleOfferSticky = $(".multiple-offers-sticky");
                if(multipleOfferSticky.length){
                    stickyImages = multipleOfferSticky.find("img");
                    stickyDescriptions = multipleOfferSticky.find("div.offer-sticky-description");

                    stickyImages.each(function(i){
                        if(i == selectedIndex){
                            $(this).removeClass("d-none");
                            $(this).addClass("d-block");
                        } else{
                            $(this).addClass("d-none");
                            $(this).removeClass("d-block");
                        }
                    });

                    stickyDescriptions.each(function(i){
                        if(i == selectedIndex){
                            $(this).removeClass("d-none");
                            $(this).addClass("d-block");
                        } else{
                            $(this).addClass("d-none");
                            $(this).removeClass("d-block");
                        }
                    })
                }
            })
		}
	
		return {
			init: init,
			initMultipleOffers: initMultipleOffers
		};
})(jQuery);
