var videoPlayOnHoverManager = (function () {
    function init() {
        $(document).ready(function () {
            var $videoHover = $("[data-video-hover]").hover( hoverVideo, hideVideo );
            var $videoHoverOut = $("[data-video-hover]").mouseout( stopVideo );

            function stopVideo(e) {
                if($(window).width() > 575){
                    $('video', this).get(0).load();
                }
            }
            function hoverVideo(e) {
                //console.log($(window).width());
                if($(window).width() > 575){
                    $('video', this).get(0).play();
                }
            }

            function hideVideo(e) {
                if($(window).width() > 575){
                    $('video', this).get(0).pause();
                }
            }
        });
    }

    return {
        init: init
    };
})(jQuery);
