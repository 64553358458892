var tabAreasCard = (function () {
    var gallery = [];
    function init() {
        eventHandler();
        initTabAreasCarousel();
        initNestedTabAreasGalleryCarousel()

    }

    function eventHandler(){
        if (!isMobile()){
            $('#tabs-card a').on("click", function (e){
                var currentCard = $(this);
                var currentTabPane = $(currentCard.attr("href"));
                e.preventDefault();
                manageTabAreasTransition(currentTabPane,currentCard)
            });
        }
        $('#tabs-card a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            e.target // newly activated tab
            e.relatedTarget // previous active tab
            refreshGallery();
        })
    }

    function manageTabAreasTransition(currentTabPane, currentCard){
        $(".tabs-card-content .tab-pane").not(currentTabPane).removeClass("active")
        $('#tabs-card a')
            .attr('aria-selected','false')
            .removeClass("active")
        $(currentCard.attr("href")).tab('show')
    }

    function refreshGallery(){
        if (gallery.length){
            $.each(gallery, function (i,carousel){
                $(carousel[i]).trigger('refresh.owl.carousel')
            })
        }
    }

    function initTabAreasCarousel () {
        var tabsCard = $('.owl-carousel.nav-tabs-card');
        if (tabsCard.length){
            tabsCard.each(function (){
                $(this).owlCarousel({
                    loop: false,
                    nav: false,
                    dots: false,
                    pullDrag: false,
                    touchDrag: $(".nav-link",this).length > 4,
                    mouseDrag: $(".nav-link",this).length > 4,
                    items: $(".nav-link",this).length > 3 ? $(".nav-link",this).length : 3,
                    margin: 20,
                    responsive:{
                        0:{
                            items:1,
                            stagePadding: 80,
                            onChanged: manageDraggedCard,
                            nav: false,
                            dots: true,
                            touchDrag: true,
                            margin: 0
                        },
                        992:{
                            items:3,
                            touchDrag: true,
                            margin: 0
                        },
                        1200:{
                            stagePadding: $(this).data('item-count') > 4 ? 80 : 0
                        }
                    }
                })
            })
        }
    }

    function manageDraggedCard(event) {
        var current = event.item.index;
        var currentCard = $(event.target).find(".owl-item").eq(current).find('a.nav-link');
        var currentTabPane = $($(currentCard).attr("href"));
        manageTabAreasTransition(currentTabPane,currentCard)
        currentCard.addClass('active');
    }

    function initNestedTabAreasGalleryCarousel() {
        var equipmentsGallery = $('.equipments-features-gallery');
        if (equipmentsGallery.length) {
            equipmentsGallery.each(function (){
                var equipmentGalleryCarousel = $(this).owlCarousel({
                    navigation : false,
                    slideSpeed : 300,
                    paginationSpeed : 400,
                    singleItem:true,
                    responsiveClass: true,
                    loop: false,
                    mouseDrag: $("img",this).length > 1,
                    touchDrag: $("img",this).length > 1,
                    pullDrag: false,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        500: {
                            items: 1,
                        },
                        768: {
                            items: 1,
                        }
                    }
                })
                gallery.push(equipmentGalleryCarousel)
            })
        }
    }

    return {
        init: init
    };
})(jQuery);
