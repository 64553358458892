var assistanceBlockR23 = (function () {

    function init() {
        initCarousel();
    }


    function initCarousel () {
        var itemsSlider = $('.assistance-block-r23__container__row__items');
        if (itemsSlider.length){
            itemsSlider.each(function (){
                $(this).owlCarousel({
                    loop: true,
                    infinite: true,
                    margin: 35,
                    items: 4,
                    nav:true,
                    navText: ["<i class='prev-icon'></i>","<i class='next-icon'></i>"],
                    dots:false,
                    responsive:{
                        0:{
                            items:1,
                            nav:false
                        },
                        600:{
                            items:2,
                            nav:false
                        },
                        1000:{
                            items:4
                        }
                    }
                })
            })
        }
    }

    return {
        init: init
    };
})(jQuery);
