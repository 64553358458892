var governanceCarouselManager = (function () {
    var carouselObj;
    var carousel = $('.governance-carousel .owl-carousel');
    function init() {
        $(document).ready(function () {
            if(carousel.length){
                initCarousel();
            }
        });
    }

    function initCarousel() {
        carouselObj = carousel.owlCarousel({
            dots: false,
            nav: true,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            mouseDrag: true,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 15,
                    singleItem: true,
                    dots: false,
                    center: true,
                    margin: -45,
                    loop: false,
                },
                992: {
                    items: 2,
                },
                1200:{
                    items: 2
                }
            },
            onInitialized: callback,
            onResized: callback
        });
        function callback(events) {
            applyHeightToFlipBox();
        }
    }

    function applyHeightToFlipBox() {
        var isFirst = false;
        var heightToApply = null;
        $(".governance-box", carousel).each(function () {
            if(!isFirst) {
                heightToApply = $(this).find('.governance-box-front');
                isFirst = true;
            }
            $(this).css({
                'height': heightToApply.height()
            });
        });
    }

    return {
        init: init
    };
})(jQuery);
