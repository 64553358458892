var peopleManager = (function () {
  function init() {
    $(".single-people-card-container").on("click", function (e) {
      if (e.target.classList.contains("fab")) return;
      //$(this).find(".person-modal-detail").modal("show");
    });
  }

  return {
    init: init
  };
  
})(jQuery);
