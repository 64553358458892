var optionalIncludedManager = (function () {
    function init() {
        optionalIncludedManager();
    }

    function optionalIncludedManager() {
        if ($(".optional-carousel.owl-carousel").length) {
            $(".optional-carousel.owl-carousel").owlCarousel({
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                responsive:{
                    0:{
                        items: 1,
                        stagePadding: 30,
                        singleItem: true,
                        dots: false,
                        center: true,
                        margin: -190,
                        loop: true,
                    },
                    767:{
                        items: 2,
                        stagePadding: 20,
                        dots: false,
                        center: true,
                        loop: true,
                    },
                    1200: {
                        items: 3,
                        stagePadding: 20,
                        margin: 20,
                        dots: false,
                        center: true,
                        loop: true,
                    }
                }
            });
        }
    }


    return {
        init: init
    };
})(jQuery);
