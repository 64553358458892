var navbarManager = (function () {

    function init() {
        eventHandler();
        hideMainNavOnScroll();
        if ($('.offer-sticky').length){
            manageOfferStickyNavbar();
        }
        trackPrimaryMenuClick();
        trackSecondaryMenuClick();
    }

    function eventHandler() {
        var toggleNavbarBtn = $(".navbar-toggler");
        var navbarCloseBtn = $(".nav-close");
        $(document).ready(function () {
            if ($('.offer-sticky').length){
                $(".nav-main").removeClass('shadow-lg')
            }
            $(".nav-tabs a").click(function(){
                $(this).tab('show');
            });
            $(".side-menu li").on('mouseover',function () {
                $(this).addClass('show');
            });
            $(".side-menu li").on('mouseout',function () {
                $(this).removeClass('show');
            });
            toggleNavbarBtn.on("click",function (e) {
                navToggler();
                e.preventDefault();
            });
            navbarCloseBtn.on("click", function (e) {
                navToggler();
                e.preventDefault();
            })
        });
        $(window).on('scroll', function() {
            toggleNavbarTransparentAndDefault();
        });
    }

    function toggleNavbarTransparentAndDefault() {
        var menuTop = $('.nav-main');

        if ($(".nav-top").length) {
            if ($(this).scrollTop() > $(".nav-top").outerHeight()) {
                if(menuTop.hasClass("sticky-top")){
                    menuTop.addClass('navbar-sticky');
                }
                
            } else {
                menuTop.removeClass('navbar-sticky');
            }
        } else{
            if ($(this).scrollTop() > menuTop.outerHeight()) {
                if(menuTop.hasClass("sticky-top")){
                    menuTop.addClass('navbar-sticky');
                }
            } else {
                menuTop.removeClass('navbar-sticky');
            }
        }
    }

    function manageOfferStickyNavbar(){
        $('.offer-sticky').css({
            top:  $('.nav-main').outerHeight()
        })
        if ($(window).width() < 767){
            $('.offer-sticky .btn.btn-leasys-red').addClass('btn-icon')
        } else {
            $('.offer-sticky .btn.btn-leasys-red').removeClass('btn-icon')
        }
    }

    function hideMainNavOnScroll() {
        if (isMobile()){
            //$('.offer-sticky .btn.btn-leasys-red').addClass('btn-icon')
            // $(document).ready(function() {
            //     $(window).scroll()
            // });
            // $(function() {
            //     var t = 0;
            //     $(window).scroll(function(e) {
            //         var n = $(this).scrollTop();
            //         Math.abs(t - n) <= 5 || (n > t && n > 200 ? $(".nav-main").css("top", "-100%").hover(function() {
            //             $(".nav-main").css("top", "0px")
            //         }) : $(".nav-main").css("top", "0px"),
            //             t = n)
            //     })
            // });
        } else {
            //$('.offer-sticky .btn.btn-leasys-red').removeClass('btn-icon')
        }
    }

    function navToggler() {
        var navbarToToggle = $(".nav-collapse");
        var body = $("body");

        if (navbarToToggle.hasClass('show')) {
            navbarToToggle.removeClass('show');
            body.removeClass('no-scroll');
        } else {
            navbarToToggle.addClass('show');
            body.addClass('no-scroll');
        }
    }

    //track the navigation header links click (white menu)
    function trackPrimaryMenuClick(){
        $(".nav-main a.nav-link, .nav-main a.dropdown-item, .nav-main a.navbar-brand").not(".dropdown-toggle").on("click",function(event){
            //console.log("PRIMARY LINK CLICKED!!!");
            var regionClicked = "primary-menu";
            var textClicked = event.currentTarget.textContent.trim() ? event.currentTarget.textContent.trim() : event.currentTarget.ariaLabel.trim();
            var pathClicked = event.currentTarget.href.trim();
            manageGTM.trackMenuClick(regionClicked, textClicked, pathClicked);
        });
    }

    //track the utility header links click (blue menu)
    function trackSecondaryMenuClick(){
        $(".nav-top a.nav-link, .nav-top a.dropdown-item").not(".dropdown-toggle").on("click",function(event){
            //console.log("SECONDARY LINK CLICKED!!!");
            var regionClicked = "secondary-menu";
            var textClicked = event.currentTarget.textContent.trim();
            var pathClicked = event.currentTarget.href.trim();
            manageGTM.trackMenuClick(regionClicked, textClicked, pathClicked);
        });
    }

    return {
        init: init
    };

})(jQuery);
