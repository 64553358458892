var sliderManager = (function () {

    var mainSliderCarousel = [];
    var mainSliderSmallCarousel = [];
    var mainSliderContainedCarousel = [];
    var mainSlider = $(".main-slider");
    var mainSliderSmall = $(".main-slider--small");
    var mainSliderContained = $(".main-slider--contained");

    function init() {
        $(document).ready(function () {
            /**EXAMPLE TO REMOVE**/
            // $(".offer-box a.btn").on("click",function (e) {
            //     manageGTM.trackSliderOfferClick(this);
            // })
            if (mainSlider.length){
                initMainSlider();
            }
            if (mainSliderSmall.length){
                initMainSliderMini();
            }
            if(mainSliderContained.length) {
                initMainSliderContained();
            }
        })
    }

    function initMainSlider() {
        var elems;
        mainSlider.each(function () {

            var animation_duration = $(this).data("customanimationsd");
            var animation_interval = $(this).data("customanimationsi");

            elems = $(this).owlCarousel({
                items: 1,
                margin: 0,
                singleItem: true,
                nav: true,
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                autoplayTimeout: animation_interval > 1 ? animation_interval: 4000,
                autoplayHoverPause: animation_interval > 1 ? true : false,
                autoplay: animation_interval > 1 ? true : false,
                smartSpeed: animation_duration > 1 ? animation_duration: 450,
                mouseDrag:false,
                loop: animation_interval > 1 ? true : false,
                dots: $(this).children(".item").length > 1 ? true : false,
                onInitialized: sliderInit,
                onResized: sliderResized,
                onChanged: sliderChanged,
                onRefreshed: onRefreshedEvent
            });
            mainSliderCarousel.push(elems);
        });
    }

    function sliderInit(sliders) {
        var slider = $(sliders.currentTarget);
        setNavPosition(slider);
        setHtml5Height(slider);
        applyHeightToFlipBox(slider);
        // setSliderSkinHeight(slider);
        var items = sliders.item.count;
        if (items > 6) {
            resizeDots(slider);
        }
    }

    function onRefreshedEvent(sliders) {
        var slider = $(sliders.currentTarget);
        setNavPosition(slider);

    }

    function sliderResized(sliders) {
        var slider = $(sliders.currentTarget);
        setNavPosition(slider);
        setHtml5Height(slider);
        applyHeightToFlipBox(slider);
        // setSliderSkinHeight(slider);
        var items = sliders.item.count;
        if (items > 6) {
            resizeDots(slider);
        }
    }

    function sliderChanged(sliders) {
        if(!isMobile()){
            sliderAnimation(sliders);
        }
    }

    function initMainSliderMini() {
        var elems;
        mainSliderSmall.each(function () {
            elems = $(this).owlCarousel({
                items: 1,
                margin: 0,
                singleItem: true,
                nav: true,
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                autoplayTimeout: 4000,
                autoplayHoverPause: false,
                autoplay: false,
                smartSpeed:450,
                mouseDrag:false,
                loop: $(this).children(".item").length > 1 ? true : false,
                dots: $(this).children(".item").length > 1 ? true : false,
                onInitialized: sliderInit,
                onResized: sliderResized,
                onChanged: sliderChanged,
                onRefreshed: onRefreshedEvent
            });
            mainSliderSmallCarousel.push(elems);
        });

    }

    function sliderAnimation(slider) {
        var currentSlider = $(slider.target).find(".owl-item").eq(slider.item.index);
        var offerBox = currentSlider.find(".offer-box");
        var contentCenter = currentSlider.find(".main-slider-content .text-center");
        var contentLeft = currentSlider.find(".main-slider-content .text-left");
        var mainCTA = currentSlider.find(".main-slider-cta:visible");

        if (offerBox.length) {
            var carImg = $(".car-image:visible", currentSlider).not('.invisible');
            //var btn = $(".btn:visible", offerBox);

            /*offerBox.addClass('zoomIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                offerBox.removeClass('zoomIn animated');
            });*/

            carImg.addClass('slideInRight animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                carImg.removeClass('slideInRight animated');
            });

        }

        if (contentCenter.length){
            $(".logos", contentCenter).addClass('zoomIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(".logos", contentCenter).removeClass('zoomIn animated');
            });
            $("h3", contentCenter).addClass('zoomIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $("h3", contentCenter).removeClass('zoomIn animated');
            });
            $(".wyswyg", contentCenter).addClass('zoomIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(".wyswyg", contentCenter).removeClass('zoomIn animated');
            });
            $(".btn:visible", contentCenter).addClass('zoomIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(".btn:visible", contentCenter).removeClass('zoomIn animated');
            });
        }

        if (contentLeft.length){
            $(".logos", contentLeft).addClass('slideInDown animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(".logos", contentLeft).removeClass('slideInDown animated');
            });
            $("h3", contentLeft).addClass('slideInDown animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $("h3", contentLeft).removeClass('slideInDown animated');
            });
            $(".wyswyg", contentLeft).addClass('slideInDown animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(".wyswyg", contentLeft).removeClass('slideInDown animated');
            });
            $(".btn:visible", contentLeft).addClass('slideInDown animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                $(".btn:visible", contentLeft).removeClass('slideInDown animated');
            });
        }

        if (mainCTA .length){
            mainCTA.addClass('zoomIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                mainCTA.removeClass('zoomIn animated');
            });
        }
    }

    function applyHeightToFlipBox(target) {
        $(".flip-card",target).each(function () {
            var heightToApply = $(this).find('.offer-box-front');
            var backFlip = $(this).find('.offer-box-back');
            $(this).css({
                'height': heightToApply.height()
            });
            backFlip.css({
                'height': '100%'
            })
        });
    }

    function setHtml5Height(target) {
        var heightFromTop = $(".owl-item.active .main-slider-image").height();

        $(".main-slider-video-container", target).each(function () {
            $(this).css({
                'height': heightFromTop
            });
        });
        $(".main-slider .html5", target).each(function () {
            $(this).css({
                'min-height': heightFromTop
            });
        })
    }

    function setSliderSkinHeight(target) {
        var heightFromTop = $(".base",target).outerHeight();
//        console.log(heightFromTop);
        $(".gradient-slider", target).each(function () {
            $(this).css({
                'height': heightFromTop+20
            });
        });
        $(".gradient-slider", target).each(function () {
            $(this).css({
                'min-height': heightFromTop+20
            });
        })
    }

    function setNavPosition(target) {
        var  fcs = $(".four-column-slider",target);
        var browserWidth = $(window).width();
        if (mainSlider.length) {
            var desktopWidthImage = 1917;
            var desktopHeightImage = 575;
            var tabletWidthImage = 1024;
            var tabletHeightImage = 575;
            var mobileWidthImage = 767;
            var mobileHeightImage = 575;

            var desktopHeight = Math.round(browserWidth/(desktopWidthImage/desktopHeightImage));
            var tabletHeight = Math.round(browserWidth/(tabletWidthImage/tabletHeightImage));
            var mobileHeight = Math.round(browserWidth/(mobileWidthImage/mobileHeightImage));
            if ($(window).width() > 1200) {
                if (fcs.length) {
                    $(".desktop .col-xl-2",fcs).attr('style','height: '+desktopHeight+'px');
                }
                $(".owl-nav",target).attr('style','top: '+(desktopHeight/2)+'px');
            }
            if ($(window).width() < 1200) {
                $(".owl-nav",target).attr('style','top: '+(tabletHeight/2)+'px');
            }
            if ($(window).width() < 768) {
                $(".owl-nav",target).attr('style','top: '+mobileHeight+'px');
            }
        }

        if (mainSliderSmall.length) {
            var desktopSmallWidthImage = 1917;
            var desktopSmallHeightImage = 424;
            var tabletSmallWidthImage = 1024;
            var tabletSmallHeightImage = 424;
            var mobileSmallWidthImage = 767;
            var mobileSmallHeightImage = 424;

            var desktopSmallHeight = Math.round(browserWidth/(desktopSmallWidthImage/desktopSmallHeightImage));
            var tabletSmallHeight = Math.round(browserWidth/(tabletSmallWidthImage/tabletSmallHeightImage));
            var mobileSmallHeight = Math.round(browserWidth/(mobileSmallWidthImage/mobileSmallHeightImage));
            if ($(window).width() > 1200) {
                $(".owl-nav",target).attr('style','top: '+(desktopSmallHeight/2)+'px');
            }
            if ($(window).width() < 1200) {
                $(".owl-nav",target).attr('style','top: '+(tabletSmallHeight/2)+'px');
            }
            if ($(window).width() < 768) {
                $(".owl-nav",target).attr('style','top: '+mobileSmallHeight+'px');
            }
        }
    }

    function resizeDots(target) {
        $(document).find('.owl-dots',target).addClass('owl-dots--lg');
    }

    function initMainSliderContained() {
        var elems;
        mainSliderContained.each(function () {
            elems = $(this).owlCarousel({
                items: 1,
                margin: 0,
                singleItem: true,
                nav: true,
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                autoplayTimeout: 4000,
                autoplayHoverPause: false,
                autoplay: false,
                smartSpeed:450,
                mouseDrag:false,
                loop: false ,
                dots: $(this).children(".item").length > 1,
                onInitialized: sliderInit,
                onResized: sliderResized,
                onChanged: sliderChanged
            });
            mainSliderContainedCarousel.push(elems);
        });

    }

    return {
        init: init
    };
})(jQuery);
