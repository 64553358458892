var sideMenuManager = (function () {

    function init() {
        eventHandler();
    }

    function eventHandler() {
        var sideMenuToggleBtn = $(".side-menu li");
        $(document).ready(function () {
            sideMenuToggleBtn.on('mouseover',function () {
                if (!$(this).hasClass('show')) {
                    $(this).addClass('show');
                }
            });
            sideMenuToggleBtn.on('mouseout',function () {
                $(this).removeClass('show');
            });
        });
    }


    return {
        init: init
    };

})(jQuery);
