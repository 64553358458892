var ourStoryCarouselManager = (function () {
    var ourStoryObj = $('.our-story-carousel.owl-carousel');
    var ourExtraContentObj = $('.our-story-extracontent.owl-carousel');
    var ourStoryCarousel;
    var ourStoryExtraContentCarousel;


    var currentH4 = null;
    var numberOfContent = null;
    var isDataTab = false;


    var isLastYear = false;

    function init() {
        $(document).ready(function () {
            if(ourStoryObj.length) {
                initOurStoryCarousel();
                ourStoryNavigation();
            }
            if (ourExtraContentObj.length) {
                initOurStoryExtraCarousel()
            }
        });
    }

    function ourStoryNavigation() {
        var indiceContenuto = 0;
        $(".our-story .prev-btn").on("click", function () {
            if (isDataTab) {
                if (numberOfContent === 1) {
                    ourStoryCarousel.trigger('prev.owl.carousel');
                    updateContent(currentH4,0);
                    isLastYear = false;
                } else if(indiceContenuto === 0) {
                    ourStoryCarousel.trigger('prev.owl.carousel');
                    updateContent(currentH4,numberOfContent-1);
                    indiceContenuto = numberOfContent-1;
                    isLastYear = false;
                } else if(indiceContenuto < numberOfContent) {
                    updateContent(currentH4,indiceContenuto-1);
                    indiceContenuto--;
                }
            } else {
                ourStoryCarousel.trigger('prev.owl.carousel');
                updateContent(currentH4,-1);
            }
        });
        $(".our-story .next-btn").on("click", function () {
            /*if (isLastYear && indiceContenuto === (numberOfContent-1)){
                ourStoryCarousel.trigger('to.owl.carousel', [0, 500]);
                updateContent(currentH4, 0);
                indiceContenuto = 0;
                isLastYear = false;
            }*/
            if (isDataTab) {
                if (numberOfContent === 1) {
                    ourStoryCarousel.trigger('next.owl.carousel');
                    updateContent(currentH4,0);
                } else if(indiceContenuto === 0) {
                    updateContent(currentH4,numberOfContent-1);
                    indiceContenuto++;
                } else if(indiceContenuto < (numberOfContent-1)) {
                    updateContent(currentH4,indiceContenuto);
                    indiceContenuto++;
                } else if(indiceContenuto === (numberOfContent-1) && isLastYear) {
                    ourStoryCarousel.trigger('to.owl.carousel', [0, 500]);
                    updateContent(currentH4,0);
                    indiceContenuto = 0;
                    isLastYear = false;
                } else if(numberOfContent === 1 && isLastYear) {
                    ourStoryCarousel.trigger('to.owl.carousel', [0, 500]);
                    updateContent(currentH4,0);
                    indiceContenuto = 0;
                    isLastYear = false;
                } else {
                    ourStoryCarousel.trigger('next.owl.carousel');
                    updateContent(currentH4,0);
                    indiceContenuto = 0;
                }
            } else {
                ourStoryCarousel.trigger('next.owl.carousel');
                updateContent(currentH4,-1);
            }
        });
        /*$(".our-story .prev-btn").on("click", function () {
            //updateContent(currentH4);
            // var number = parseInt(currentItem.attr("data-tab-count"));
            // if (hasMoreTab && initCounter === 0){
            //     $('.multiple-tab.row #pills-'+initCounter-1+'-tab').tab('show');
            //     initCounter--
            //     return false;
            // } else {
            //     initCounter = 0;
            // }

            if (lastSavedContentIndex > 0 && !isFirstContent) {
                updateContent(currentH4,lastSavedContentIndex-1);
                lastSavedContentIndex--;
            } else {
                ourStoryCarousel.trigger('prev.owl.carousel');
                updateContent(currentH4,numberOfContent-1);
            }
        });
        $(".our-story .next-btn").on("click", function () {
            if (numberOfContent > 1 && contentIndex < (numberOfContent-1)) {
                updateContent(currentH4, contentIndex+1);
                contentIndex++;

                lastSavedContentIndex = contentIndex;
                isFirstContent = false;
            } else if (isLast) {
                ourStoryCarousel.trigger('to.owl.carousel', [0, 500]);
                updateContent(currentH4, 0);
                contentIndex = 0;
                isLast = false;
                isFirstContent = true;
            } else {
                ourStoryCarousel.trigger('next.owl.carousel');
                updateContent(currentH4, 0);
                isFirstContent = true;
                contentIndex = 0;
            }

            // var number = parseInt(currentItem.attr("data-tab-count"));
            // if (hasMoreTab && initCounter < number){
            //     $('.multiple-tab.row #pills-'+initCounter+'-tab').tab('show');
            //     initCounter++
            //     return false;
            // } else {
            //     initCounter = 0;
            // }

        });*/
    }

    function initOurStoryExtraCarousel() {
        ourStoryExtraContentCarousel = ourExtraContentObj.owlCarousel({
            nav: false,
            dots: false,
            center: true,
            loop: false,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            mouseDrag: false,
            touchDrag: false,
            items: 1
        })
    }

    function initOurStoryCarousel() {
        ourStoryCarousel = ourStoryObj.owlCarousel({
            nav: false,
            dots: false,
            center: true,
            loop: false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            autoplayTimeout: 4000,
            autoplayHoverPause: false,
            autoplay: false,
            smartSpeed: 450,
            onInitialized: initializedFunction,
            onChanged: changedFunction,
            onChange: changeFunction,
            responsive:{
                0:{
                    items: 5,
                    stagePadding: 25
                },
                767: {
                    items: 5,
                    stagePadding: 25

                },
                1200: {
                    items: 10,
                    stagePadding: 50
                }
            }
        })

    }

    function changeFunction(event){

        // initCounter = 0;
        // if (currentItem.attr('data-tab-count') !== '1'){
        //     hasMoreTab = true;
        // } else {
        //     hasMoreTab = false;
        // }
    }

    function initializedFunction(event) {
        currentH4 = $(event.target).find(".owl-item").eq(event.item.index).find("h4");
        var dataTabCount = $(event.target).find(".owl-item").eq(event.item.index).find(".item").attr("data-tab-count");
        var dataTab = $(event.target).find(".owl-item").eq(event.item.index).find(".item").attr("data-tab");
        if (typeof dataTabCount !== typeof undefined){
            numberOfContent = parseInt(dataTabCount);
            if (numberOfContent === 1){
                updateContent(currentH4,0);
            }
        } else{
            updateContent(currentH4,-1);
        }
        if (typeof dataTab !== typeof undefined && dataTab === 'true'){
            isDataTab = true;
        }
    }

    function changedFunction(event) {
        var item = $(event.target).find(".owl-item").eq(event.item.index).find('.item')
        currentH4 = $(event.target).find(".owl-item").eq(event.item.index).find("h4");
        var dataTabCount = item.attr("data-tab-count");
        var dataTab = item.attr("data-tab");
        if (typeof dataTabCount !== typeof undefined){
            numberOfContent = parseInt(dataTabCount);
        }
        if (typeof dataTab !== typeof undefined && dataTab === 'true'){
            isDataTab = true;
        }
        var numberOfYears     = event.item.count;     // Number of items
        var currItem      = event.item.index;     // Position of the current item
        var size      = event.page.size;      // Number of items per page

        if (currItem === 0){
            $(".our-story .prev-btn").addClass('disabled')
        } else {
            $(".our-story .prev-btn").removeClass('disabled')
        }

        if ((numberOfYears - currItem) === size) {
            isLastYear = true;
        }

        //GO TO SLIDE
        if (typeof ourStoryExtraContentCarousel !== typeof undefined){
            ourStoryExtraContentCarousel.trigger('to.owl.carousel',event.item.index)
        }
    }

    // function updateTabContent(data,item)
    // {
    //     var bindTextPlaceholder = $(".our-story .story-text-placeholder");
    //     var bindImagePlaceholder = $(".our-story .story-image-placeholder");
    //     bindImagePlaceholder.addClass('d-none');
    //     bindTextPlaceholder.addClass('d-none');
    //     var multipleTab = $(".multiple-tab.row");
    //     $(".to-remove",multipleTab).remove();
    //     var tabContent = $(".tab-content", multipleTab)
    //     var navPills = $(".nav-pills", multipleTab)
    //     var tabPaneToClone = $(".tab-pane", multipleTab);
    //     var navItemToClone = $(".nav-item", multipleTab);
    //     var countItem = item.attr("data-tab-count")
    //     for(var i = 0; i < countItem; i++) {
    //         var title = data.attr('data-text-'+i)
    //         var img = data.attr('data-image-'+i)
    //         var tabPaneCloned = tabPaneToClone.clone().removeClass('d-none').addClass('to-remove');
    //         var navItemCloned = navItemToClone.clone().removeClass('d-none').addClass('to-remove');
    //         $("h6",tabPaneCloned).html(title);
    //         tabPaneCloned.attr('id','pills-'+i);
    //         tabPaneCloned.attr('aria-labelledby','pills-'+i+'-tab');
    //         $('.nav-link',navItemCloned).attr('id','pills-'+i+'-tab')
    //         $('.nav-link',navItemCloned).attr('href','#pills-'+i)
    //         $('.nav-link',navItemCloned).attr('aria-controls','pills-'+i)
    //
    //         $('img',navItemCloned).attr("src",img)
    //         // tabPaneCloned.addClass('active show')
    //         // $('.nav-link',navItemCloned).addClass('active')
    //         //     .attr('aria-selected','true')
    //         // if (countItem === '3' && i === 1){
    //         //     tabPaneCloned.addClass('active show');
    //         //     $('.nav-link',navItemCloned).addClass('active')
    //         //         .attr('aria-selected','true')
    //         // }
    //         // if (countItem === '2' && i === 0){
    //         //     tabPaneCloned.addClass('active show')
    //         //     $('.nav-link',navItemCloned).addClass('active')
    //         //         .attr('aria-selected','true')
    //         // }
    //         if (countItem === '1' && i === 0){
    //             tabPaneCloned.addClass('active show')
    //             $('.nav-link',navItemCloned).addClass('active')
    //                 .attr('aria-selected','true')
    //         }
    //         tabContent.append(tabPaneCloned)
    //         navPills.append(navItemCloned)
    //     }
    //     multipleTab.removeClass('d-none');
    // }

    function updateContent(data, index) {
        var text,image;
        if (index !== -1) {
            $(".our-story .prev-btn").attr('data-index',index)
            $(".our-story .next-btn").attr('data-index',index)
            text = data.attr("data-text-"+index);
            image = data.attr("data-image-"+index);
        } else {
            text = data.attr("data-text");
            image = data.attr("data-image");
        }

        if (typeof text !== typeof undefined && typeof image !== typeof undefined) {
            var bindTextPlaceholder = $(".our-story .story-text-placeholder");
            var bindImagePlaceholder = $(".our-story .story-image-placeholder");
            bindImagePlaceholder.removeClass('d-none').attr("src", image);
            bindTextPlaceholder.removeClass('d-none').html(text);
        }
    }

    return {
        init: init
    };
})(jQuery);
