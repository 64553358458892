var iconTextListCardsCarouselManager = (function (){
    var iconTextCardsCarousel;
    var numbersAnimated = false;
    var indexCard = 0;
    var iconTextCardsCarouselObj = $(".icons-text-list-cards-component .owl-carousel");
    var iconTextCardsCarouselComponent = $(".icons-text-list-cards-component");

    function init(){
        $(document).ready(function (){
            if(iconTextCardsCarouselObj.length){
                initCarousel();
            }
            if(iconTextCardsCarouselComponent.length){
                resizeCardsHeight();
            }
            if (iconTextCardsCarouselComponent.length){
                initNumberCounter();
            }
        })

        $(window).resize(function(){
            if(iconTextCardsCarouselComponent.length){
                resizeCardsHeight();
            }
        })

        $(window).on('shown.bs.tab', function (e) {
            var prevComponent = iconTextCardsCarouselComponent;
            iconTextCardsCarouselComponent = $('.tab-pane.active.show .icons-text-list-cards-component', e.target.closest('section'));
            if (iconTextCardsCarouselComponent.length>0){
                var prevCarousel = iconTextCardsCarouselObj;
                iconTextCardsCarouselObj = iconTextCardsCarouselComponent.find('.owl-carousel');
                if(iconTextCardsCarouselObj.length){
                    initCarousel();
                }
                if(iconTextCardsCarouselComponent.length){
                    resizeCardsHeight();
                }
                if (iconTextCardsCarouselComponent.length){
                    initNumberCounter();
                }
                iconTextCardsCarouselObj = prevCarousel;
            }
            iconTextCardsCarouselComponent = prevComponent;
        })
    }

    function initNumberCounter() {
        iconTextCardsCarouselComponent.find('.icons-and-text-inline.cards-place .single-card-container .single-card').each(function () {
            var currentNumber = $(this).find('.number');
            currentNumber.data('countToOptions', {
                formatter: function (value, options) {
                    return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.');
                }
            });
        });

        $(window).on('scroll', function() {
            iconTextCardsCarouselComponent.each(function () {
                var bottom_of_object = iconTextCardsCarouselComponent.offset().top + iconTextCardsCarouselComponent.outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if( bottom_of_window > bottom_of_object/1.2 && !numbersAnimated){
                    $('.icons-and-text-inline.cards-place .single-card-container .single-card', iconTextCardsCarouselComponent).each(count);
                    numbersAnimated = true;
                }
            })
        });
    }

    function count(options) {
        var $this = $(this).find('.number');
        if ($this.length && $this.data('speed')>0){
            options = $.extend({}, options || {}, parseInt($this.text()) || {});
            $this.countTo(options);
        }
    }

    function resizeCardsHeight(){
        if(window.innerWidth < 768 || ENV.isEditmode) return;
        iconTextCardsCarouselComponent.each(function(){

            if ($(this).find('.icons-and-text-inline').length){
                var singleCards = $(this).find('.icons-and-text-inline .single-card-container .single-card');
                var maxCardsHeight = 0;
                singleCards.each(function () {
                    $(this).outerHeight('auto');
                    maxCardsHeight = Math.max(maxCardsHeight, $(this).outerHeight());
                });
                singleCards.outerHeight(maxCardsHeight);
            }else{
                textContainers = $(this).find(".card-text-container");
                ctaContainers = $(this).find(".card-cta-container");
                textContainersMaxHeight = 0;
                ctaContainersMaxHeight = 0;
                if(textContainers.length){
                    textContainers.each(function(){
                        $(this).height('auto');
                        if($(this).height() > textContainersMaxHeight) textContainersMaxHeight =  $(this).height();
                    })
                    textContainers.each(function(){
                        $(this).height(textContainersMaxHeight);
                    })
                }
                if(ctaContainers.length){
                    ctaContainers.each(function(){
                        $(this).height('auto');
                        if($(this).height() > ctaContainersMaxHeight) ctaContainersMaxHeight =  $(this).height();
                    })
                    ctaContainers.each(function(){
                        $(this).height(ctaContainersMaxHeight);
                    })
                }
            }
        });
    }

    function initCarousel(){
        $(".item", iconTextCardsCarouselObj).each( function (k,v){
            if ($(this).hasClass('active')){
                indexCard = k;
            }
        });
        iconTextCardsCarouselObj.each(function () {
            var currentCarousel = $(this);
            var iconTextCardsCount = currentCarousel.find('.single-card').length;
            var ItemPerRowDesktop = currentCarousel.closest(".cards-place").data("items-per-row-desktop");
            var ItemPerRowTablet = currentCarousel.closest(".cards-place").data("items-per-row-tablet");
            iconTextCardsCarousel = currentCarousel.owlCarousel({
                navText: [
                    "<i class='fas fa-arrow-left'></i>",
                    "<i class='fas fa-arrow-right'></i>"
                ],
                dots: false,
                center: iconTextCardsCount == 1,
                loop: iconTextCardsCount > 2 && !ENV.isEditmode,
                responsive:{
                    0:{
                        mouseDrag: iconTextCardsCount > 1 && !ENV.isEditmode,
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: false,
                        center: false,
                        loop: iconTextCardsCount > 1 && !ENV.isEditmode,
                        nav: false,
                        stagePadding: 25,
                    },
                    768: {
                        items: iconTextCardsCount > 1 ? ItemPerRowTablet : 1,
                        loop: iconTextCardsCount > 1 && !ENV.isEditmode,
                        nav:iconTextCardsCount > 1,
                        center: false,
                    },
                    992: {
                        loop: iconTextCardsCount > 2 && !ENV.isEditmode,
                        mouseDrag: iconTextCardsCount > 2,
                        items: iconTextCardsCount > ItemPerRowDesktop ? ItemPerRowDesktop : (iconTextCardsCount),
                        nav:iconTextCardsCount > 1,
                    }
                }
            });
        });
    }

    return {
        init: init
    }
})(jQuery);