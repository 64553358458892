var normativaAccordion = (function () {

    function init() {

        var showMoreBtn = $('.normativa-accordion-toggle-item');

        showMoreBtn.on('click', function (e) {

            e.preventDefault();

            var $pressedBtn = $(this);
            var hideableElements = $pressedBtn.parent().next();

            if (!$pressedBtn.hasClass('toggle-active')) {


                hideableElements.slideDown(300);
                hideableElements.removeClass('normativa-hideable');
                if (hideableElements.parent().hasClass('normativa-accordion-vantaggi')) {
                    hideableElements.slideDown(300);
                    hideableElements.next().removeClass('normativa-hideable');
                }
                $pressedBtn.addClass('toggle-active');

            } else {

                hideableElements.slideUp(300, function () {
                    hideableElements.addClass('normativa-hideable');
                });
                if (hideableElements.parent().hasClass('normativa-accordion-vantaggi')) {
                    hideableElements.slideUp(300, function () {
                        hideableElements.next().addClass('normativa-hideable');
                    });
                }
                $pressedBtn.removeClass('toggle-active');

            }
        });
    }

    return {
        init: init
    }
}(jQuery));
