var contactsBoxCarouselManager = (function () {
    var carouselObj,carouselInternationalObj;
    var carousel = $('.contacts-box-section .owl-carousel');
    var carouselInternational = $('.international-contacts-box');
    var carouselCount = $(".item", carousel).length;
    var carouselInternationalCount = $(".item", carouselInternational).length;
    function init() {
        $(document).ready(function () {
            var total = 0;
            carouselInternational.on('changed.owl.carousel', function(e) {
                total = e.relatedTarget.items().length;
                if(total>3){
                    updateLastActive(e,carouselInternational);
                }
            });
            carouselInternational.on('initialized.owl.carousel', function(e) {
                $(this).each(function (){
                    total = e.relatedTarget.items().length;
                    carouselInternational = $(this);
                    if(total==1){
                        carouselInternational.find('.owl-item').addClass('last-active');
                    }
                    if(total==2){
                        carouselInternational.find('.owl-item').eq(1).addClass('last-active');
                    }
                    if(total==3){
                        console.log("3");
                        carouselInternational.find('.owl-item').eq(2).addClass('last-active');
                    }
                });
            });
            if(carousel.length){
                initCarousel();
                adjustHeight();
            }
            if(carouselInternational.length){
                initCarouselInternational();
                adjustHeight();
            }
        });
    }

    function updateLastActive(event,owl) {
        //var total = $(".international-contacts-box .item").length;
        var activeItems = event.item.count;
        //var lastVisibleItemIndex = event.item.index + activeItems - 4;
        var lastVisibleItemIndex = event.item.index + activeItems - (activeItems-2);
        owl.find('.owl-item').removeClass('last-active');
        owl.find('.owl-item').eq(lastVisibleItemIndex).addClass('last-active');
    }

    function adjustHeight() {
        var contactsBoxes = $('.contacts-box');
        var maxHeight = 0;
        contactsBoxes.each(function() {
            var tmp = $(this).find('.contacts-box-text').innerHeight();
            if(maxHeight < tmp){
                maxHeight = tmp;
            }
        });

        contactsBoxes.each(function() {
            var contactsBoxes = $(this);
            contactsBoxes.find('.contacts-box-text').css('height', maxHeight);
        });
    }

    function initCarousel() {
        carouselObj = carousel.owlCarousel({
            dots: false,


            margin: carouselCount >= 2 ? 50 : 0,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 35,
                    margin: 20,
                    singleItem: true,
                    center: true,
                    nav: true,
                    navText: [
                        "<i class='prev-icon'></i>",
                        "<i class='next-icon'></i>"
                    ]
                },
                1200:{
                    items: 2,
                    nav: false
                }
            }
        });
    }
    function initCarouselInternational() {
        carouselInternationalObj = carouselInternational.owlCarousel({
            dots: false,
            nav: true,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            responsive:{
                0:{
                    items: 1,
                    singleItem: true,
                    center: true,
                },
                1280:{
                    autoWidth:false,
                    items: carouselInternationalCount <= 2 ? carouselInternationalCount : 3,
                }
            }
        });
    }

    return {
        init: init
    };
})(jQuery);
