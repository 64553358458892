var verticalImageTextSliderManager = (function () {
    var verticalBoxesCarousel;
    var indexBox = 0;
    var verticalBoxesSliderObj = $(".section.vertical-image-text .owl-carousel")

    function init(){
        $(document).ready(function (){
            if(verticalBoxesSliderObj.length){
                initCarousel();
            }
        })
    }

    function initCarousel(){
        $(".item", verticalBoxesSliderObj).each( function (k,v){
            if ($(this).hasClass('active')){
                indexBox = k;
            }
        });

        var verticalBoxesCount = $(".single-vertical-box-container").length;
        verticalBoxesCarousel = verticalBoxesSliderObj.owlCarousel({
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            dots: false,
            center: verticalBoxesCount == 1,
            loop: verticalBoxesCount > 2 && !ENV.isEditmode,
            responsive:{
                0:{
                    mouseDrag: verticalBoxesCount > 1 && !ENV.isEditmode,
                    items: 1,
                    stagePadding: 0,
                    singleItem: true,
                    dots: false,
                    center: false,
                    loop: verticalBoxesCount > 1 && !ENV.isEditmode,
                    nav:verticalBoxesCount > 1
                },
                992: {
                    items: 1,
                    loop: verticalBoxesCount > 1 && !ENV.isEditmode,
                    nav:verticalBoxesCount > 1,
                    center: false,
                },
                1200: {
                    loop: verticalBoxesCount > 2 && !ENV.isEditmode,
                    mouseDrag: verticalBoxesCount > 2,
                    items: verticalBoxesCount > 2 ? 2 : verticalBoxesCount,
                    nav:verticalBoxesCount > 1
                }
            }
        });
    }

    return {
        init: init
    }
})(jQuery);