var careersListingManager = ( function (){

    function init(){
        if( $ ('.careers-listing-component').length <= 0 ){
            return;
        }
        
        $('.careers-listing-component .search-bar-section .select').select2({
            allowClear: true
        });

        $(document).ready(function () {
            let params = new URL(document.location.toString()).searchParams;
            if(params.size >= 1){
                $('html, body').stop().animate({
                    scrollTop: $('#careers-listing-place').offset().top - 150
                }, 10);
            }
        })

    }

    return {init: init}
})(jQuery);