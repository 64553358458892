var ourProductsCarouselManager = (function () {
    var mainProductCarousel, mainDedicatedServicesCarousel;
    var ourProductsObj = $(".our-products");
    var dedicatedServices = $(".dedicated-services");
    var productCarousel = $('.owl-carousel', ourProductsObj);
    var forceItemsNumber = $('.owl-carousel', ourProductsObj).attr("data-force-items");
    var servicesCarousel = $('.owl-carousel', dedicatedServices);
    var itemCount = $(".item", productCarousel).length;
    var itemCountServices = $(".item", servicesCarousel).length;

    function init() {
        $(document).ready(function () {
            if(dedicatedServices.length){
                initDedicatedServiceCarousel();
                eventHandlerForDedicatedServices();
            }
            if(ourProductsObj.length) {
                initProductCarousel();
                eventHandler();
            }
        })
    }

    function eventHandler() {
        if(itemCount === 2) {
            $('.owl-item',productCarousel).removeClass('active');
            $(".owl-item", productCarousel).addClass('two-item');

        }
        $('.item', ourProductsObj).click(function (e) {
            var linkToOpen = $('a', this).attr('data-image-target');
            if ($(this).closest('.owl-item').hasClass('center') && $(this).closest('.owl-item').hasClass('active') && typeof linkToOpen !== typeof undefined && linkToOpen !== '#'){
                window.open(linkToOpen,"_blank");
            }
            if(itemCount === 2) {

                $('.owl-item .item',productCarousel).removeClass('active');
                $(this).addClass('active');
                //grayScaleImage($(this));
            }
            mainProductCarousel.trigger('to.owl.carousel', $(this).attr('data-index'));
            changeContent($('a', this));
            e.preventDefault();
            return false;
        });
    }

    function eventHandlerForDedicatedServices() {
        $(document).on('click','.dedicated-services .item',function (e) {
            mainDedicatedServicesCarousel.trigger('to.owl.carousel', $(this).attr('data-index'));
            changeContentServices($('a', this));
            e.preventDefault();
            return false;
        });
    }

    function changeContent($this) {
        var title = $this.attr("title");
        var subtitle = $this.attr("data-subtitle");
        var description = $this.attr("data-description");
        var target1 = $this.attr("data-target-1");
        var target2 = $this.attr("data-target-2");
        var label1 = $this.attr("data-label-1");
        var label2 = $this.attr("data-label-2");
        //$("h3", ourProductsObj).text(title);
        //$("h5", ourProductsObj).text(subtitle);
        if (typeof description !== typeof undefined) {
            $("p", ourProductsObj).html(
                "<span class='mb-4 h5'>"+title+"</span>"+description
            );
        }
        if (target1 !== '') {
            $(".link2", ourProductsObj).parent().addClass('d-none');
            $(".link1", ourProductsObj)
                .removeClass('d-none')
                .text(label1)
                .attr('href',target1);
        } else {
            $(".link1", ourProductsObj).addClass('d-none');
        }
        if(target2 !== '') {
            $(".link2", ourProductsObj).parent().removeClass('d-none');
            $(".link2", ourProductsObj)
                .removeClass('d-none')
                .text(label2)
                .attr('href',target2);
        } else {
            $(".link2", ourProductsObj).addClass('d-none');
        }
    }

    function changeContentServices($this) {

        if (typeof $this.attr("data-object") !== typeof undefined) {

            var data = JSON.parse($this.attr("data-object"));
            var dataDescription = $this.attr("data-description");

            //OBJECT
            var title = $("h5", dedicatedServices);
            var description = $("p", dedicatedServices);
            var videoElement = $(".illustration_video", dedicatedServices);
            var illustrationElement = $(".illustration", dedicatedServices);


            if (data.title !== '') {
                title.html(data.title);
            }
            if (dataDescription !== '') {
                description.html(dataDescription);
            }

            videoElement
                .addClass('d-none')
                .attr('href','javascript:void(0)')
                .removeAttr('data-lity');

            illustrationElement.addClass('d-none');

            //IF VIDEO
            if (data.video !== '' && data.illustration !== ''){
                videoElement.attr('data-lity','')
                    .attr('href',data.video)
                    .removeClass('d-none');

                illustrationElement
                    .removeClass('d-none')
                    .attr('src',data.illustration);
            }

            if (data.illustration !== '' && data.video === '') {
                illustrationElement
                    .removeClass('d-none')
                    .attr('src',data.illustration);
                videoElement
                    .removeClass('d-none')
                    .attr('href','javascript:void(0)')
                    .removeAttr('data-lity');
            }

            if (data.data_target_1 !== '' && data.data_label_1 !== '') {
                $(".link1", dedicatedServices)
                    .removeClass('d-none')
                    .text(data.data_label_1)
                    .attr('href',data.data_target_1);
            } else {
                $(".link1", dedicatedServices).addClass('d-none');
            }
            if(data.data_target_2 !== '' && data.data_label_2 !== '') {
                $(".link2", dedicatedServices)
                    .removeClass('d-none')
                    .text(data.data_label_2)
                    .attr('href',data.data_target_2);
            } else {
                $(".link2", dedicatedServices).addClass('d-none');
            }
        }
    }

    function initProductCarousel() {
        var defaultDesktopConfig = {
            items: typeof forceItemsNumber !== typeof undefined ? forceItemsNumber : 4,
            stagePadding: 25,
            margin: -55,
            nav: true,
            loop: true,
            center: true
        };

        var defaultMobileConfig = {
            items: typeof forceItemsNumber !== typeof undefined ? 1: 2,
            nav: true,
            loop: true,
            center: true
        };

        var desktopConfig = {
            2: {
                items: 2,
                loop: false,
                stagePadding: 155,
                nav: false,
                center: false,
                mouseDrag: false
            },
            3: {
                items: 3,
                loop: true,
                center: true,
                nav: true,
            },
            4: {
                items: 4,
                stagePadding: 25,
                margin: -55,
                nav: true,
                loop: true,
                center: true
            }
        };

        var mobileConfig = {
            2: {
                items: 2,
                loop: false,

                nav: false,
                center: false,
                mouseDrag: false
            },
            3: {
                items: 2,
                loop: true,
                center: true,
                nav: true,
            }
        };



        mainProductCarousel = productCarousel.owlCarousel({
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            onInitialized: onInitializedCallback,
            onChanged: callback,
            dots: false,
            mouseDrag: false,
            pullDrag: false,
            freeDrag: false,
            responsive:{
                0: itemCount > 4 ? defaultMobileConfig : mobileConfig[itemCount],
                1200: itemCount > 3 ? defaultDesktopConfig : desktopConfig[itemCount]
            }
        });

        var activeItem = $(".item.active", ourProductsObj);
        if (activeItem.length) {
            mainProductCarousel.trigger('to.owl.carousel', activeItem.attr('data-index'));
            changeContent($('a', activeItem));
        }
    }

    function initDedicatedServiceCarousel() {
        mainDedicatedServicesCarousel = servicesCarousel.owlCarousel({
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            onInitialized: onInitializedCallback,
            onChanged: callbackService,
            dots: false,
            responsive:{
                0: {
                    items: 3,
                    margin: 10,
                    nav: false,
                    loop: true,
                    center: true,
                    autoWidth: true
                },
                992: {
                    items: 5,
                    margin: 30,
                    nav: true,
                    loop: true,
                    center: true,
                    autoWidth: true
                },
                1200: {
                    items: 6,
                    margin: 15,
                    nav: true,
                    loop: true,
                    center: true,
                    autoWidth: true
                }
            }
        });


    }

    function onInitializedCallback(event) {
        var current = $(event.target).find(".owl-item").eq(event.item.index).find("a");
        $(".product",current).addClass('active');
        //grayScaleImage(current);
    }

    /*function grayScaleImage(current) {
        var currentImg = current.find('img');
        var targetImages = productCarousel.find('img');

        var swapGrayscaled = function (image) {
            if (image.attr('data-grayscale-src')) {
                image.attr('src', image.attr('data-grayscale-src'));
                return true;
            }

            return false;
        };

        var swapOriginal = function (image) {
            if (image.attr('data-original-src')) {
                image.attr('src', image.attr('data-original-src'));
                return true;
            }

            return false;
        };

        targetImages.each(function (index) {
            var image = $(this);
            if (!swapGrayscaled(image)) {
                grayscale(image.attr('src'), function (grayscaled) {
                    image.attr("data-grayscale-src", grayscaled);
                    swapGrayscaled(image);

                    if (index === (targetImages.length - 1)) {
                        swapOriginal(currentImg);
                    }
                });
            } else {
                if (index === (targetImages.length - 1)) {
                    swapOriginal(currentImg);
                }
            }
        });
    }*/

    function callback(event) {
        var current = $(event.target).find(".owl-item").eq(event.item.index).find("a");
        $(".product",current).addClass('active');
        changeContent($(current));
        //grayScaleImage(current);
    }

    function callbackService(event) {
        var current = $(event.target).find(".owl-item").eq(event.item.index).find("a");
        $(".product",current).addClass('active');
        changeContentServices($(current));
        //grayScaleImage(current);
    }

    return {
        init: init
    };
})(jQuery);
