var howItWorkCarousel = (function () {
    var mainHIWCarousel;
    var hiwObj = $(".hiw-carousel");

    var mainHowItWorkCarousel;
    var howItWorkCarouselObj = $(".how-it-work-carousel");
    var howItWorkCarousel = $('.owl-carousel', howItWorkCarouselObj);
    function init() {
        $(document).ready(function () {
            if(hiwObj.length){
                initHIWCarousel();
            }
            if(howItWorkCarouselObj.length) {
                initHowItWorkCarousel();
            }
        });
    }

    function initHIWCarousel() {
        mainHIWCarousel = hiwObj.owlCarousel({
            navText: [
                "<span><</span>",
                "<span>></span>"
            ],
            responsive:{
                0:{
                    items: 1,
                    nav: true
                },
                992: {
                    items: 5
                },
                1200: {
                    items: 5,
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,
                    freeDrag: false
                }
            }
        });

    }

    function initHowItWorkCarousel() {
        mainHowItWorkCarousel = howItWorkCarousel.owlCarousel({
            navText: [
                "<i class='prev-icon--w'></i>",
                "<i class='next-icon--w'></i>"
            ],
            onChanged: callback,
            onInitialized: onInitCallback,
            nav: true,
            dots: false,
            center: true,
            responsive:{
                0:{
                    items: 1,
                    center: true
                },
                767: {
                    items: 1,
                    center: true
                },
                1200: {
                    items: 1
                }
            }
        });
    }

    function onInitCallback(event) {
        $(event.target).find('.owl-prev').attr('title',LABELS.prev);
        $(event.target).find('.owl-next').attr('title',LABELS.next);
    }

    function callback(event) {
        var item = $(event.target).find(".owl-item").eq(event.item.index).find(".item");
        updatedContent($(item))
    }

    function updatedContent(item) {
        if (typeof item.attr("data-progress") !== typeof undefined) {
            var elems = $(".timeline-how-it-work li");
            var movingElem = $(".timeline-moving-elem");
            var progress = parseFloat(item.attr("data-progress"));

            if (progress === 0) {
                movingElem.css({
                    'opacity': 1
                });
                elems
                    .css('background','')
                    .not(':first').removeClass('timeline-in-progress')
            }

            if (progress < 100) {
                movingElem.css({
                    'opacity': 1
                });
            }

            if (progress === 100) {
                movingElem.css({
                    'opacity': 0
                });
                elems.last()
                    .css({'background': 'rgb(194, 14, 26)'})
                    .addClass('timeline-in-progress')
            }

            movingElem.css({
                'left': progress+'%'
            });

            elems.eq(item.attr("data-index"))
                .addClass('timeline-in-progress')
                .next().removeClass('timeline-in-progress');

            elems.eq(item.attr("data-index"))
                .prev().css({'background': 'rgb(194, 14, 26)'})
                .next().css({'background':''});
        }
    }

    return {
        init: init
    };
})(jQuery);
