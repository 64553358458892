var relativeOffersManager = (function () {
    var relativeOfferCarousel;
    var indexOffer = 0;
    var relativeOffersObj = $('.relative-offers .owl-carousel');
    function init() {
        $(document).ready(function () {
            if (relativeOffersObj.length) {
                initCarousel();
            }
        });
        $(window).on("load", function () {
            setMaxHeightOffers();
        });
    }

    function setMaxHeightOffers(){
        if($(window).width() < 1200) return;
        let relatedOffers = $(".relative-offers-container .owl-item");
        let maxHeight = 0;
        relatedOffers.each(function(){
            currentHeight = $(this).innerHeight();
            if(currentHeight > maxHeight) maxHeight = currentHeight;
        })

        relatedOffers.each(function(){
            currentHeight = $(this).innerHeight();
            if(currentHeight != maxHeight || true){
                marginToAdd = maxHeight - currentHeight;
                offerEquipment = $(this).find('.figure-caption p');
                offerEquipment.css('margin-bottom', marginToAdd + 10)
            }
        })
    }

    function initCarousel() {
        $(".item", relativeOffersObj).each(function (k, v) {
            if ($(this).hasClass('active')){
                indexOffer = k;
            }
        });
        var relatedOfferNumber = $('.related-offer').length;
        if(relatedOfferNumber > 0){
            var $owl = $('.owl-carousel');
            relativeOffersObj.trigger("destroy.owl.carousel");
            $owl.html($owl.find('.owl-stage-outer').html()).removeClass('owl-loaded');
            relativeOfferCarousel = relativeOffersObj.owlCarousel({
                mouseDrag: relatedOfferNumber > 3,
                nav: relatedOfferNumber > 3,
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                dots: false,
                center: relatedOfferNumber > 3,
                loop: relatedOfferNumber > 3,
                startPosition: relatedOfferNumber > 3 ? 1 : 0,
                responsive:{
                    0:{
                        items: 1,
                        stagePadding: 0,
                        singleItem: true,
                        dots: false,
                        center: true,
                        loop: true,
                    },
                    1200: {
                        items: relatedOfferNumber > 3 ? 3 : relatedOfferNumber,
                        stagePadding: 0,
                        margin: 0,
                    }
                }
            });
            if(relatedOfferNumber > 3){
                $(".relative-offers-container .owl-nav").attr('style', 'display: inherit !important');
            } else {
                $(".relative-offers-container .owl-nav").attr('style', 'display: none !important');
            }
        }
        // relativeOfferCarousel.trigger('to.owl.carousel', indexOffer);
    }

    return {
        init: init,
        setMaxHeightOffers: setMaxHeightOffers
    };
})(jQuery);
