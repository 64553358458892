var featureIncludedManager= (function () {
    function init() {
        optionalIncludedManager();
    }

    function optionalIncludedManager() {
        if ($(".car-specification .owl-carousel").length) {
            $(".car-specification .owl-carousel").owlCarousel({
                nav: true,
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                dots: false,
                responsive:{
                    0:{
                        items: 2,
                        stagePadding: 40,
                        margin: 5,
                        loop: false,
                        center: true
                    },
                    767:{
                        items: 3,
                        stagePadding: 20,
                        margin: 20,
                        dots: false
                    },
                    1200: {
                        items: 5,
                        stagePadding: 100,
                        margin: 20,
                        dots: false,
                        loop: false,
                    }
                }
            });
        }
    }


    return {
        init: init
    };
})(jQuery);
