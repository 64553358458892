var carGalleryManager = (function () {
  var galleryCarousel = null;
  function init() {
    $(document).ready(function () {
      handleCarousel();
      handleCarGallery();
    });
  }

  function handleCarousel() {
    galleryCarousel = $('#galleryIndicators').carousel({
      pause: 'hover',
      ride: false,
      wrap: false,
      keyboard: false
    })
  }

  function handleCarGallery(){
    $('#carGallery').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget) // Button that triggered the modal
      var index = button.data('index') // Extract info from data-* attributes
      galleryCarousel.carousel(index)
    })
  }

  return {
    init: init
  };
})(jQuery);
