var gamificationManager = (function () {
    var gamificationScreen = $("section.gamification-screen");
    var owlGamification = $('.owl-gamification');
    var owlGamificationObj;
    var tagContainer = $(".wizard-data-container", gamificationScreen);
    var gamificationData;
    var gamificationRoute = [];
    var disabledAnswers;

    function init() {
        $(document).ready(function () {
            if(owlGamification.length){
                miniGamification();
            }
            if (gamificationScreen.length) {
                gamificationEventListener();
            }
        });
    }

    function initGamification() {
        gamificationData = ENV.gamificationData;
        disabledAnswers = false;
        renderQuestion(gamificationData.start);
    }

    function gamificationEventListener() {
        var gamificationLaunchBtn = $(".launch-gamification");
        var startBtn = $(".start-gamification", gamificationScreen);
        var closeBtn = $(".close", gamificationScreen);
        var body = $("body");
        var backBtn = $('.gamification-nav .back', gamificationScreen);

        $(document).on("click", ".gamification-answer", function (e) {
            if(disabledAnswers) return;
            var questionId = $(this).closest('.step').attr('data-question-id');
            var answerId = $(this).attr('data-answer-id');
            handleAnswer(questionId, answerId)
        }).on("mouseenter", ".gamification-answer", function (e) {
            ungrayscaleImage($('img', this));
        }).on("mouseleave", ".gamification-answer", function (e) {
            grayscaleImage($('img', this));
        });

        gamificationLaunchBtn.click(function (e) {
            gamificationScreen.addClass('show');
            body.addClass('no-scroll');
            e.preventDefault();
            $(".step.start", gamificationScreen).addClass('show');
        });

        backBtn.click(function () {
            var previousQuestionId = gamificationRoute.pop();
            if (!gamificationRoute.length) {
                hideGamificationNav();
            }
            removeAnswerTag(previousQuestionId);
            var backProgress = gamificationData.questions[previousQuestionId].progress;
            var currentStep = $(".step[data-question-id='" + previousQuestionId + "']");
            showStep(currentStep);
            managerProgressBar(backProgress);
        });

        startBtn.click(function () {
            initGamification();
        });

        closeBtn.click(function () {
            body.removeClass('no-scroll');
            gamificationScreen.removeClass('show');
            hideGamificationNav();
            hideSteps();
            gamificationRoute = [];
            $('.to-remove', gamificationScreen).remove();
            $('.wizard-screen-progress--end .car').css({
                'left':'0%'
            });
            $(".wizard-screen-progress.wizard-screen-progress--start .wizard-screen-progress-bar").css({
                'width':'0%'
            }).removeClass('in-progress');
            $(".wizard-screen-progress.wizard-screen-progress--start .car").css({
                'left': '-10px',
            }).removeClass('in-progress');
            leasysWizardManager.hideWizardProgressBar();
        });
    }

    function showGamificationNav() {
        $('.gamification-nav', gamificationScreen).removeClass('d-none');
    }

    function hideGamificationNav() {
        $('.gamification-nav', gamificationScreen).addClass('d-none');
    }

    function handleAnswer(questionId, answerId) {
        gamificationRoute.push(questionId);
        showGamificationNav();
        var currentQuestion = gamificationData.questions[questionId];
        var currentAnswer = currentQuestion.answers[answerId];
        var questionTag = currentQuestion.tag;
        var answerText = currentAnswer.answer;
        var nextData = currentAnswer.next.data;
        var nextType = currentAnswer.next.type;
        switch (nextType) {
            case "question":
                renderQuestion(nextData);
                break;
            case "result":
                disabledAnswers = true;
                managerProgressBar('100%');
                setTimeout(function(){
                    renderResults(nextData);
                }, 1000);
                break;
        }
        addAnswerTag(questionId, questionTag, answerText);
    }

    function managerProgressBar(progress) {
        var progressContainer = $(".wizard-screen-progress.wizard-screen-progress--start");
        var progressBar = $(".wizard-screen-progress-bar", progressContainer);
        var progressCar = $(".car", progressContainer);
        progressBar.addClass('in-progress');
        progressCar.addClass('in-progress');
        progressCar.css({
            'left': progress
        });
        progressBar.css({
            'width': progress
        })
    }

    function renderResults(urls) {
        var stepEnd = $('.step.end', gamificationScreen);
        var urlContainer = $('.wizard-url-container', stepEnd);
        $.each(urls, function (k, url) {
            var urlDom = $('.to-clone', stepEnd).clone().removeClass('to-clone d-none')
                .addClass('to-remove');
            var button = $('.wizard-data-send-btn', urlDom);
            button.attr('href', url.href).text(url.label);
            if(url.hasOwnProperty("icon") && !!url.icon) {
                button.html("<img class='product-logo' src='"+ url.icon +"' alt='" + button.text() + "'>");
            } else {
                button.addClass("btn btn-leasys-white")
            }
            urlContainer.append(urlDom);
        });
        leasysWizardManager.hideWizardProgressBar();
        hideGamificationNav();
        showStep(stepEnd);
        animateLastStep();
    }

    function animateLastStep() {
        if ($(window).width()<992) {
            $('.wizard-screen-progress--end .car').animate({
                left: '94%'
            }, {
                duration: 3500,
                complete: function(){
                    var target = $(".step.end .wizard-data-send-btn");
                    if(target.find("> img").length === 0) {
                        target.addClass("btn btn-leasys-white");
                    }
                    target.removeClass("invisible");
                }
            });
        } else if ($(window).width()<767) {
            $('.wizard-screen-progress--end .car').animate({
                left: '89%'
            }, {
                duration: 3500,
                complete: function(){
                    var target = $(".step.end .wizard-data-send-btn");
                    if(target.find("> img").length === 0) {
                        target.addClass();
                    }
                    target.removeClass("invisible");
                }
            });
        } else {
            $('.wizard-screen-progress--end .car').animate({
                left: '95.5%'
            }, {
                complete: function(){
                    var target = $(".step.end .wizard-url-container .wizard-data-send-btn");
                    target.removeClass("invisible");
                }
            });
        }
    }

    function showStep(step) {
        hideSteps();
        step.addClass('show');
    }

    function hideSteps() {
        $(".step.show", gamificationScreen).removeClass('show');
    }

    function renderQuestion(questionId) {
        var questionObj = gamificationData.questions[questionId];
        var questionsArea = $('.gamification-question-area', gamificationScreen);
        var question = $(".step[data-question-id='" + questionId + "']", gamificationScreen);
        if (!question.length) {
            question = $(".step.to-clone", questionsArea).clone().removeClass("to-clone").addClass('to-remove')
                .attr('data-question-id', questionId);
            var answersArea = $('.gamification-answers-area', question);
            $('.question-text', question).text(questionObj.question);
            $.each(questionObj.answers, function (answerId, answerObj) {
                var answer = $(".gamification-answer-to-clone", question).clone().removeClass("gamification-answer-to-clone d-none");
                $('.gamification-answer', answer).attr('data-answer-id', answerId);
                $('.answer-text', answer).text(answerObj.answer);
                $('.answer-img', answer).attr('src', answerObj.icon).attr('data-original-src', answerObj.icon);
                grayscaleAlpha(answerObj.icon, 0.3, function (grayscaledImage) {
                    $('.answer-img', answer).attr("data-grayscale-src", grayscaledImage);
                    grayscaleImage($('.answer-img', answer));
                });
                answersArea.append(answer);
            });
            questionsArea.append(question);
        }

        leasysWizardManager.showWizardProgressBar();
        managerProgressBar(questionObj.progress);
        showStep(question);
    }

    function grayscaleImage(image) {
        image.attr('src', image.attr('data-grayscale-src'));
    }

    function ungrayscaleImage(image) {
        image.attr('src', image.attr('data-original-src'));
    }

    function addAnswerTag(questionId, questionTag, answerText) {
        var tag = $("li[data-question-id='" + questionId + "']", tagContainer);
        if (!tag.length) {
            tag = $("li.to-clone", tagContainer).clone().addClass('to-remove')
                .removeClass('to-clone d-none').attr('data-question-id', questionId);
            tagContainer.append(tag);
        }

        $('.question-tag', tag).text(questionTag);
        $('.answer-text', tag).text(answerText);
    }

    function removeAnswerTag(questionId) {
        $("li[data-question-id='" + questionId + "']", tagContainer).remove();
    }

    function miniGamification() {
        $('.mini-gamification-next', owlGamification).on("click", function() {
            owlGamificationObj.trigger('next.owl.carousel');
            var target = $('.mini-gamification-answer', owlGamification);
            var correctAnswer = owlGamification.attr("data-correct-answer");
            var wrongAnswer = owlGamification.attr("data-wrong-answer");
            var answerTrue = $(this).attr("data-answer-true");
            var answerFalse = $(this).attr("data-answer-false");
            var risposta = $(this).attr("data-risposta");
            var dataBool = $(this).attr("data-bool");
            if (dataBool !== risposta) {
                target
                    .text(wrongAnswer)
                    .css({
                        color: '#c20e1a'
                    });
            } else{
                target
                    .text(correctAnswer)
                    .css({
                        color: 'green'
                    });
            }
        });
        owlGamificationObj = owlGamification.owlCarousel({
            items: 1,
            loop: false,
            dots: false,
            nav: false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false,
            margin: 10
        });
    }

    return {
        init: init
    };
})(jQuery);
