var refactorPrice = (function () {
    function init() {
        if (typeof adaptPriceIsEnable != typeof undefined && adaptPriceIsEnable){
            adaptPrice();
        }
    }
    function addCommas(nStr) {
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }
        return x1 + x2;
    }



    function adaptPrice() {
        var main = $(".price");
        var priceElem;
        var rgx = /(\d+)(\d{3})/;
        var selector,
            dottedValue,
            price,
            parent,
            isListing,
            priceWithoutSpace;
        $.each(main, function() {
            selector = $(this);
            isListing = selector.closest(".offerte-listing").length;
            priceElem = selector.find(".item_price");
            parent = priceElem.parent("strong");
            price = priceElem.text();
            priceWithoutSpace = price.replace(/\s/g, '');
            dottedValue = addCommas(priceWithoutSpace);
            priceElem.text(dottedValue);
            if (rgx.test(price) && isListing) {
                parent.css({
                    'font-size':'2em'
                })
            }
            if (price.length >= 5 && isListing) {
                parent.css({
                    'font-size':'1.55em'
                })
            }
        });
    }
    return {
        init: init
    }
}(jQuery));